import React, { useState, useEffect } from "react";
import { Icon, Button, Form, Modal } from "semantic-ui-react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-chaos";
import {
  editDeviceConfiguration,
  createDeviceConfiguration,
  DeviceConfigurationType,
} from "../../../../../BytebeamClient";
import { Mixpanel } from "../../../common/MixPanel";
import { DeviceConfigurationsOperationType } from "./DeviceConfigurations";
import ThemeSchema from "../../../../../theme/schema";
import { beamtoast } from "../../../../common/CustomToast";
import { isJSONValid } from "../../../util";

type CreateDeviceConfigurationsModalProps = {
  type: string;
  changeType: (type: string) => void;
  config: DeviceConfigurationType;
  isOpen: boolean;
  setConfig: (value: DeviceConfigurationType) => void;
  close: () => void;
  fillConfigsTable: () => Promise<void>;
  allExistingDeviceConfigurations: DeviceConfigurationType[];
  theme: string;
};

const TYPE_BASED_ACTION = {
  Create: "Create",
  Edit: "Update",
  View: "View",
};

function CreateDeviceConfigurationsModal(
  props: CreateDeviceConfigurationsModalProps
) {
  const {
    type,
    changeType,
    config,
    isOpen,
    setConfig,
    close,
    fillConfigsTable,
    allExistingDeviceConfigurations,
    theme,
  } = props;

  const [configJSON, setConfigJSON] = useState<string>("");
  const [versionName, setVersionName] = useState<string>("");

  const [disableUpdateButton, setDisableUpdateButton] =
    useState<boolean>(false);

  const [, setExistingConfigVersions] = useState<Set<string>>(new Set());
  const [errors, setErrors] = useState<{ versionName: string }>({
    versionName: "",
  });

  const callDeviceConfigApi = () => {
    const configJson = JSON.parse(configJSON);
    if (type === DeviceConfigurationsOperationType.Edit) {
      return editDeviceConfiguration(config.version_name, configJson);
    } else {
      return createDeviceConfiguration(
        versionName,
        configJson,
        "update_config"
      );
    }
  };

  const updateDeviceConfig = async () => {
    try {
      const newConfig = await callDeviceConfigApi();
      beamtoast.success(
        `${
          type === DeviceConfigurationsOperationType.Edit
            ? "Updated"
            : "Created"
        } Device Configuration ${newConfig.version_name}`
      );
      Mixpanel.track("Created Configuration", {
        Config: newConfig.version_name,
      });

      await fillConfigsTable();
      onModalClose();
    } catch (e) {
      onModalClose();
      Mixpanel.track("Failure", {
        type: "Upload Configuration",
        error: JSON.stringify(e),
      });
      console.log("Error while updating device config ", e);
    }
  };

  function versionNameExists(allConfigs, versionName) {
    return allConfigs.some((item) => item.version_name === versionName);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    let validity = 1;
    if (type === DeviceConfigurationsOperationType.Create) {
      if (versionName.length === 0) {
        setErrors({
          versionName: "Please enter a Version Name",
        });
        validity = 0;
      }
    }
    if (
      type === DeviceConfigurationsOperationType.Create ||
      type === DeviceConfigurationsOperationType.Edit
    ) {
      if (!isJSONValid(configJSON)) {
        beamtoast.error("Please type a valid JSON config");
        validity = 0;
      }
    }
    if (type === DeviceConfigurationsOperationType.Create) {
      if (versionNameExists(allExistingDeviceConfigurations, versionName)) {
        setErrors({
          versionName: "This config version already exists",
        });
        validity = 0;
      }
    }
    if (validity === 1) {
      // valid data, submit
      await updateDeviceConfig();
    }
  };

  // Setting the state for the modal when it is opened for editing or viewing
  function onModalOpen() {
    if (type !== DeviceConfigurationsOperationType.Create) {
      setVersionName(config?.version_name ?? "");
      setConfigJSON(
        config?.config_json ? JSON.stringify(config.config_json, null, 2) : ""
      );
    }
    if (type === DeviceConfigurationsOperationType.Edit)
      setDisableUpdateButton(true);
    if (type === DeviceConfigurationsOperationType.View)
      setDisableUpdateButton(false);
  }

  // Reset state and close modal
  function onModalClose() {
    // Reset state
    setVersionName("");
    setConfigJSON("");
    setErrors({
      versionName: "",
    });
    setConfig({
      action_type: "",
      tenant_id: "",
      is_deactivated: false,
      version_name: "",
      config_json: "",
    });
    // Close Modal
    close();
  }

  useEffect(() => {
    const existingVersions: Set<string> = new Set();
    if (
      allExistingDeviceConfigurations &&
      type === DeviceConfigurationsOperationType.Create
    ) {
      // backup reset state
      setVersionName("");
      setConfigJSON("");
      setErrors({
        versionName: "",
      });
      allExistingDeviceConfigurations.forEach((config) => {
        if (
          config.action_type === "update_config" ||
          config.action_type === null
        ) {
          // This only has device config and not geofence config
          existingVersions.add(config.version_name);
        }
      });
      setExistingConfigVersions(existingVersions);
    }
  }, [allExistingDeviceConfigurations, type]);

  return (
    <Modal
      open={isOpen}
      onClose={onModalClose}
      onMount={onModalOpen}
      size="tiny"
      className="dark"
    >
      <Modal.Header>{`${TYPE_BASED_ACTION[type]} Device Configuration`}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Version Name</label>
            <Form.Input
              autoFocus={type === DeviceConfigurationsOperationType.Create}
              disabled={
                type === DeviceConfigurationsOperationType.Edit ||
                type === DeviceConfigurationsOperationType.View
              }
              error={errors.versionName !== "" ? errors.versionName : null}
              placeholder={
                type === DeviceConfigurationsOperationType.Edit ||
                type === DeviceConfigurationsOperationType.View
                  ? config?.version_name
                  : "production v1"
              }
              value={versionName}
              onChange={(e) => {
                if (type === DeviceConfigurationsOperationType.Create)
                  setDisableUpdateButton(false);
                setVersionName(e.target.value);
                setErrors({
                  versionName: "",
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label
              style={{
                marginBottom: "8px",
              }}
            >
              Configuration JSON
            </label>
            <AceEditor
              height="300px"
              width="100%"
              placeholder="Enter Configuration JSON"
              mode="json"
              theme={
                ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-theme"]
              }
              name="config-json"
              onChange={(val) => {
                if (type === DeviceConfigurationsOperationType.Create)
                  setDisableUpdateButton(false);
                else if (type === DeviceConfigurationsOperationType.Edit) {
                  if (isJSONValid(val)) {
                    if (
                      JSON.stringify(config.config_json) !==
                      JSON.stringify(JSON.parse(val))
                    )
                      setDisableUpdateButton(false);
                    else setDisableUpdateButton(true);
                  } else setDisableUpdateButton(false);
                } else setDisableUpdateButton(true);
                setConfigJSON(val);
              }}
              focus={type === DeviceConfigurationsOperationType.Edit}
              fontSize={14}
              readOnly={type === DeviceConfigurationsOperationType.View}
              value={configJSON}
              showPrintMargin={false}
              showGutter={true}
              highlightActiveLine={true}
              setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}
              style={{
                marginBottom: "16px",
                borderRadius: "4px",
                border: `${
                  ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-border"]
                }`,
                boxShadow: `${
                  ThemeSchema.data[theme ?? "dark"]?.colors[
                    "ace-editor-box-shadow"
                  ]
                }`,
              }}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            onModalClose();
          }}
        >
          <Icon name="remove" /> Discard
        </Button>
        <Button
          disabled={config?.is_deactivated || disableUpdateButton}
          primary
          onClick={(e) => {
            if (type !== DeviceConfigurationsOperationType.View)
              handleSubmit(e);
            else {
              changeType(DeviceConfigurationsOperationType.Edit);
              setDisableUpdateButton(true);
            }
          }}
        >
          <Icon
            name={
              type === DeviceConfigurationsOperationType.View
                ? "edit"
                : "checkmark"
            }
          />{" "}
          {type === DeviceConfigurationsOperationType.View
            ? "Edit"
            : TYPE_BASED_ACTION[type]}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CreateDeviceConfigurationsModal;
