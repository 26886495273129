import React from "react";
import { LogsPageProps } from "./ActionLog";
import LogsComponent from "./LogsComponent";
import { ErrorMessage } from "../../../common/ErrorMessage";

function DeviceLog(props: Readonly<LogsPageProps>) {
  if (props.logsStreams.length === 0) {
    return <ErrorMessage marginTop="72px" message={"No Logs streams found"} />;
  }
  return (
    <LogsComponent
      timeRange={props.timeRange}
      onTimeRangeChange={props.onTimeRangeChange}
      loading={props.loading}
      setLoading={props.setLoading}
      table={props.logStream}
      title={"device log"}
      filterBys={{ id: [props.selectedDevice] }}
    />
  );
}

export default DeviceLog;
