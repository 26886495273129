import React, { useState, useRef, useEffect } from "react";
import { Table, Icon, Popup } from "semantic-ui-react";
import ConfirmationModal from "../../common/ConfirmationModal";
import AddNewColumn from "./AddNewColumn";
import ColumnType from "./ColumnType";
import CreateStreamModal from "./CreateStreamModal";
import { filter, mapIf, updateColumns, mapColumnIf } from "./functional";
import Layout from "../../common/Layout";
import { ErrorMessage } from "../../../common/ErrorMessage";
import useAsyncEffect from "../../common/useAsyncEffect";
import { DisplayIf, filterStreamTableInfo, isNumericType } from "../../util";
import {
  createStreamField,
  deleteStream,
  deleteStreamField,
  updateStreamField,
  fetchAllStreamsWithDetails,
  fetchAlertRules,
} from "../../../../BytebeamClient";
import ConfirmationModalMessage from "../../common/ConfirmationModalMessage";
import LoadingAnimation from "../../../common/Loader";
import { beamtoast } from "../../../common/CustomToast";
import ColumnUnit from "./ColumnUnit";
import SlicedTextPopUp from "../../DeviceManagement/Devices/SlicedTextPopUp";
import { useUser } from "../../../../context/User.context";

const DeleteTableModal = ({ tableName, onConfirm, alertRulesNames }) => (
  <>
    {alertRulesNames.has(tableName) ? (
      <Popup
        id="delete-stream-alert-rule-popup"
        content="Cannot delete stream with active alert rules."
        inverted
        position="top center"
        trigger={
          <Icon link name="trash" disabled={alertRulesNames.has(tableName)} />
        }
      />
    ) : (
      <ConfirmationModal
        prefixContent="Delete stream"
        expectedText={tableName}
        onConfirm={onConfirm}
        trigger={<Icon link name="trash" />}
        message={
          <ConfirmationModalMessage name={tableName} type={"Stream Table"} />
        }
      />
    )}
  </>
);

const DeleteColModal = ({ columnName, onConfirm }) => (
  <ConfirmationModal
    prefixContent="Delete column"
    expectedText={columnName}
    onConfirm={onConfirm}
    trigger={<Icon link name="trash" />}
    message={<ConfirmationModalMessage name={columnName} type={"Column"} />}
  />
);

const sortColumns = (columns) => {
  columns.sort((a, b) => {
    let alphaCompare = a.name.localeCompare(b.name);
    if (a.required) {
      if (b.required) {
        return alphaCompare;
      } else {
        return -1;
      }
    } else {
      if (b.required) {
        return +1;
      } else {
        return alphaCompare;
      }
    }
  });
  return columns;
};

let requestIdInternal = 0;
const newRequestId = () => {
  return requestIdInternal++;
};

export default function Streams({ user }) {
  const { getCurrentUser } = useUser();

  const [tables, setTables] = useState([]);
  const [alertRulesNames, setAlertRulesNames] = useState(new Set());
  const [loading, setLoading] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const permissions = user.role.permissions;

  /**
   * Fetches the streams with active alert rules and making a set of stream names.
   * @returns {Promise<void>}
   */
  const streamsWithActiveAlertRules = async () => {
    try {
      const alertsRes = await fetchAlertRules();
      const streamNames = new Set(
        alertsRes.map((alertRule) => alertRule.stream)
      );
      setAlertRulesNames(streamNames);
    } catch (error) {
      console.error("An error occurred while fetching alert rules:", error);
    }
  };

  const resetTable = async () => {
    setLoading(true);
    try {
      let response = await fetchAllStreamsWithDetails(),
        // Filtering the stream table info to remove streams name starting with uplink_ and ending with _local
        // and also filtering out columns ending with _timestamp
        filteredStreamsWithDetails = filterStreamTableInfo(response, true);

      let streamsWithDetails = Object.entries(filteredStreamsWithDetails).map(
        ([streamName, streamDetails]) => {
          let stream = {
            tableName: streamName,
            // Sorting the columns by name and adding newType, newUnit and status properties
            cols: sortColumns(streamDetails).map((col) => ({
              ...col,
              newType: col.type,
              newUnit: col.unit,
              status: "ready",
            })),
          };
          return stream;
        }
      );

      streamsWithActiveAlertRules();

      setTables(streamsWithDetails);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setErrorOccurred(true);
    }
  };

  useAsyncEffect(resetTable, []);

  useEffect(() => {
    document.title = "Streams | Bytebeam";
    // To scroll to top of the screen
    window.scrollTo(0, 0);
  }, []);

  const tablesRef = useRef();
  tablesRef.current = tables;

  // Normally to add a new table, you would:
  // setTable([newTable, ...tables])
  // But if it is done in a callback, you will only
  // have access to old tables. To avoid this nuance, use
  // updateTables(tables => [newTable, ...tables])
  // instead.
  const updateTables = (transition) => {
    setLoading(true);
    setTables(transition(tablesRef.current));
    setLoading(false);
  };

  const addNewCol = async (tableName, columnName, columnType, columnUnit) => {
    const requestId = newRequestId();
    columnName = columnName.replace(" ", "_").trim();
    columnUnit = columnUnit === "" ? null : columnUnit;

    updateTables(
      mapIf(
        (table) => table.tableName === tableName,
        updateColumns((cols) => [
          ...cols,
          {
            requestId,
            name: columnName,
            type: columnType,
            unit: columnUnit,
            status: "initiated",
          },
        ])
      )
    );

    try {
      await createStreamField({
        streamName: tableName,
        fieldName: columnName,
        fieldType: columnType,
        fieldUnit: columnUnit,
      });

      updateTables(
        mapIf(
          (table) => table.tableName === tableName,
          mapColumnIf(
            (col) => col.requestId === requestId,
            (col) => ({ ...col, status: "ready" })
          )
        )
      );

      await getCurrentUser();

      beamtoast.success(
        `New column ${columnName} is created in ${tableName} stream successfully`
      );
    } catch (e) {
      beamtoast.error(
        `Error creating ${columnName} in ${tableName} stream column`
      );
      console.log(e);
    }
  };

  const deleteTable = (tableName) => {
    updateTables(
      mapIf(
        (table) => table.tableName === tableName,
        (table) => ({ ...table, slatedForDeletion: true })
      )
    );

    deleteStream(tableName)
      .then(() => {
        updateTables(filter((row) => row.tableName !== tableName));
        getCurrentUser();
        beamtoast.success(`${tableName} stream is deleted successfully`);
      })
      .catch((e) => {
        updateTables(
          mapIf(
            (table) => table.tableName === tableName,
            (table) => ({
              ...table,
              slatedForDeletion: false,
              status: "error",
              error: e.message,
            })
          )
        );
        beamtoast.error(`Error deleting ${tableName} stream`);
      });
  };

  const deleteColumn = (tableName, columnName) => {
    //
    updateTables(
      mapIf(
        (table) => table.tableName === tableName,
        mapColumnIf(
          (col) => col.name === columnName,
          (col) => ({ ...col, status: "deleting" })
        )
      )
    );

    deleteStreamField(tableName, columnName)
      .then(() => {
        updateTables(
          mapIf(
            (table) => table.tableName === tableName,
            updateColumns(filter((col) => col.name !== columnName))
          )
        );
        getCurrentUser();
        beamtoast.success(
          `column ${columnName} from ${tableName} stream is deleted successfully`
        );
      })
      .catch((err) => {
        updateTables(
          mapIf(
            (table) => table.tableName === tableName,
            mapColumnIf(
              (col) => col.name === columnName,
              (col) => ({ ...col, status: "error", error: err.message })
            )
          )
        );
        beamtoast.error(
          `Error deleting column ${columnName} from ${tableName} stream`
        );
      });
  };

  const editColumn = (tableName, columnName, newType, newUnit) => {
    newUnit = newUnit === "" ? null : newUnit;

    updateTables(
      mapIf(
        (table) => table.tableName === tableName,
        mapColumnIf(
          (col) => col.name === columnName,
          (col) => ({
            ...col,
            newType,
            newUnit,
            status: "editing",
          })
        )
      )
    );

    updateStreamField({
      streamName: tableName,
      fieldName: columnName,
      fieldType: newType,
      fieldUnit: newUnit,
    })
      .then(() =>
        updateTables(
          mapIf(
            (table) => table.tableName === tableName,
            mapColumnIf(
              (col) => col.name === columnName,
              (col) => ({
                ...col,
                type: newType,
                unit: newUnit,
                status: "ready",
              })
            )
          )
        )
      )
      .then(() => {
        getCurrentUser();
        beamtoast.success(`column ${columnName} is updated successfully`);
      })
      .catch((err) => {
        updateTables(
          mapIf(
            (table) => table.tableName === tableName,
            mapColumnIf(
              (col) => col.name === columnName,
              (col) => ({
                ...col,
                newType: [col.type], // reset to old type!
                newUnit: [col.unit], // reset to old unit!
                status: "error",
                error: err.message,
              })
            )
          )
        );
      });
  };

  const ActiveTable = ({ tableName, cols, slatedForDeletion, error }) => {
    const columnNameSet = new Set(
      cols.map((col) => col.name.toLowerCase().replace(" ", "_").trim())
    );
    return [
      ...cols.map(
        ({ name, type, newType, unit, newUnit, required, status }, index) => (
          <Table.Row
            key={tableName + ":" + name}
            warning={type !== newType || unit !== newUnit}
          >
            {index === 0 && (
              <Table.Cell
                rowSpan={1 + cols.length}
                width={"3"}
                verticalAlign="top"
              >
                {/* table name only needed for first row */}
                <SlicedTextPopUp text={tableName} length={30} />
              </Table.Cell>
            )}

            <Table.Cell width={"3"}>{name}</Table.Cell>

            <Table.Cell width={"3"}>
              <ColumnType
                columnName={name}
                required={required}
                status={status}
                pendingType={newType}
                value={type}
                permission={permissions.editStreams}
                onChange={(newType) =>
                  editColumn(
                    tableName,
                    name,
                    newType,
                    !isNumericType(newType) ? null : newUnit ?? unit
                  )
                }
              />
            </Table.Cell>

            <Table.Cell width={"3"}>
              <ColumnUnit
                columnName={name}
                required={required}
                status={status}
                pendingUnit={newUnit ?? "None"}
                value={unit ?? "None"}
                columnType={type}
                permission={permissions.editStreams}
                onChange={(newUnit) =>
                  editColumn(tableName, name, newType ?? type, newUnit)
                }
              />
            </Table.Cell>

            <Table.Cell width={"1"}>
              {status !== "ready" && status !== "error" && status}
              {status === "error" && JSON.stringify(error)}
              {!required && status === "ready" && (
                <DisplayIf cond={permissions.editStreams}>
                  <DeleteColModal
                    columnName={name}
                    onConfirm={() => deleteColumn(tableName, name)}
                  />
                </DisplayIf>
              )}
            </Table.Cell>

            {index === 0 && tableName !== "device_shadow" && (
              <Table.Cell
                rowSpan={1 + cols.length}
                width={"1"}
                verticalAlign="top"
                textAlign="center"
              >
                {error && <p>{error}</p>}
                <DisplayIf cond={permissions.editStreams}>
                  <DeleteTableModal
                    tableName={tableName}
                    alertRulesNames={alertRulesNames}
                    onConfirm={() => deleteTable(tableName)}
                  />
                </DisplayIf>
              </Table.Cell>
            )}
            {/* delete table button only needed for first row */}
          </Table.Row>
        )
      ),

      permissions.editStreams ? (
        <AddNewColumn
          tableName={tableName}
          key={tableName + ":newcol"}
          addNewCol={addNewCol}
          columnNameSet={columnNameSet}
        />
      ) : (
        <Table.Row></Table.Row>
      ),
    ];
  };

  if (errorOccurred) {
    return <ErrorMessage marginTop="270px" errorMessage />;
  }

  if (loading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="65vh"
        fontSize="1.5rem"
        loadingText="Loading streams"
      />
    );
  }

  return (
    <Layout
      buttons={
        <>
          <DisplayIf cond={permissions.editStreams}>
            <CreateStreamModal onClose={resetTable} sourceStreams={tables} />
          </DisplayIf>
          {/*
                <Button primary floated="right" icon labelPosition='left' as="a" href={streamProtobuff}>
                  <Icon name='download' />
                  Download proto!
                </Button>
            */}
        </>
      }
    >
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={"3"}>Stream Name</Table.HeaderCell>
            <Table.HeaderCell width={"3"}>Column Name</Table.HeaderCell>
            <Table.HeaderCell width={"3"}>Column Type</Table.HeaderCell>
            <Table.HeaderCell width={"3"}>Column Unit</Table.HeaderCell>

            <DisplayIf cond={permissions.editStreams}>
              <Table.HeaderCell width={"1"}>Column Ops</Table.HeaderCell>
            </DisplayIf>

            <DisplayIf cond={permissions.editStreams}>
              <Table.HeaderCell width={"1"}>Stream Ops</Table.HeaderCell>
            </DisplayIf>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {tables.length !== 0 ? (
            tables.map((table) => {
              return ActiveTable(table);
            })
          ) : (
            <Table.Row>
              <Table.Cell colspan={`${permissions.editStreams ? "5" : "3"}`}>
                <ErrorMessage marginTop="30px" message={"No Streams Found!"} />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Layout>
  );
}
