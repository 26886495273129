import React, { useState } from "react";
import { Icon, Button, Form, Modal } from "semantic-ui-react";
import { createDeviceConfiguration } from "../../../../../BytebeamClient";
import { Mixpanel } from "../../../common/MixPanel";
import { OptionType } from "../../util";
import { useUser } from "../../../../../context/User.context";
import { CreateJSONConfigModalProps } from "./CreateJSONConfigModal";
import GeofenceEditor, {
  LatLng,
} from "../../Inventory/DeviceConfigurations/GeofenceEditor";
import { beamtoast } from "../../../../common/CustomToast";

function CreateGeofenceConfigModal(props: CreateJSONConfigModalProps) {
  const {
    isOpen,
    onClose,
    setOptionType,
    setSelectedVersion,
    allConfigOptions,
  } = props;

  const { user } = useUser();
  const theme = user?.settings?.theme ?? "dark";

  const [versionName, setVersionName] = useState<string>("");
  const [alertThreshold, setAlertThreshold] = useState<number>(20);
  const [errors, setErrors] = useState<{
    versionName?: string;
    thresholdValue?: string;
  }>({
    versionName: "",
    thresholdValue: "",
  });

  const [path, setPath] = useState<LatLng[]>([]);
  const [isPolygonComplete, setIsPolygonComplete] = useState<boolean>(false);
  const [latitude, setLatitude] = useState<number>(12.927381); // defaults to bangalore
  const [longitude, setLongitude] = useState<number>(77.637729); // defaults to bangalore

  const updateDeviceConfig = async () => {
    try {
      const configJson = {
        type: "polygon",
        path,
        alert_interval: alertThreshold.toString(),
      };

      const newConfig = await createDeviceConfiguration(
        versionName.trim(),
        configJson,
        "update_geofence"
      );

      if (newConfig.version_name) {
        setOptionType(OptionType.UploadGeofence);
        setSelectedVersion(newConfig.version_name);
        beamtoast.success(
          `Created Geofence Configuration ${newConfig.version_name}`
        );
        Mixpanel.track("Created Configuration", {
          Config: newConfig.version_name,
        });
      }
    } catch (e) {
      Mixpanel.track("Failure", {
        type: "Upload Configuration",
        error: JSON.stringify(e),
      });
      console.log(e);
    } finally {
      onCloseModal();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (alertThreshold < 1) {
      setErrors({
        thresholdValue: "Geofence alert threshold should be greater than 0",
      });
    } else if (versionName?.trim().length === 0) {
      setErrors({
        versionName: "Please enter a Version Name",
      });
    } else if (!alertThreshold) {
      setErrors({
        thresholdValue: "Please enter valid geofence threshold",
      });
    } else if (!isPolygonComplete) {
      beamtoast.error("Please completely select a geofence on the map");
    } else if (allConfigOptions.has(versionName?.trim()?.toLowerCase())) {
      setErrors({
        versionName: "This geofence config version already exists",
      });
    } else {
      // valid data, submit
      await updateDeviceConfig();
    }
  };

  // Reset state and close modal
  function onCloseModal() {
    setVersionName("");
    setAlertThreshold(20);
    setIsPolygonComplete(false);
    setPath([]);
    setErrors({
      versionName: "",
      thresholdValue: "",
    });
    setLatitude(12.927381);
    setLongitude(77.637729);

    onClose();
  }

  return (
    <Modal open={isOpen} onClose={onCloseModal} size="small" className="dark">
      <Modal.Header>{`Create Geofence Configuration`}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label htmlFor="version_name">Version Name</label>
            <Form.Input
              id={"version_name"}
              error={errors.versionName !== "" ? errors.versionName : null}
              placeholder={"bengaluru-geofence"}
              value={versionName}
              onChange={(e) => {
                setVersionName(e.target.value);
                setErrors({
                  versionName: "",
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="geofence_editor">
              Click to create geofence polygon
            </label>
            <GeofenceEditor
              editable={true}
              latitude={latitude}
              longitude={longitude}
              path={path}
              onPolygonChanged={(path, isComplete) => {
                setPath(path);
                setIsPolygonComplete(isComplete);
              }}
              theme={theme}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="alert_threshold">Alert threshold (seconds)</label>
            <Form.Input
              min="1"
              id={"alert_threshold"}
              type="number"
              error={
                errors.thresholdValue !== "" ? errors.thresholdValue : null
              }
              placeholder={20}
              value={alertThreshold}
              onChange={(e) => {
                setAlertThreshold(parseInt(e.target.value));
                setErrors({
                  versionName: "",
                });
              }}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            onCloseModal();
          }}
        >
          <Icon name="remove" /> Discard
        </Button>
        <Button
          id="submitButton"
          primary
          onClick={async (e) => {
            await handleSubmit(e);
          }}
        >
          <Icon name={"checkmark"} />
          Create
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CreateGeofenceConfigModal;
