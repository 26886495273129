import React, { useEffect, useState } from "react";
import { Button, Icon, Popup, Table } from "semantic-ui-react";
import useAsyncEffect from "../../common/useAsyncEffect";
import Layout from "../../common/Layout";
import { ErrorMessage } from "../../../common/ErrorMessage";
import {
  CompositeCondition,
  Condition,
  SessionType,
  SimpleCondition,
} from "../../../../util";
import { Mixpanel } from "../../common/MixPanel";
import CreateSessionTypeModal from "./CreateSessionTypeModal";
import {
  createSessionType,
  deleteSessionType,
  fetchSessionTypes,
  startSessionType,
  stopSessionType,
} from "../../../../BytebeamClient";
import { ButtonIcon, capitalizeFirstLetter } from "../../util";
import ConfirmationModal from "../../common/ConfirmationModal";
import ConfirmationModalMessage from "../../common/ConfirmationModalMessage";
import moment from "moment";
import LoadingAnimation from "../../../common/Loader";
import { beamtoast } from "../../../common/CustomToast";

interface CreateSessionTypeButtonProps {
  readonly onUpdate: () => void;
  readonly sessionTypes: SessionType[];
}

function CreateSessionTypeButton(props: CreateSessionTypeButtonProps) {
  return (
    <CreateSessionTypeModal
      title="Create new session type"
      sessionTypesArray={props.sessionTypes}
      onSubmit={async (submittedSessionType) => {
        try {
          await createSessionType(submittedSessionType);
          Mixpanel.track("Created Session Type", {});
          beamtoast.success(
            `Created session type '${submittedSessionType.name}'`
          );
          props.onUpdate();
        } catch (e) {
          Mixpanel.track("Failure", {
            type: "Session Type creation",
            error: JSON.stringify(e),
          });
          beamtoast.error(
            `Failed to create session type '${submittedSessionType.name}'`
          );
          console.log(e);
        }
      }}
      trigger={
        <Button primary floated="right" icon labelPosition="left">
          <Icon name="plus" />
          Create Session Type
        </Button>
      }
    />
  );
}

export default function SessionTypes() {
  const [sessionTypes, setSessionTypes] = useState<SessionType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);
  const [actionLoading, setActionLoading] = useState<{
    [key: string]: boolean;
  }>({});

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const res = await fetchSessionTypes();
      setSessionTypes(res);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setErrorOccured(true);
    }
  };

  useEffect(() => {
    document.title = "Session Types | Bytebeam";
  });

  useAsyncEffect(handleUpdate, []);

  const formatCondition = (
    condition: Condition,
    wrapInBrackets: boolean = false
  ) => {
    const compositeOpertors = ["and", "or"];

    if (compositeOpertors.includes(condition.operator)) {
      const conditions = (condition as CompositeCondition).conditions;
      const s = conditions
        .map((c) => formatCondition(c, true))
        .join(` ${condition.operator} `);
      return wrapInBrackets ? `(${s})` : s;
    } else {
      const c = condition as SimpleCondition;
      const s = `${c.field} ${c.operator} ${c.value}`;

      return wrapInBrackets ? `(${s})` : s;
    }
  };

  const _startSessionType = async (name: string) => {
    try {
      setActionLoading((prevLoading) => ({
        ...prevLoading,
        [name]: true,
      }));
      await startSessionType(name);
      beamtoast.success(`Started session type '${name}'`);
      const res = await fetchSessionTypes();
      setSessionTypes(res);
    } catch (e) {
      beamtoast.error(`Failed to start session type '${name}'`);
      console.log(e);
    } finally {
      setActionLoading((prevLoading) => ({
        ...prevLoading,
        [name]: false,
      }));
    }
  };

  const _stopSessionType = async (name: string) => {
    try {
      setActionLoading((prevLoading) => ({
        ...prevLoading,
        [name]: true,
      }));
      await stopSessionType(name);
      beamtoast.success(`Stopped session type '${name}'`);
      const res = await fetchSessionTypes();
      setSessionTypes(res);
    } catch (e) {
      beamtoast.error(`Failed to stop session type '${name}'`);
      console.log(e);
    } finally {
      setActionLoading((prevLoading) => ({
        ...prevLoading,
        [name]: false,
      }));
    }
  };

  const renderToggleButton = (name: string, status: string) => {
    if (status === "stopped") {
      return (
        <ButtonIcon
          title={"Play"}
          link
          name="play"
          onClick={() => _startSessionType(name)}
        />
      );
    } else {
      return (
        <ButtonIcon
          title={"Stop"}
          link
          name="stop"
          onClick={() => _stopSessionType(name)}
        />
      );
    }
  };

  const renderDeleteButton = (name: string, sessionStatus: string) => {
    if (sessionStatus === "stopped") {
      return (
        <ConfirmationModal
          trigger={<ButtonIcon link name="trash" />}
          prefixContent="Delete Session Type"
          expectedText={name}
          message={
            <ConfirmationModalMessage
              name={name}
              type={"Session Type"}
              specialMessage="Note that this will not delete any sessions already created"
            />
          }
          onConfirm={async () => {
            try {
              await deleteSessionType(name);
              beamtoast.success(`Deleted session type '${name}'`);
              handleUpdate();
            } catch (e) {
              beamtoast.error(`Failed to delete session type '${name}'`);
              console.log(e);
            }
          }}
        />
      );
    } else {
      return (
        <Popup
          id="cannot-delete-session-type-popup"
          content="Can't delete a running session"
          inverted
          position="top center"
          trigger={<Icon link name="trash" disabled={true} />}
        />
      );
    }
  };

  if (errorOccured) {
    return <ErrorMessage marginTop="280px" errorMessage />;
  }

  if (loading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="65vh"
        fontSize="1.5rem"
        loadingText="Loading Session Types"
      />
    );
  }

  return (
    <Layout
      buttons={
        <CreateSessionTypeButton
          onUpdate={handleUpdate}
          sessionTypes={sessionTypes}
        />
      }
    >
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Input Stream</Table.HeaderCell>
            <Table.HeaderCell width={4}>Condition</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Last Processed Timestamp</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sessionTypes.length !== 0 ? (
            sessionTypes.map(
              ({
                name,
                stream,
                condition,
                status,
                last_processed_timestamps,
              }) => {
                const lpt = last_processed_timestamps || {};

                return (
                  <Table.Row key={name}>
                    <Table.Cell>{name}</Table.Cell>
                    <Table.Cell>{stream}</Table.Cell>
                    <Table.Cell>{formatCondition(condition, false)}</Table.Cell>
                    <Table.Cell>
                      {capitalizeFirstLetter(status ?? "-")}
                    </Table.Cell>
                    <Table.Cell>
                      {Object.keys(lpt).map((key) => {
                        return (
                          <div key={key}>
                            shard-{key}:{" "}
                            {moment
                              .duration(lpt[key] - new Date().valueOf())
                              .humanize()}{" "}
                            ago
                          </div>
                        );
                      })}
                    </Table.Cell>
                    <Table.Cell>
                      {actionLoading[name] ? (
                        <span
                          style={{
                            display: "inline-block",
                            marginRight: "6px",
                          }}
                        >
                          <LoadingAnimation
                            loaderSize="12px"
                            loaderBorderSize="3px"
                          />
                        </span>
                      ) : (
                        renderToggleButton(name, status ?? "stopped")
                      )}
                      {renderDeleteButton(name, status ?? "stopped")}
                    </Table.Cell>
                  </Table.Row>
                );
              }
            )
          ) : (
            <Table.Row>
              <Table.Cell colSpan={9}>
                <ErrorMessage
                  marginTop="30px"
                  message={"No Session Types found!"}
                />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Layout>
  );
}
