import React from "react";
import { Icon, Button } from "semantic-ui-react";
import CreateMetadataModal from "./CreateMetadataModal";
import * as uuid from "uuid";

interface CreateMetadataButtonProps {
  createKey: (id: string) => (key: string) => Promise<void>;
  updateKey: (id: string) => (key: string) => Promise<void>;
  metadataKeysSet: Set<string>;
}

export default function CreateMetadataButton(props: CreateMetadataButtonProps) {
  const { createKey } = props;
  return (
    <CreateMetadataModal
      title="Create Metadata"
      metadataKeysSet={props.metadataKeysSet}
      onSubmit={async (key) => {
        try {
          await createKey(uuid.v4())(key);
          // await updateKey(key);
        } catch (e) {
          console.log(e);
        }
      }}
      trigger={
        <Button primary floated="right" icon labelPosition="left">
          <Icon name="plus" />
          Create Metadata
        </Button>
      }
    />
  );
}
