import React from "react";

type ConfirmationModalMessageProps = {
  readonly name: string;
  readonly type: string;
  readonly specialMessage?: string;
};

function ConfirmationModalMessage(props: ConfirmationModalMessageProps) {
  const { name, type, specialMessage } = props;
  return (
    <>
      <p>
        <span>Are you absolutely sure?</span>
        <br />
        You are going to delete <b>{name}</b>, this {type} will be deleted along
        with all of its content.
        <br />
        {specialMessage && (
          <b>
            <em>{specialMessage}</em>
          </b>
        )}
      </p>
      <div
        style={{
          padding: "8px",
          color: "#c50000",
          textAlign: "center",
          background: "#f7d4d6",
          border: "1px solid #c50000",
          borderRadius: "4px",
        }}
      >
        <b>Warning:</b> This action is not reversible. Please be certain.
      </div>
      <p>
        {/* Adding `pre-wrap` to the style to prevent the text changing*/}
        <br /> Please type <b style={{ whiteSpace: "pre-wrap" }}>{name}</b> to
        proceed or press Cancel to close.
      </p>
    </>
  );
}

export default ConfirmationModalMessage;
