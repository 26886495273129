import React, { MouseEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Pagination, PaginationProps } from "semantic-ui-react";
import {
  ActionStatusDetail,
  ActionStatusType,
  fetchAction,
  fetchActionStatusDetails,
} from "../../../BytebeamClient";
import ActionAccordion from "./ActionAccordion";
import DeviceList from "./DeviceList";
import OverviewActionData from "./OverviewActionData";
import ProgressChart from "./ProgressChart";
import { User } from "../../../util";
import LoadingAnimation from "../../common/Loader";
import { inProgressStatuses } from "./util";

interface ActionOverviewParams {
  actionID: string;
}

interface ActionOverviewProps {
  user: User;
}

function ActionOverview(props: ActionOverviewProps) {
  const { actionID } = useParams<ActionOverviewParams>();

  const [loading, setLoading] = useState(true);

  const [actionData, setActionData] = useState<ActionStatusType>();

  const [initialNumTotal, setInitialNumTotal] = useState<number>(0);

  const [totalDevices, setTotalDevices] = useState<number>(0);
  const [queuedCount, setQueuedCount] = useState<number>(0);
  const [initiatedCount, setInitiatedCount] = useState<number>(0);
  const [completedCount, setCompletedCount] = useState<number>(0);
  const [failedCount, setFailedCount] = useState<number>(0);
  const [inProgressCount, setInProgressCount] = useState<number>(0);

  const [devicesPageNumber, setDevicesPageNumber] = useState(1);
  const devicesPageLimit: number = 5;

  const [deviceItems, setDeviceItems] = useState<ActionStatusDetail[]>([]);

  const [devicesLoading, setDevicesLoading] = useState(true);

  const [filterStatus, setFilterStatus] = useState(false);
  const [filterName, setFilterName] = useState<string>("");

  const fetchStatusDetails = async (
    devicesPageNumber,
    status: string | string[]
  ) => {
    setDevicesLoading(true);
    fetchActionStatusDetails(
      Number(actionID),
      status,
      devicesPageNumber,
      devicesPageLimit
    )
      .then((res) => {
        setDeviceItems(res.device_actions);
        setDevicesLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDevicesFromAction = () => {
    fetchStatusDetails(
      devicesPageNumber,
      `${filterStatus ? filterName : "all"}`
    );
  };

  const onPaginationChange = (event: MouseEvent, data: PaginationProps) => {
    const activePage = data.activePage as number;
    setDevicesLoading(true);
    setDevicesPageNumber(activePage);
    fetchStatusDetails(
      activePage,
      filterStatus
        ? filterName === "In Progress"
          ? inProgressStatuses
          : filterName
        : "all"
    );
  };

  const fetchActionData = () => {
    setInProgressCount(0);
    setLoading(true);
    fetchAction(Number(actionID))
      .then((data) => {
        setActionData(data);
        const numTotal: any = Object.values(data.statuses).reduce(
          (a: any, b: any) => a + b
        );

        const { Queued, Initiated, Completed, Failed, ...remaining } =
          data.statuses;

        setTotalDevices(numTotal);
        setInitialNumTotal(numTotal);
        setCompletedCount(Completed ? Completed : 0);
        setQueuedCount(Queued ? Queued : 0);
        setInitiatedCount(Initiated ? Initiated : 0);
        setFailedCount(Failed ? Failed : 0);
        Object.keys(remaining).map((status) =>
          setInProgressCount((count) => count + remaining[status])
        );
        setLoading(false);
        fetchDevicesFromAction();
      })
      .catch((e) => console.log(e));
  };

  const fetchFilteredStatusDetails = async (status: string | string[]) => {
    setDevicesLoading(true);
    setDevicesPageNumber(1);
    fetchActionStatusDetails(Number(actionID), status, 1, devicesPageLimit)
      .then((res) => {
        setDeviceItems(res.device_actions);
        fetchActionStatusDetails(Number(actionID), status, 1, 10000) // need list without pagination so applying this hack :(
          .then((res) => {
            setTotalDevices(res.device_actions.length);
            setDevicesLoading(false);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const filterData = (label: string) => {
    switch (label) {
      case "Initiated Actions":
        setFilterName("Initiated");
        fetchFilteredStatusDetails("Initiated");
        break;

      case "Queued Actions":
        setFilterName("Queued");
        fetchFilteredStatusDetails("Queued");
        break;

      case "In Progress Actions":
        setFilterName("In Progress");
        fetchFilteredStatusDetails(inProgressStatuses);
        break;

      case "Completed Actions":
        setFilterName("Completed");
        fetchFilteredStatusDetails("Completed");
        break;

      case "Failed Actions":
        setFilterName("Failed");
        fetchFilteredStatusDetails("Failed");
        break;

      default:
        setFilterName("");
        fetchStatusDetails(devicesPageNumber, "all");
        break;
    }
    setFilterStatus(true);
  };

  useEffect(() => {
    fetchActionData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return loading ? (
    <LoadingAnimation
      loaderContainerHeight="65vh"
      fontSize="20px"
      loadingText="Loading Action data"
    />
  ) : (
    <div>
      <p style={{ fontSize: "1.2rem", margin: "2rem 0" }}>
        Action {actionID}: Overview
      </p>
      <div
        style={{
          marginBottom: "4rem",
        }}
      >
        <ProgressChart
          setInitiatedListCount={initiatedCount}
          setQueuedListCount={queuedCount}
          setProgressListCount={inProgressCount}
          setFailedListCount={failedCount}
          setCompletedListCount={completedCount}
          forDevice={false}
        />
      </div>

      <OverviewActionData
        actionData={actionData}
        deviceCount={initialNumTotal}
        fetchActionData={fetchActionData}
        user={props.user}
      />

      <div style={{ marginTop: "3rem" }} />

      {actionData && (
        <ActionAccordion
          actionData={actionData}
          setTotalCount={initialNumTotal}
          setInitiatedListCount={initiatedCount}
          setQueuedListCount={queuedCount}
          setProgressListCount={inProgressCount}
          setFailedListCount={failedCount}
          setCompletedListCount={completedCount}
          onClick={(label: string) => filterData(label)}
          filterStatus={filterStatus}
        />
      )}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p style={{ fontSize: "1.2rem", margin: "2rem 0 0 0" }}>
          Device List {filterName !== "" ? `(${filterName})` : ""}
        </p>
        <p
          style={{
            fontSize: "1rem",
            margin: "2rem 0 0 0",
            cursor: "pointer",
            textDecoration: "underline",
            visibility: filterStatus ? "visible" : "hidden",
          }}
          onClick={() => {
            setTotalDevices(initialNumTotal);
            setDevicesPageNumber(1);
            fetchStatusDetails(1, "all");
            setFilterStatus(false);
            setFilterName("");
          }}
        >
          Clear Filters
        </p>
      </div>
      <DeviceList
        deviceData={deviceItems}
        actionID={actionID}
        showInModal={false}
        loading={devicesLoading}
      />
      {!devicesLoading && deviceItems?.length !== 0 && (
        <Pagination
          boundaryRange={0}
          defaultActivePage={devicesPageNumber}
          ellipsisItem={null}
          siblingRange={2}
          totalPages={Math.ceil(totalDevices / devicesPageLimit)}
          onPageChange={onPaginationChange}
        />
      )}
    </div>
  );
}

export default ActionOverview;
