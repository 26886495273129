import React from "react";
import { TimeRange } from "../../Dashboards/Datetime/TimeRange";
import LogsComponent from "./LogsComponent";
import { ErrorMessage } from "../../../common/ErrorMessage";

export type LogsPageProps = {
  readonly timeRange: TimeRange;
  readonly onTimeRangeChange: (timeRange: TimeRange) => void;
  readonly loading: boolean;
  readonly setLoading: (arg: boolean) => void;
  readonly logStream: string;
  readonly logsStreams: string[];
  readonly selectedDevice: string;
};

function ActionLog(props: LogsPageProps) {
  if (props.logsStreams.length === 0) {
    return <ErrorMessage marginTop="72px" message={"No Logs streams found"} />;
  }
  return (
    <LogsComponent
      timeRange={props.timeRange}
      onTimeRangeChange={props.onTimeRangeChange}
      loading={props.loading}
      setLoading={props.setLoading}
      table={props.logStream}
      title={"action log"}
      filterBys={{ id: [props.selectedDevice] }}
    />
  );
}

export default ActionLog;
