import React, { Component } from "react";
import { Accordion, Icon, Segment } from "semantic-ui-react";
import { ActionStatusType } from "../../../BytebeamClient";
import BarChart from "./BarChart";
import ActionLegend from "./ActionLegend";

interface ActionAccordionProps {
  actionData: ActionStatusType;
  setTotalCount: number;
  setInitiatedListCount: number;
  setQueuedListCount: number;
  setProgressListCount: number;
  setFailedListCount: number;
  setCompletedListCount: number;
  onClick: Function;
  filterStatus: boolean;
}

export default class ActionAccordion extends Component<ActionAccordionProps> {
  state = { activeIndex: 0 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;

    return (
      <Segment inverted>
        <Accordion fluid inverted>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={this.handleClick}
          >
            <p
              style={{
                fontSize: "1.2rem",
                margin: 0,
              }}
            >
              <Icon name="dropdown" />
              Device wise Summary
            </p>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <div
              style={{
                margin: "0 8rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "2rem",
                  marginTop: "4rem",
                }}
              >
                <div
                  style={{
                    marginRight: "6rem",
                  }}
                >
                  <BarChart
                    setTotalCount={this.props.setTotalCount}
                    setInitiatedListCount={this.props.setInitiatedListCount}
                    setQueuedListCount={this.props.setQueuedListCount}
                    setProgressListCount={this.props.setProgressListCount}
                    setFailedListCount={this.props.setFailedListCount}
                    setCompletedListCount={this.props.setCompletedListCount}
                    height={350}
                    width={700}
                    textInfo={true}
                  />
                </div>
                <ActionLegend
                  onClick={(label: string) => this.props.onClick(label)}
                  filterStatus={this.props.filterStatus}
                  liStyles={{
                    paddingBottom: "0.5rem",
                  }}
                />
              </div>
            </div>
          </Accordion.Content>
        </Accordion>
      </Segment>
    );
  }
}
