import React, { useCallback, useEffect } from "react";
import { OptionType } from "../util";
import { Form } from "semantic-ui-react";
import { beamtoast } from "../../../common/CustomToast";

type UploadTextProps = {
  setDisableNextButton: (arg0: boolean) => void;
  setOptionType: (arg0: OptionType) => void;
  optionType: OptionType;
  uploadTriggered: string;
  setUploadTriggered: (arg0: string) => void;
  uploadTriggeredEach: boolean;
  setActiveModal: (arg0: number) => void;
  activeModal: number;
  customInputTextData: string;
  setCustomInputTextData: (arg0: string) => void;
};

export default function UploadText(props: UploadTextProps) {
  const handleSubmit = () => {
    if (props.customInputTextData && props.customInputTextData !== "")
      // valid data, move ahead
      props.setActiveModal(props.activeModal + 1);
    else {
      props.setUploadTriggered("");
      beamtoast.error("Please enter a text to trigger the action");
    }
  };

  const handleTextChange = useCallback((e) => {
    props.setOptionType(OptionType.UploadText);
    props.setCustomInputTextData(e.target.value);
    if (e.target.value && e.target.value !== "")
      props.setDisableNextButton(false);
    else props.setDisableNextButton(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    props.setUploadTriggered("");
    props.setOptionType(OptionType.UploadText);
    if (props.customInputTextData === "") props.setDisableNextButton(true);
    else props.setDisableNextButton(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.uploadTriggered === "trigger") {
      switch (props.optionType) {
        case OptionType.UploadText:
          handleSubmit();
          break;
      }
    }
  }, [props.uploadTriggered, props.uploadTriggeredEach]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <p style={{ fontSize: "1.1rem" }}>Enter Text Payload</p>
      <Form style={{ marginBottom: "16px" }}>
        <textarea
          rows={3}
          placeholder="Text payload"
          value={props.customInputTextData}
          onChange={handleTextChange}
        />
      </Form>
    </>
  );
}
