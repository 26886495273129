import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";
import { Mixpanel } from "../../Screens/common/MixPanel";
import { isHostMicelioHideTabs, User } from "../../../util";
import ByteBeamLogo from "../ByteBeamLogo";
import { DisplayIf } from "../../Screens/util";
import Toggle from "../Toggle";
import UserSummaryWithProjectPicker from "./UserSummaryWithProjectPicker";
import { ThemeContext } from "styled-components";

type MobileNavBarProps = {
  user: User;
  activeItem: string;
  setActiveItem: (item: string) => void;
  currentTenant: string;
  showSettingsTab: () => boolean;
  handleThemeToggle: () => Promise<void>;
  isMultiTenant: boolean;
  setCurrentTenant: (tenant: string) => void;
  setShowNewProjectModal: (show: boolean) => void;
};

type NavigationalTabProps = {
  name: string;
  title: string;
  to: string;
  shouldDisplay: boolean;
  showTabsVisible?: boolean;
  activeItem: string;
  handleClick: () => void;
};

function NavigationalTab(props: NavigationalTabProps) {
  const {
    name,
    title,
    to,
    shouldDisplay,
    showTabsVisible,
    activeItem,
    handleClick,
  } = props;
  const isShowTabsVisible = showTabsVisible !== false;
  return (
    <DisplayIf cond={shouldDisplay && isShowTabsVisible}>
      <Menu.Item
        style={{
          display: "flex",
          paddingLeft: "4px",
        }}
        as={Link}
        to={to}
        name={name}
        active={activeItem === name}
        onClick={handleClick}
      >
        <Icon
          className={"angle right"}
          style={{
            float: "none",
            marginRight: "8px",
            marginLeft: "0px",
            fontSize: "18px",
          }}
        />
        <h4 style={{ fontSize: "14px", fontWeight: 700, marginTop: "0px" }}>
          {title.toUpperCase()}
        </h4>
      </Menu.Item>
    </DisplayIf>
  );
}

type NonNavigationalTabProps = {
  name: string;
  title: string;
  shouldDisplay: boolean;
  showTabsVisible?: boolean;
  activeItem: string;
  isOpen: boolean;
  handleClick: () => void;
  rightShift?: string;
};

function NonNavigationalTab(props: NonNavigationalTabProps) {
  const {
    name,
    title,
    shouldDisplay,
    showTabsVisible,
    activeItem,
    isOpen,
    handleClick,
    rightShift,
  } = props;
  const isShowTabsVisible = showTabsVisible !== false;
  return (
    <DisplayIf cond={shouldDisplay && isShowTabsVisible}>
      <Menu.Item
        style={{
          display: "flex",
          paddingLeft: "4px",
        }}
        name={name}
        active={activeItem === name}
        onClick={handleClick}
      >
        <Icon
          className={isOpen ? "angle down" : "angle right"}
          style={{
            float: "none",
            marginRight: "8px",
            marginLeft: rightShift ?? "0px",
            fontSize: "18px",
          }}
        />
        <h4 style={{ fontSize: "14px", fontWeight: 700, marginTop: "0px" }}>
          {title}
        </h4>
      </Menu.Item>
    </DisplayIf>
  );
}

type NestedNavigationalTabProps = {
  name: string;
  title: string;
  to: string;
  shouldDisplay: boolean;
  activeItem: string;
  handleClick: () => void;
  icon: string;
  rightShift?: string;
};

function NestedNavigationalTab(props: NestedNavigationalTabProps) {
  const {
    name,
    title,
    to,
    shouldDisplay,
    activeItem,
    handleClick,
    icon,
    rightShift,
  } = props;

  const location = useLocation();
  return (
    <DisplayIf cond={shouldDisplay}>
      <Menu.Item
        style={{
          display: "flex",
        }}
        as={Link}
        to={to}
        name={name}
        active={activeItem === name}
        onClick={() => {
          // TODO: Remove this hack once we have a better solution
          // =========================== Hack Starts ===========================
          let currentParentPath = location.pathname
            .split("/")
            .slice(0, -1)
            ?.join("/");
          let toParentPath = to.split("/").slice(0, -1)?.join("/");
          let isInventory =
            location.pathname.includes("inventory") || to.includes("inventory");
          let isActionSummary =
            location.pathname.includes("action-summary") ||
            to.includes("action-summary");
          if (
            toParentPath === currentParentPath ||
            (isInventory && isActionSummary)
          ) {
            window.location.href = to;
          }
          // ============================ Hack Ends ============================
          handleClick();
        }}
      >
        <Icon
          className={icon}
          style={{
            float: "none",
            marginRight: "12px",
            marginLeft: rightShift ?? "18px",
          }}
        />
        <h4
          style={{
            fontSize: "14px",
            fontWeight: 600,
            marginTop: "0px",
          }}
        >
          {title}
        </h4>
      </Menu.Item>
    </DisplayIf>
  );
}

function MobileNavBar(props: MobileNavBarProps) {
  const {
    user,
    activeItem,
    setActiveItem,
    currentTenant,
    showSettingsTab,
    handleThemeToggle,
    isMultiTenant,
    setCurrentTenant,
    setShowNewProjectModal,
  } = props;

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isAlertsOpen, setIsAlertsOpen] = useState<boolean>(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
  const [isActionsOpen, setIsActionsOpen] = useState<boolean>(false);
  const [isInventoryOpen, setIsInventoryOpen] = useState<boolean>(false);

  const permissions = user?.role?.permissions || {};

  const themeContext = useContext(ThemeContext);
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  useEffect(() => {
    setIsDarkTheme(themeContext?.name?.toLowerCase() === "dark");
  }, [themeContext]);

  const sessionsEnabled =
    user.role.name === "admin" && !isHostMicelioHideTabs();
  // const alertRulesEnabled = user.role.name === "admin";

  return (
    <Menu
      className="main-navbar mob-view"
      fixed="top"
      borderless
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        height: isMenuOpen ? "100vh" : "auto",
        overflowY: "auto",
      }}
    >
      <Menu
        className="main-navbar mob-menu"
        fluid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "62px",
        }}
      >
        <Menu.Item
          position="left"
          style={{
            padding: "12px 24px",
          }}
          className="logo"
          as={Link}
          to={
            permissions.showDeviceManagementTab
              ? `/projects/${currentTenant}/device-management/devices`
              : `/projects/${currentTenant}/dashboards`
          }
          onClick={() =>
            permissions.showDeviceManagementTab
              ? setActiveItem("device-management")
              : setActiveItem("dashboards")
          }
        >
          <ByteBeamLogo
            height="30px"
            user={user}
            currentTenant={currentTenant}
          />
        </Menu.Item>

        <Menu.Item position="right">
          <Icon
            name={isMenuOpen ? "close" : "bars"}
            size="large"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          />
        </Menu.Item>
      </Menu>

      {isMenuOpen && (
        <Menu
          vertical
          fluid
          borderless
          className="main-navbar"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            minHeight: "100vh",
          }}
        >
          <div>
            <UserSummaryWithProjectPicker
              currentTenant={currentTenant}
              setCurrentTenant={setCurrentTenant}
              isMultiTenant={isMultiTenant}
              setShowNewProjectModal={setShowNewProjectModal}
            />

            {/* Get-Started Link  */}
            <NavigationalTab
              name="get-started"
              title="Get Started"
              to={`/projects/${currentTenant}/get-started`}
              shouldDisplay={true}
              activeItem={activeItem}
              handleClick={() => {
                setActiveItem("get-started");
                setIsMenuOpen(!isMenuOpen);
              }}
            />

            {/* Device Management Link  */}
            <NavigationalTab
              name="device-management"
              title="DEVICE MANAGEMENT"
              to={`/projects/${currentTenant}/device-management/devices`}
              shouldDisplay={permissions.showDeviceManagementTab}
              showTabsVisible={
                user?.["tenant-settings"]?.show_tabs?.device_management?.show
              }
              activeItem={activeItem}
              handleClick={() => {
                setActiveItem("device-management");
                setIsMenuOpen(!isMenuOpen);
              }}
            />

            {/* Dashboards Link  */}
            <NavigationalTab
              name="dashboards"
              title="Dashboards"
              to={`/projects/${currentTenant}/dashboards`}
              shouldDisplay={permissions.showDashboardsTab}
              showTabsVisible={
                user?.["tenant-settings"]?.show_tabs?.dashboards?.show
              }
              activeItem={activeItem}
              handleClick={() => {
                setActiveItem("dashboards");
                setIsMenuOpen(!isMenuOpen);
              }}
            />

            {/* Actions Link  */}
            <NonNavigationalTab
              name="actions"
              title="ACTIONS"
              shouldDisplay={permissions.showActionsTab}
              showTabsVisible={
                user?.["tenant-settings"]?.show_tabs?.actions?.show
              }
              activeItem={activeItem}
              isOpen={isActionsOpen}
              handleClick={() => {
                setIsActionsOpen(!isActionsOpen);
                setActiveItem("actions");
              }}
            />

            {/* Actions Nested Link */}
            {isActionsOpen && (
              <>
                <NestedNavigationalTab
                  to={`/projects/${currentTenant}/actions/live-actions`}
                  name="Action Details"
                  title="Action Details"
                  shouldDisplay={permissions.allowedActions?.length > 0}
                  activeItem={activeItem}
                  handleClick={() => {
                    setActiveItem("Actions Summary");
                    setIsMenuOpen(!isMenuOpen);
                  }}
                  icon={"tv"}
                />

                {/* Inventory Management Link */}
                <NonNavigationalTab
                  name="Inventory"
                  title="Inventory"
                  shouldDisplay={
                    permissions.viewFirmwares || permissions.viewDeviceConfigs
                  }
                  activeItem={activeItem}
                  isOpen={isInventoryOpen}
                  handleClick={() => {
                    setActiveItem("Inventory");
                    setIsInventoryOpen(!isInventoryOpen);
                  }}
                  rightShift="30px"
                />

                {/* Inventory Management Nested Link */}
                {isInventoryOpen && (
                  <>
                    <NestedNavigationalTab
                      to={`/projects/${currentTenant}/actions/inventory/firmware-files`}
                      name={"Firmware Files"}
                      title={"Firmware Files"}
                      shouldDisplay={permissions.viewFirmwares}
                      activeItem={activeItem}
                      handleClick={() => {
                        setActiveItem("Firmware Files");
                        setIsMenuOpen(!isMenuOpen);
                      }}
                      icon={"file alternate outline"}
                      rightShift="64px"
                    />
                    <NestedNavigationalTab
                      to={`/projects/${currentTenant}/actions/inventory/json-configurations`}
                      name={"JSON Configurations"}
                      title={"JSON Configurations"}
                      shouldDisplay={permissions.viewDeviceConfigs}
                      activeItem={activeItem}
                      handleClick={() => {
                        setActiveItem("JSON Configurations");
                        setIsMenuOpen(!isMenuOpen);
                      }}
                      icon={"code"}
                      rightShift="64px"
                    />
                    <NestedNavigationalTab
                      to={`/projects/${currentTenant}/actions/inventory/geofence-configurations`}
                      name={"Geofence Configurations"}
                      title={"Geofence Configurations"}
                      shouldDisplay={permissions.viewDeviceConfigs}
                      activeItem={activeItem}
                      handleClick={() => {
                        setActiveItem("Geofence Configurations");
                        setIsMenuOpen(!isMenuOpen);
                      }}
                      icon={"street view"}
                      rightShift="64px"
                    />
                  </>
                )}
                <NestedNavigationalTab
                  to={`/projects/${currentTenant}/actions/new-action`}
                  name="New Action"
                  title="New Action"
                  shouldDisplay={permissions.allowedActions?.length > 0}
                  activeItem={activeItem}
                  handleClick={() => {
                    setActiveItem("Actions Summary");
                    setIsMenuOpen(!isMenuOpen);
                  }}
                  icon={"plus square outline"}
                />
              </>
            )}

            {/* Alerts Link  */}
            <NonNavigationalTab
              name="alerts"
              title="ALERTS"
              shouldDisplay={user.role.name === "admin"}
              showTabsVisible={
                user?.["tenant-settings"]?.show_tabs?.alerts?.show
              }
              activeItem={activeItem}
              isOpen={isAlertsOpen}
              handleClick={() => {
                setActiveItem("alerts");
                setIsAlertsOpen(!isAlertsOpen);
                // setIsMenuOpen(!isMenuOpen);
              }}
            />

            {isAlertsOpen && (
              <>
                <NestedNavigationalTab
                  to={`/projects/${currentTenant}/alerts/alert-rules`}
                  name={"Alert Rules"}
                  title={"Alert Rules"}
                  shouldDisplay={true}
                  activeItem={activeItem}
                  handleClick={() => {
                    setActiveItem("Alert Rules");
                    setIsMenuOpen(!isMenuOpen);
                  }}
                  icon={"file alternate outline"}
                />
                <NestedNavigationalTab
                  to={`/projects/${currentTenant}/alerts/alert-notification`}
                  name={"Notification Settings"}
                  title={"Notification Settings"}
                  shouldDisplay={true}
                  activeItem={activeItem}
                  handleClick={() => {
                    setActiveItem("Notification Settings");
                    setIsMenuOpen(!isMenuOpen);
                  }}
                  icon={"code"}
                />
              </>
            )}

            {/* Settings Link  */}
            <NonNavigationalTab
              name="settings"
              title="SETTINGS"
              shouldDisplay={showSettingsTab()}
              activeItem={activeItem}
              isOpen={isSettingsOpen}
              handleClick={() => {
                setIsSettingsOpen(!isSettingsOpen);
                setActiveItem("settings");
              }}
            />

            {/* Settings Nested Link */}
            {isSettingsOpen && (
              <>
                <NestedNavigationalTab
                  to={`/projects/${currentTenant}/settings/general`}
                  name={"General"}
                  title={"General"}
                  shouldDisplay={user.role.permissions.editTenantSettings}
                  activeItem={activeItem}
                  handleClick={() => {
                    setActiveItem("General");
                    setIsMenuOpen(!isMenuOpen);
                  }}
                  icon={"key"}
                />
                <NestedNavigationalTab
                  to={`/projects/${currentTenant}/settings/users`}
                  name={"Users"}
                  title={"Users"}
                  shouldDisplay={permissions.viewUsers}
                  activeItem={activeItem}
                  handleClick={() => {
                    setActiveItem("Users");
                    setIsMenuOpen(!isMenuOpen);
                  }}
                  icon={"users"}
                />
                <NestedNavigationalTab
                  to={`/projects/${currentTenant}/settings/streams`}
                  name="Streams"
                  title="Streams"
                  shouldDisplay={permissions.viewStreams}
                  activeItem={activeItem}
                  handleClick={() => {
                    setActiveItem("Streams");
                    setIsMenuOpen(!isMenuOpen);
                  }}
                  icon={"table"}
                />
                <NestedNavigationalTab
                  to={`/projects/${currentTenant}/settings/roles`}
                  name="Roles"
                  title="Roles"
                  shouldDisplay={permissions.viewRoles}
                  activeItem={activeItem}
                  handleClick={() => {
                    setActiveItem("Roles");
                    setIsMenuOpen(!isMenuOpen);
                  }}
                  icon={"settings"}
                />
                <NestedNavigationalTab
                  to={`/projects/${currentTenant}/settings/metadata`}
                  name={"Metadata"}
                  title={"Metadata"}
                  shouldDisplay={permissions.viewMetadataKeys}
                  activeItem={activeItem}
                  handleClick={() => {
                    setActiveItem("Metadata");
                    setIsMenuOpen(!isMenuOpen);
                  }}
                  icon={"hdd"}
                />
                <NestedNavigationalTab
                  to={`/projects/${currentTenant}/settings/action_types`}
                  name={"Action Types"}
                  title={"Action Types"}
                  shouldDisplay={permissions.viewActionTypes}
                  activeItem={activeItem}
                  handleClick={() => {
                    setActiveItem("Action Types");
                    setIsMenuOpen(!isMenuOpen);
                  }}
                  icon={"sitemap"}
                />
                <NestedNavigationalTab
                  to={`/projects/${currentTenant}/settings/api_keys`}
                  name={"API Keys"}
                  title={"API Keys"}
                  shouldDisplay={user.role.name === "admin"}
                  activeItem={activeItem}
                  handleClick={() => {
                    setActiveItem("API Keys");
                    setIsMenuOpen(!isMenuOpen);
                  }}
                  icon={"key"}
                />
                <NestedNavigationalTab
                  to={`/projects/${currentTenant}/settings/session_types`}
                  name={"Session Types"}
                  title={"Session Types"}
                  shouldDisplay={sessionsEnabled}
                  activeItem={activeItem}
                  handleClick={() => {
                    setActiveItem("Session Types");
                    setIsMenuOpen(!isMenuOpen);
                  }}
                  icon={"history"}
                />
                <NestedNavigationalTab
                  to={`/projects/${currentTenant}/settings/dbc`}
                  name="DBC Parsers"
                  title="DBC Parsers"
                  shouldDisplay={
                    user?.["tenant-settings"]?.show_tabs?.dbc_parsers?.show ||
                    true
                  } // subject to change
                  activeItem={activeItem}
                  handleClick={() => {
                    setActiveItem("DBC");
                    setIsMenuOpen(!isMenuOpen);
                  }}
                  icon={"microchip"}
                />
              </>
            )}
          </div>
          <div style={{ padding: "48px 0px 16px 0px" }}>
            <Menu.Item>
              <Icon
                name="adjust"
                style={{ float: "none", marginRight: "12px" }}
              />
              Dark Mode{" "}
              <Toggle
                id="theme-toggle"
                size="medium"
                style={{
                  marginLeft: "12px",
                  top: "4px",
                }}
                checked={isDarkTheme}
                onClick={() => handleThemeToggle()}
              />
            </Menu.Item>

            <Menu.Item
              onClick={() => {
                Mixpanel.track("Logout");
                window.location.href = "/logout";
              }}
            >
              <Icon
                name="power off"
                style={{ float: "none", marginRight: "12px" }}
              />
              Logout
            </Menu.Item>
          </div>
        </Menu>
      )}
    </Menu>
  );
}

export default MobileNavBar;
