import React, { useState, useEffect } from "react";
import { ViewLogs } from "../../Dashboards/Panel/Logs/ViewLogs";
import { FetchParams } from "../../Dashboards/Panel/util";
import { Settings } from "../../../../util";
import { DashboardsInfo } from "../../Dashboards/ViewDashboard";
import { TableInfo, fetchPanelData } from "../../../../BytebeamClient";
import { DashboardType } from "../../Dashboards/EditDashboardModal";
import { ReplayState } from "../../Dashboards/DashboardHeader";
import LoadingAnimation from "../../../common/Loader";

export type LogsComponentProps = {
  readonly timeRange: any;
  readonly onTimeRangeChange: (timeRange: any) => void;
  readonly loading: boolean;
  readonly setLoading: (arg: boolean) => void;
  readonly title: string;
  readonly filterBys: Record<string, string[]>;
  readonly table: string;
};

function LogsComponent(props: LogsComponentProps) {
  const {
    timeRange,
    onTimeRangeChange,
    loading,
    setLoading,
    title,
    filterBys,
    table,
  } = props;

  const [panelData, setPanelData] = useState({});

  const fetchParams: FetchParams = {
    groupBys: [],
    fetchAll: false,
    timeRange: timeRange,
    filterBys: filterBys,
  };
  const panelMeta = {
    type: "logs",
    logLevel: "",
    tags: [],
    searchString: "",
    table: table,
    rowsPerPage: 10,
    showSearchBar: true,
    numBins: 50,
    id: title,
    title: title,
    description: "",
  };

  useEffect(() => {
    let panelMetaData = { ...panelMeta };

    const fetchData = async () => {
      const abortController = new AbortController();
      const panelsData = await fetchPanelData(
        [panelMetaData],
        fetchParams,
        abortController.signal,
        DashboardType.DeviceDashboard
      );
      setPanelData(panelsData[0]?.data);
      setLoading(false);
    };

    setLoading(true);
    if (table) {
      panelMetaData["table"] = table;
      fetchData();
    }
  }, [table, filterBys.id[0], timeRange]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <LoadingAnimation
        fontSize="16px"
        loaderContainerHeight="60vh"
        loaderSize="42px"
        loadingText={`Loading ${title}...`}
      />
    );
  } else if (!loading && Object.keys(panelData).length > 0)
    return (
      <ViewLogs
        timeRange={timeRange}
        onTimeRangeChange={onTimeRangeChange}
        panelMeta={panelMeta}
        // @ts-ignore
        data={panelData}
        fetchParams={fetchParams}
        settings={{} as Settings}
        dashboards={[] as DashboardsInfo[]}
        tables={{} as TableInfo}
        replayStep={0}
        replayTimestamp={0}
        replayState={ReplayState.ReplayStopped}
      />
    );
  else {
    return <div>No data</div>;
  }
}

export default LogsComponent;
