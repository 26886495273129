import React, { useState, memo, useEffect, MouseEvent } from "react";
import {
  Grid,
  AccordionTitle,
  AccordionContent,
  Icon,
  Popup,
  Pagination,
  PaginationProps,
} from "semantic-ui-react";
import { capitalizeFirstLetter } from "../../Screens/util";
import { useHistory, useRouteMatch } from "react-router-dom";
import moment from "moment";
import { ThinDivider } from "../Dashboards/Panel/util";
import {
  ActionStatusDetail,
  ActionStatusType,
  fetchActionStatusDetails,
} from "../../../BytebeamClient";
import ViewActionPayloadModal from "./action-modals/ViewActionPayloadModal";
import DeviceList from "./DeviceList";
import ProgressStatus from "./ProgressStatus";
import {
  StyledGridColumn,
  StyledCard,
} from "../DeviceManagement/Devices/Device";
import styled from "styled-components";
import { useUser } from "../../../context/User.context";
import PhaseList from "./PhaseList";
import { inProgressStatuses } from "./util";

const MoreDetailsButton = styled.span`
  margin-right: 1rem;
  font-size: 1rem;
  color: ${(props) => props.theme.colors["link-text-color"]};
  cursor: pointer;
`;

type ActionCardProp = {
  action: ActionStatusType;
  activeIndex: number;
  setActiveIndex: (value: number) => any;
  users: { name: string; email: string }[];
};

function ActionCard(props: ActionCardProp) {
  let { action, activeIndex, setActiveIndex } = props;

  const [openPayloadModal, setOpenPayloadModal] = useState(false);
  const [devicesPageNumber, setDevicesPageNumber] = useState(1);

  const devicesPageLimit = 5;

  const [deviceItems, setDeviceItems] = useState<ActionStatusDetail[]>([]);
  const [numTotal, setNumTotal] = useState<number>(0);
  const [initialNumTotal, setInitialNumTotal] = useState<number>(0);
  const [queued, setQueued] = useState<number>(0);
  const [initiated, setInitiated] = useState<number>(0);
  const [completed, setCompleted] = useState<number>(0);
  const [failed, setFailed] = useState<number>(0);
  const [inProgress, setInProgress] = useState<number>(0);
  const [devicesLoading, setDevicesLoading] = useState(true);
  const [filterStatus, setFilterStatus] = useState(false);
  const [filterName, setFilterName] = useState<string>("");

  const [email, setEmail] = useState<string>();

  const history = useHistory();
  const { path } = useRouteMatch(); // Gets the relative path
  const { user } = useUser();

  const actionID = action["action_id"];
  const type = action["type"];
  const createdAt = action["created_at"];
  const statuses = action["statuses"];
  const userName = action["user_name"];
  const params = action["params"];
  const phasesList = action?.schedule?.phases || [];
  // const payloadType = action["payload_type"];

  const showPayloadTxt = params?.length > 0 && params !== "{}" ? true : false;

  if (showPayloadTxt && params) {
    try {
      JSON.parse(params);
    } catch (e) {
      console.log("error in parsing Action params");
      console.log(e);
    }
  }

  function handleCollapse(index: number, activeIndex: number) {
    let newIndex = -1;
    setNumTotal(initialNumTotal);
    setDevicesPageNumber(1);
    setFilterStatus(false);
    setFilterName("");
    if (activeIndex === index) {
      fetchStatusDetails(1, "all");
    } else {
      newIndex = index;
      onToggleAccordion();
    }
    setActiveIndex(newIndex);
  }

  const fetchStatusDetails = async (
    devicesPageNumber,
    status: string | string[]
  ) => {
    setDevicesLoading(true);
    fetchActionStatusDetails(
      actionID,
      status,
      devicesPageNumber,
      devicesPageLimit
    )
      .then((res) => {
        setDeviceItems(res.device_actions);
        setDevicesLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchFilteredStatusDetails = async (status: string | string[]) => {
    setDevicesLoading(true);
    setDevicesPageNumber(1);
    fetchActionStatusDetails(actionID, status, 1, devicesPageLimit)
      .then((res) => {
        setDeviceItems(res.device_actions);
        fetchActionStatusDetails(actionID, status, 1, 10000) // need list without pagination so applying this hack :(
          .then((res) => {
            setNumTotal(res.device_actions.length);
            setDevicesLoading(false);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onToggleAccordion = () => {
    fetchStatusDetails(
      devicesPageNumber,
      `${filterStatus ? filterName : "all"}`
    );
  };

  const onPaginationChange = (event: MouseEvent, data: PaginationProps) => {
    const activePage = data.activePage as number;
    setDevicesLoading(true);
    setDevicesPageNumber(activePage);
    fetchStatusDetails(
      activePage,
      filterStatus
        ? filterName === "In Progress"
          ? inProgressStatuses
          : filterName
        : "all"
    );
  };

  const filterData = (label: string) => {
    switch (label) {
      case "Initiated":
        setFilterName("Initiated");
        fetchFilteredStatusDetails("Initiated");
        break;

      case "Queued":
        setFilterName("Queued");
        fetchFilteredStatusDetails("Queued");
        break;

      case "In Progress":
        setFilterName("In Progress");
        fetchFilteredStatusDetails(inProgressStatuses);
        break;

      case "Completed":
        setFilterName("Completed");
        fetchFilteredStatusDetails("Completed");
        break;

      case "Failed":
        setFilterName("Failed");
        fetchFilteredStatusDetails("Failed");
        break;

      default:
        setFilterName("");
        fetchStatusDetails(devicesPageNumber, "all");
        break;
    }
    setFilterStatus(true);
  };

  useEffect(() => {
    setInitiated(0);
    setQueued(0);
    setInProgress(0);
    setCompleted(0);
    setFailed(0);
    const numTotal: any = Object.values(statuses).reduce(
      (a: any, b: any) => a + b
    );

    const { Queued, Initiated, Completed, Failed, ...remaining } = statuses;

    setNumTotal(numTotal);
    setInitialNumTotal(numTotal);
    setCompleted(Completed ?? 0);
    setQueued(Queued ?? 0);
    setInitiated(Initiated ?? 0);
    setFailed(Failed ?? 0);
    Object.keys(remaining).forEach((status) =>
      setInProgress((prev) => prev + remaining[status])
    );
  }, [props.action]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setEmail(props.users.find((user) => user.name === userName)?.email);
  }, [props.action]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StyledCard>
        <AccordionTitle
          active={actionID === activeIndex}
          index={actionID}
          onClick={() => handleCollapse(actionID, activeIndex)}
        >
          <Grid columns={6}>
            <Grid.Row>
              <StyledGridColumn>{actionID}</StyledGridColumn>

              <StyledGridColumn>
                <Popup
                  content={moment(createdAt).format(
                    "ddd, MMM Do YYYY, HH:mm:ss"
                  )}
                  position="top center"
                  inverted
                  trigger={
                    <div>
                      {createdAt
                        ? capitalizeFirstLetter(moment(createdAt).fromNow())
                        : "--"}
                    </div>
                  }
                />
              </StyledGridColumn>

              <StyledGridColumn>
                {type ? capitalizeFirstLetter(type) : "--"}
              </StyledGridColumn>
              <StyledGridColumn>
                {email ? (
                  <Popup
                    content={email}
                    position="top center"
                    inverted
                    trigger={<div>{userName}</div>}
                  />
                ) : (
                  <div>{userName}</div>
                )}
              </StyledGridColumn>
              <StyledGridColumn>
                <ProgressStatus
                  actionID={actionID}
                  initiatedCount={initiated}
                  queuedCount={queued}
                  inProgressCount={inProgress}
                  completedCount={completed}
                  failedCount={failed}
                  onClick={(label: string) => {
                    filterData(label);
                  }}
                  filterStatus={filterStatus}
                  activeIndex={props.activeIndex}
                  setActiveIndex={(index: number) =>
                    props.setActiveIndex(index)
                  }
                />
              </StyledGridColumn>

              <StyledGridColumn>
                {showPayloadTxt ? (
                  <span
                    style={{
                      marginLeft: "2rem",
                      color: "#808183",
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      className="hover-underline payload-hover"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenPayloadModal(true);
                      }}
                    >
                      Payload
                    </span>
                  </span>
                ) : (
                  <span
                    style={{
                      color: "#808183",
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    --
                  </span>
                )}

                <span
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    // width: "33%",
                  }}
                >
                  <Icon
                    style={{ fontSize: "24px" }}
                    name={
                      actionID === activeIndex ? "angle down" : "angle right"
                    }
                  />
                </span>
              </StyledGridColumn>
            </Grid.Row>
          </Grid>
        </AccordionTitle>
        <AccordionContent active={actionID === activeIndex}>
          <Grid>
            <ThinDivider style={{ margin: "20px 0px 8px 0px" }} />

            {/* Show this when phased action is there */}
            {phasesList && phasesList.length > 0 && (
              <>
                <Grid.Row
                  style={{
                    paddingBottom: "0px",
                  }}
                >
                  <StyledGridColumn
                    width={8}
                    style={{ justifyContent: "flex-start", fontWeight: "700" }}
                  >
                    Phases Summary
                  </StyledGridColumn>
                </Grid.Row>
                <Grid.Row>
                  <StyledGridColumn
                    style={{ alignItems: "flex-start" }}
                    width={16}
                  >
                    <PhaseList phaseData={phasesList} />
                  </StyledGridColumn>
                </Grid.Row>
              </>
            )}
            {/* Show this when phased action is there */}

            <Grid.Row
              style={{
                paddingBottom: "0px",
              }}
            >
              <StyledGridColumn
                width={8}
                style={{ justifyContent: "flex-start", fontWeight: "700" }}
              >
                Device Overview {filterName !== "" ? `(${filterName})` : ""}
              </StyledGridColumn>
              <StyledGridColumn
                width={8}
                style={{
                  justifyContent: "flex-end",
                  fontWeight: "700",
                }}
              >
                <p
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    visibility: filterStatus ? "visible" : "hidden",
                  }}
                  onClick={() => {
                    setNumTotal(initialNumTotal);
                    setDevicesPageNumber(1);
                    fetchStatusDetails(1, "all");
                    setFilterStatus(false);
                    setFilterName("");
                  }}
                >
                  Clear filters
                </p>
              </StyledGridColumn>
            </Grid.Row>
            <Grid.Row>
              <StyledGridColumn style={{ alignItems: "flex-start" }} width={16}>
                <DeviceList
                  deviceData={deviceItems}
                  actionID={actionID}
                  showInModal={false}
                  loading={devicesLoading}
                />
              </StyledGridColumn>
            </Grid.Row>
            <Grid.Row>
              <StyledGridColumn
                style={{ alignItems: "flex-start", justifyContent: "left" }}
                width={16}
              >
                {!devicesLoading && (
                  <Pagination
                    boundaryRange={0}
                    defaultActivePage={devicesPageNumber}
                    ellipsisItem={null}
                    siblingRange={2}
                    totalPages={Math.ceil(numTotal / devicesPageLimit)}
                    onPageChange={onPaginationChange}
                  />
                )}
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                    width: "100%",
                  }}
                >
                  <MoreDetailsButton
                    id={`more-details-button-${actionID}`}
                    onClick={() => {
                      const basePath = path.substring(0, path.lastIndexOf("/"));
                      history.push(`${basePath}/${actionID}/action-overview`);
                    }}
                    className="hover-underline"
                    style={{ marginRight: "1rem" }}
                  >
                    More Details
                  </MoreDetailsButton>
                </span>
              </StyledGridColumn>
            </Grid.Row>
          </Grid>
        </AccordionContent>
      </StyledCard>
      <ViewActionPayloadModal
        isOpen={openPayloadModal}
        close={() => {
          setOpenPayloadModal(false);
        }}
        action={action}
        theme={user?.settings?.theme ?? "dark"}
      />
    </>
  );
}

export default memo(ActionCard);
