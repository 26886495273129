import React, { useEffect, useState } from "react";
import { Button, Popup, Table } from "semantic-ui-react";
import {
  ActionStatusType,
  markActionAsCompleted,
} from "../../../BytebeamClient";
import ViewActionPayloadModal from "./action-modals/ViewActionPayloadModal";
import { capitalizeFirstLetter } from "../util";
import moment from "moment";
import { User } from "../../../util";
import { beamtoast } from "../../common/CustomToast";
import { inProgressStatuses } from "./util";

interface OverviewDataProps {
  actionData?: ActionStatusType;
  deviceCount?: number;
  fetchActionData: () => void;
  user: User;
}

function OverviewActionData(props: OverviewDataProps) {
  const [openPayloadModal, setOpenPayloadModal] = useState(false);
  const [overallStatus, setOverallStatus] = useState<string>();

  const showPayloadTxt = props.actionData
    ? props.actionData.params?.length > 0 && props.actionData?.params !== "{}"
      ? true
      : false
    : false;

  if (showPayloadTxt && props.actionData?.params) {
    try {
      JSON.parse(props.actionData.params);
    } catch (e) {
      console.log("error in parsing Action params");
      console.log(e);
    }
  }

  const markAsCompleted = async () => {
    let actionId = props.actionData?.action_id;
    let actionID = String(actionId!);

    try {
      await markActionAsCompleted(actionID, { all: true });
      beamtoast.success(`Marked Action ${actionId} as completed!`);
      props.fetchActionData();
    } catch (e) {
      console.log(e);
    }
  };

  const checkForInProgressStatus = (statusKeys: string[]) => {
    return statusKeys.some((r) => inProgressStatuses.includes(r));
  };

  useEffect(() => {
    if (props.actionData) {
      let statusKeys: string[] = Object.keys(props.actionData.statuses);
      if (statusKeys.includes("Failed")) setOverallStatus("Failed");
      else if (statusKeys.includes("Completed")) setOverallStatus("Completed");
      else if (checkForInProgressStatus(statusKeys))
        setOverallStatus("In Progress");
      else if (statusKeys.includes("Queued")) setOverallStatus("Queued");
      else setOverallStatus("Initiated");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Table celled fixed>
        <Table.Body>
          {/* <Table.Row> */}
          {/* <Table.Cell>Action Name</Table.Cell> */}
          {/* <Table.Cell className="table-border-right">--</Table.Cell> */}
          {/* <Table.Cell>Version</Table.Cell> */}
          {/* <Table.Cell className="table-border-right">
              {props.actionData?.params
                ? JSON.parse(props.actionData?.params).version
                : "--"}
            </Table.Cell> */}
          {/* <Table.Cell width={1}>Devices:</Table.Cell>
            <Table.Cell className="table-border-right" width={1}>
              <b>{props.deviceCount}</b>
            </Table.Cell> */}
          {/* <Table.Cell
            //  rowspan={2}
            >
              <Button
                primary
                icon
                fluid
                className="min-width-150"
                onClick={() => {
                  markAsCompleted();
                }}
              >
                <p style={{ padding: "0 2rem" }}>Mark as Complete</p>
              </Button>
            </Table.Cell> */}
          {/* </Table.Row> */}
          <Table.Row>
            <Table.Cell className="table-border-bottom">
              Action Type:
            </Table.Cell>
            <Table.Cell className="table-border-right table-border-left-none table-border-bottom">
              <b>
                {props.actionData?.type
                  ? capitalizeFirstLetter(props.actionData?.type)
                  : "--"}
              </b>
            </Table.Cell>
            <Table.Cell className="table-border-bottom">
              Triggered By:
            </Table.Cell>
            <Table.Cell className="table-border-right table-border-left-none table-border-bottom">
              <b>{props.actionData?.user_name}</b>
            </Table.Cell>
            <Table.Cell width={1} rowspan={2}>
              Devices:
            </Table.Cell>
            <Table.Cell
              className="table-border-right table-border-left-none"
              width={1}
              rowspan={2}
            >
              <b>{props.deviceCount}</b>
            </Table.Cell>
            {/* <Table.Cell width={1} rowspan={2}>
              Filter:
            </Table.Cell>
            <Table.Cell className="table-border-right" width={1} rowspan={2}>
              --
            </Table.Cell> */}
            <Table.Cell
              className="table-border-bottom-none"
              //  rowspan={2}
            >
              <Button
                primary
                icon
                fluid
                disabled={
                  !props.user.role.permissions.allowMarkActionAsCompleted
                }
                title={
                  props.user.role.permissions.allowMarkActionAsCompleted
                    ? ""
                    : "You do not have the necessary permissions"
                }
                className="min-width-150"
                onClick={() => {
                  markAsCompleted();
                }}
              >
                <p style={{ padding: "0 2rem" }}>Mark as Complete</p>
              </Button>
            </Table.Cell>
          </Table.Row>
          <Table.Cell>Action Status:</Table.Cell>
          <Table.Cell className="table-border-right">
            <b>{props.actionData ? overallStatus || "--" : "--"}</b>
          </Table.Cell>
          <Table.Cell>Triggered At:</Table.Cell>
          <Table.Cell className="table-border-right">
            <b>
              {props.actionData?.created_at ? (
                <Popup
                  content={`${new Date(
                    props.actionData?.created_at
                  ).toDateString()}
                    ${new Date(
                      props.actionData?.created_at
                    ).toLocaleTimeString()}`}
                  position="top center"
                  inverted
                  trigger={
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {capitalizeFirstLetter(
                        moment
                          .duration(
                            -1 *
                              moment().diff(
                                moment.utc(props.actionData?.created_at)
                              )
                          )
                          .humanize(true)
                      )}
                    </div>
                  }
                />
              ) : (
                "--"
              )}
            </b>
          </Table.Cell>
          <Table.Cell>
            {showPayloadTxt ? (
              <Button
                icon
                fluid
                color="grey"
                onClick={() => {
                  setOpenPayloadModal(true);
                }}
              >
                <p style={{ padding: "0 1rem" }}>View Payload</p>
              </Button>
            ) : (
              <p style={{ padding: "0 1rem", textAlign: "center" }}>
                No Payload
              </p>
            )}
          </Table.Cell>
        </Table.Body>
      </Table>
      <ViewActionPayloadModal
        isOpen={openPayloadModal}
        close={() => {
          setOpenPayloadModal(false);
        }}
        action={props.actionData}
        theme={props.user?.settings?.theme ?? "dark"}
      />
    </>
  );
}

export default OverviewActionData;
