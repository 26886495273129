import React, { useCallback, useEffect } from "react";
import { OptionType } from "../util";
import { Form } from "semantic-ui-react";
import {
  StyledFileInput,
  StyledFileUploadButton,
} from "../../DeviceManagement/Devices/ActionModals/SendFileModal";
import { uploadFile } from "../../../../BytebeamClient";
import AnimatedEllipsis from "../../common/AnimatedEllipsis";
import { beamtoast } from "../../../common/CustomToast";

type SendFileProps = {
  setOptionType: (arg0: OptionType) => void;
  optionType: OptionType;
  uploadTriggered: string;
  setUploadTriggered: (arg0: string) => void;
  uploadTriggeredEach: boolean;
  setActiveModal: (arg0: number) => void;
  activeModal: number;
  customFileInputData: string;
  setCustomFileInputData: (arg0: string) => void;
  setDisableNextButton: (arg0: boolean) => void;
  file: File;
  setFile: (arg0: File) => void;
  fileName: string;
  setFileName: (arg0: string) => void;
  showUploadProgress: boolean;
  setShowUploadProgress: (arg0: boolean) => void;
  fileLoaded: number;
  setFileLoaded: (arg0: number) => void;
  fileTotal: number;
  setFileTotal: (arg0: number) => void;
  uploadedFileResponse: {
    status: number;
    data: { id: string };
  };
  setUploadedFileResponse: (arg0: {
    status: number;
    data: { id: string };
  }) => void;
  script: File;
  setScript: (arg0: File) => void;
  scriptName: string;
  setScriptName: (arg0: string) => void;
  showScriptUploadProgress: boolean;
  setShowScriptUploadProgress: (arg0: boolean) => void;
  scriptLoaded: number;
  setScriptLoaded: (arg0: number) => void;
  scriptTotal: number;
  setScriptTotal: (arg0: number) => void;
  uploadedScriptResponse: {
    status: number;
    data: { id: string };
  };
  setUploadedScriptResponse: (arg0: {
    status: number;
    data: { id: string };
  }) => void;
  fileInput: any;
  action: string;
};

export default function SendFile(props: SendFileProps) {
  const fileUpload = async (file: File, fileName: string) => {
    props.setDisableNextButton(true);
    // File Upload code
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);

    try {
      const url = `/api/v1/file`;
      await uploadFile(url, formData, (p) => {
        props.setFileLoaded(p.loaded);
        props.setFileTotal(p.total);
      }).then((res) => {
        props.setUploadedFileResponse(res);
        props.setDisableNextButton(false);

        // move to next modal for summary and triggering respective action
        props.setCustomFileInputData(res.data.id);
        props.setActiveModal(props.activeModal + 1);

        beamtoast.success("File uploaded successfully!");
      });
    } catch (error) {
      // Handling Error for File Size exceeding limit from NGINX
      if (String(error).includes("413")) {
        beamtoast.error("Upload failed due to size limit!");
      } else if (String(error).includes("401")) {
        beamtoast.error("Upload failed as access is not there!");
      } else beamtoast.error("Failed to upload File");
      props.setDisableNextButton(false);
    }
  };

  const onSelect = useCallback((e) => {
    props.setOptionType(OptionType.SendFile);
    props.setShowUploadProgress(false);
    props.setFileLoaded(0);
    props.setFileTotal(0);
    if (e.target.files.length !== 0) {
      props.setFile(e.target.files[0]);
      props.setFileName(e.target.files[0].name);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = () => {
    if (
      props.file !== new File([""], "filename") &&
      props.fileName !== "" &&
      props.showUploadProgress &&
      props.fileLoaded !== 0 &&
      props.fileTotal !== 0 &&
      props.customFileInputData !== ""
    ) {
      // form already has data, no need to upload again unless file has been selected again
      props.setActiveModal(props.activeModal + 1);
    } else {
      if (props.fileInput?.current?.files) {
        const fileCount = props.fileInput.current.files.length;
        if (fileCount === 0) {
          props.setUploadTriggered("");
          setTimeout(() => beamtoast.error("An upload file must be selected!"));
        } else {
          // form is valid
          props.setShowUploadProgress(true);
          fileUpload(props.file, props.fileName);
        }
      }
    }
  };

  useEffect(() => {
    props.setUploadTriggered("");
    if (!props.showUploadProgress) {
      props.setFile(new File([""], "filename"));
      props.setFileName("");
      props.setCustomFileInputData("");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.file !== new File([""], "filename") && props.fileName !== "") {
      props.setOptionType(OptionType.SendFile);
    }

    if (props.uploadTriggered === "trigger") {
      switch (props.optionType) {
        case OptionType.SendFile:
          handleSubmit();
          break;
      }
    }
  }, [props.uploadTriggered, props.uploadTriggeredEach]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form>
      <Form.Field>
        <label>
          Upload {props.action === "send_script" ? "Script" : "File"} from the
          system:
        </label>
        <div style={{ position: "relative" }}>
          <StyledFileUploadButton
            fluid
            content={`Select ${
              props.action === "send_script" ? "Script" : "File"
            }`}
            labelPosition="left"
            icon="file"
          />
          <StyledFileInput
            type="file"
            id="file"
            ref={props.fileInput}
            onChange={onSelect}
          />
        </div>
        <label style={{ marginTop: "12px" }}>
          {props.action === "send_script" ? "Script" : "File"} Chosen:
        </label>
        {props.fileName
          ? props.fileName
          : props.action === "send_script"
            ? "No Script Chosen"
            : "No File Chosen"}
      </Form.Field>

      {props.showUploadProgress && (
        <Form.Field>
          <label htmlFor="file-progress">
            {props.uploadedFileResponse.status === 0 ? (
              <span>
                {props.action === "send_script" ? "Script" : "File"} Uploading
                <AnimatedEllipsis spacing={3} dotSize={"8px"} />
              </span>
            ) : (
              <span>
                {props.action === "send_script" ? "Script" : "File"} Uploaded
              </span>
            )}
          </label>
          <progress
            id="file-progress"
            max={props.fileTotal}
            value={props.fileLoaded}
          />
        </Form.Field>
      )}
    </Form>
  );
}
