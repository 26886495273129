import React, { createRef, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import SelectDevicesModal from "./SelectDevicesModal";
import UploadPayloadModal from "./UploadPayloadModal";
import {
  ActionDropdownType,
  DeviceFilterOption,
  Filters,
  Key,
  SearchDeviceResponse,
  fetchAllActionTypes,
  fetchAllDeviceConfiguration,
  fetchAllFirmwares,
  fetchAllMetadataKeys,
  fetchDeviceFilterOptions,
  searchDevices,
} from "../../../../BytebeamClient";
import { ActionType } from "../../../../util";
import { OptionType } from "../util";
import ActionExecutionSummaryModal from "./ActionExecutionSummaryModal";
import { capitalizeFirstLetter } from "../../util";
import { DropdownOptionType } from "../../DeviceManagement/Devices/ActionModals/UpdateConfigModal";
import { LatLng } from "../Inventory/DeviceConfigurations/GeofenceEditor";
import { useUser } from "../../../../context/User.context";

type NewActionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  setLoading: (arg0: boolean) => void;
  // fetchAllActions: () => void;
};

export type FilterListObject = {
  filterName: string;
  filterValue: string[];
};

function NewActionModal(props: NewActionModalProps) {
  let abortController = new AbortController();

  const [allKeys, setAllKeys] = useState<
    {
      key: string;
    }[]
  >([]);

  const [devices, setDevices] = useState<SearchDeviceResponse>();
  const [disableNextButton, setDisableNextButton] = useState<boolean>(true);
  const [disableSubmitButton, setDisableSubmitButton] =
    useState<boolean>(false);
  const [disableBackButton, setDisableBackButton] = useState<boolean>(false);
  const [uploadTriggered, setUploadTriggered] = useState<string>("");
  const [uploadTriggeredEach, setUploadTriggeredEach] =
    useState<boolean>(false);
  const [uploadExecuted, setUploadExecuted] = useState<string>("");
  const [activeModal, setActiveModal] = useState(1);

  const [optionType, setOptionType] = useState<OptionType>(
    OptionType.NoOptionSelected
  );

  const [selectedDevices, setSelectedDevices] = useState<SearchDeviceResponse>({
    devices: [],
    count: 0,
  });

  const [allSelected, setAllSelected] = useState<boolean>(false);

  const [action, setAction] = useState<string>("");
  const [payloadType, setPayloadType] = useState<string>("");

  const [filters, setFilters] = useState<Filters>({});
  const [metadataKeysToShow] = useState<string[]>([]);

  //----> useStates for SelectDevicesModal moved here <----//
  const [allowedFilterBys, setAllowedFilterBys] = useState<Key[]>([]);
  const [filterOptions, setFilterOptions] = useState<DeviceFilterOption[]>([]);
  const [filterLoading, setFilterLoading] = useState<boolean>(true);
  const [selectDevicesModalLoading, setSelectDevicesModalLoading] =
    useState<boolean>(true);
  const [selectDevicesPageLimit, setSelectDevicesPageLimit] =
    useState<number>(5);
  const [selectDevicesModalDevices, setSelectDevicesModalDevices] =
    useState<SearchDeviceResponse>({ devices: [], count: 0 });
  const [selectDevicesModalPage, setsSelectDevicesModalPage] =
    useState<number>(1);

  const [filterList, setFilterList] = useState<FilterListObject[]>([]);

  //----> useStates for uploadPayloadModal moved here <----//
  const [uploadPayloadLoading, setUploadPayloadLoading] =
    useState<boolean>(true);
  const [actionTypes, setActionTypes] = useState<ActionType[]>([]);
  const [actionDropdownList, setActionDropdownList] = useState<
    ActionDropdownType[]
  >([]);

  const [selectedAction, setSelectedAction] = useState<string>("");
  const [selectedConfigVersion, setSelectedConfigVersion] =
    useState<DropdownOptionType>({
      key: "",
      text: "",
      value: "",
    });
  const [selectedGeofenceVersion, setSelectedGeofenceVersion] =
    useState<DropdownOptionType>({
      key: "",
      text: "",
      value: "",
    });
  const [selectedFirmwareVersion, setSelectedFirmwareVersion] =
    useState<DropdownOptionType>({
      key: "",
      text: "",
      value: "",
    });
  const [selectedCustomConfigVersion, setSelectedCustomConfigVersion] =
    useState<string>("");
  const [selectedCustomGeofenceVersion, setSelectedCustomGeofenceVersion] =
    useState<string>("");
  const [selectedCustomFirmwareVersion, setSelectedCustomFirmwareVersion] =
    useState<string>("");
  const [customInputJSONData, setCustomInputJSONData] = useState<string>("");
  const [customInputTextData, setCustomInputTextData] = useState<string>("");
  const [customFirmwareInputData, setCustomFirmwareInputData] =
    useState<string>("");
  const [customFileInputData, setCustomFileInputData] = useState<string>("");
  const [customScriptInputData, setCustomScriptInputData] =
    useState<string>("");

  const [configActiveIndex, setConfigActiveIndex] = useState<number>(-1);
  const [geofenceActiveIndex, setGeofenceActiveIndex] = useState<number>(-1);
  const [firmwareActiveIndex, setFirmwareActiveIndex] = useState<number>(-1);

  const [firmwareOptions, setFirmwareOptions] = useState<DropdownOptionType[]>(
    []
  );
  const [allFirmwareOptions, setAllFirmwareOptions] = useState<Set<any>>();
  const [configJSONOptions, setConfigJSONOptions] = useState<
    DropdownOptionType[]
  >([]);
  const [JSONConfigs, setJSONConfigs] = useState<{
    [key: string]: any;
  }>({});
  const [configGeofenceOptions, setConfigGeofenceOptions] = useState<
    DropdownOptionType[]
  >([]);
  const [allConfigOptions, setAllConfigOptions] = useState<Set<any>>();

  const [firmwareOptionsLoaded, setFirmwareOptionsLoaded] =
    useState<boolean>(false);

  // firmware file data persistence
  const [firmwareFile, setFirmwareFile] = useState<File>(
    new File([""], "filename")
  );
  const [firmwareFileName, setFirmwareFileName] = useState<string>("");
  const [showFirmwareUploadProgress, setShowFirmwareUploadProgress] =
    useState<boolean>(false);
  const [firmwareFileLoaded, setFirmwareFileLoaded] = useState<number>(0);
  const [firmwareFileTotal, setFirmwareFileTotal] = useState<number>(0);
  const [uploadedFirmwareFileResponse, setUploadedFirmwareFileResponse] =
    useState<{
      status: number;
      data: { id: string };
    }>({
      status: 0,
      data: { id: "" },
    });

  // upload file data persistence
  const [file, setFile] = useState<File>(new File([""], "filename"));
  const [fileName, setFileName] = useState<string>("");
  const [showUploadProgress, setShowUploadProgress] = useState<boolean>(false);
  const [fileLoaded, setFileLoaded] = useState<number>(0);
  const [fileTotal, setFileTotal] = useState<number>(0);
  const [uploadedFileResponse, setUploadedFileResponse] = useState<{
    status: number;
    data: { id: string };
  }>({
    status: 0,
    data: { id: "" },
  });

  // upload script data persistence
  const [script, setScript] = useState<File>(new File([""], "filename"));
  const [scriptName, setScriptName] = useState<string>("");
  const [showScriptUploadProgress, setShowScriptUploadProgress] =
    useState<boolean>(false);
  const [scriptLoaded, setScriptLoaded] = useState<number>(0);
  const [scriptTotal, setScriptTotal] = useState<number>(0);
  const [uploadedScriptResponse, setUploadedScriptResponse] = useState<{
    status: number;
    data: { id: string };
  }>({
    status: 0,
    data: { id: "" },
  });

  const firmwareFileInput = createRef<HTMLInputElement>();
  const fileInput = createRef<HTMLInputElement>();
  const scriptInput = createRef<HTMLInputElement>();

  // upload geofence data persistence
  const [path, setPath] = useState<LatLng[]>([]);
  const [latitude, setLatitude] = useState<number>(12.927381); // defaults to bangalore
  const [longitude, setLongitude] = useState<number>(77.637729); // defaults to bangalore
  const [isPolygonComplete, setIsPolygonComplete] = useState<boolean>(false);
  const [alertThreshold, setAlertThreshold] = useState<number>(20);
  const [geofenceConfigs, setGeofenceConfigs] = useState<{
    [key: string]: any;
  }>({});

  // Getting user from the context
  const { user } = useUser();

  //----> functions for SelectDevicesModal <----//

  const refreshFilterOptions = async (filters: Filters, keys) => {
    // setFilterLoading(true);
    await fetchDeviceFilterOptions(filters).then((allFilterOptions) => {
      const filterOptionsMap = Object.fromEntries(
        allFilterOptions.map(({ filterName, filterValues }) => [
          filterName,
          filterValues,
        ])
      );
      if (keys) {
        let map = keys.map((filterName) => {
          return {
            filterName: filterName.key,
            filterValues: filterOptionsMap[filterName.key],
          };
        });
        setFilterOptions(map);
      }
      setFilterLoading(false);
    });
  };

  const refreshDevices = (pageNumber: number, myFilters: Filters) => {
    setSelectDevicesModalLoading(true);
    //setTimeout here is to allow change in filters/page using setState to take affect before refreshing devices;
    abortController.abort();
    abortController = new AbortController();
    setSelectDevicesModalDevices({ devices: [], count: 0 });
    setTimeout(async () => {
      // const status = showInActive ? "all" : "active";
      const status = "active"; // for now only showing active as we don't think actions are triggered on inactive devices
      try {
        await searchDevices(
          { metaDatafilters: myFilters },
          pageNumber,
          selectDevicesPageLimit,
          status,
          abortController.signal
        ).then((devices) => {
          setDevices(devices);
          setSelectDevicesModalDevices(devices);
          setSelectDevicesModalLoading(false);
        });
      } catch (e) {
        console.log(e);
      }
    });
  };

  const fetchMetadataKeys = async () => {
    setFilterLoading(true);
    setSelectDevicesModalLoading(true);
    await fetchAllMetadataKeys().then((keys) => {
      const idKey = { key: "id" };
      setAllKeys([idKey, ...keys]);
      setAllowedFilterBys([idKey, ...keys]);
      refreshFilterOptions({}, [idKey, ...keys]);
    });
  };

  const initialFetch = () => {
    fetchMetadataKeys();
    refreshDevices(1, {});
  };

  //----> functions for UploadPayloadModal <----//

  const getActionTypes = async () => {
    try {
      const res = await fetchAllActionTypes();
      const actionTypesToRender = res.filter((actionType: ActionType) => {
        return (
          actionType.type !== "launch_shell" &&
          user.role.permissions.allowedActions.indexOf(actionType.type) !== -1
        );
      }); //update_config & update_firmware & launch_shell & send_file are default action types
      // but we are only not trigerring launch_shell from here for now (TBD if to be included here)
      setActionTypes(actionTypesToRender);
      actionTypesToRender.forEach((item) => {
        let newDataItem: ActionDropdownType = {
          text: capitalizeFirstLetter(item.type),
          value: item.type,
          icon: item.icon,
          payload_type: item.payload_type,
        };
        setActionDropdownList((item) => [...item, newDataItem]);
        setUploadPayloadLoading(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const fillDropdownOptions = async () => {
    try {
      const res = await fetchAllDeviceConfiguration();
      let versions = new Set();

      res.forEach((o) => {
        versions.add(o.version_name);
      });

      const resJSON = res.filter(
        (config) =>
          (config.action_type === "update_config" ||
            config.action_type === null) &&
          !config.is_deactivated
      );

      const resGeofence = res.filter(
        (config) =>
          config.action_type === "update_geofence" && !config.is_deactivated
      );

      const JSONOptions = resJSON.map((o) => {
        return {
          key: o.tenant_id,
          value: o.version_name,
          text: o.version_name,
        };
      });

      const GeofenceOptions = resGeofence.map((o) => {
        return {
          key: o.tenant_id,
          value: o.version_name,
          text: o.version_name,
        };
      });

      const JSONConfigs = resJSON.reduce((acc, config) => {
        acc[config.version_name] = config;
        return acc;
      }, {});

      const GeofenceConfigs = resGeofence.reduce((acc, config) => {
        acc[config.version_name] = config;
        return acc;
      }, {});

      setJSONConfigs(JSONConfigs);
      setGeofenceConfigs(GeofenceConfigs);

      setConfigJSONOptions(JSONOptions);
      setConfigGeofenceOptions(GeofenceOptions);
      setAllConfigOptions(versions);
    } catch (e) {
      console.log(e);
    }
  };

  const fillFirmwareDropdownOptions = async () => {
    setFirmwareOptionsLoaded(false);
    try {
      let res = await fetchAllFirmwares();
      let versions = new Set();

      res.forEach((o) => {
        versions.add(o.version_number);
      });

      const resJSON = res.filter((o) => !o.is_deactivated);
      const options = resJSON.map((o) => {
        return {
          key: o.tenant_id,
          value: o.version_number,
          text: o.version_number,
        };
      });

      setAllFirmwareOptions(versions);
      setFirmwareOptions(options);
      setFirmwareOptionsLoaded(true);
    } catch (e) {
      setFirmwareOptionsLoaded(false);
      console.log(e);
    }
  };

  return (
    <Modal
      closeIcon
      className="dark"
      open={props.isOpen}
      size="large"
      onClose={() => {
        setDevices({ devices: [], count: 0 });
        setDisableNextButton(true);
        setDisableSubmitButton(false);
        setDisableBackButton(false);
        setUploadTriggered("");
        setUploadTriggeredEach(false);
        setUploadExecuted("");
        setActiveModal(1);
        setOptionType(OptionType.NoOptionSelected);

        setSelectedDevices({ devices: [], count: 0 });
        setAllSelected(false);
        setAction("");
        setPayloadType("");
        setFilters({});

        setAllowedFilterBys([]);
        setFilterOptions([]);
        setFilterLoading(true);
        setSelectDevicesModalLoading(true);
        setSelectDevicesPageLimit(5);
        setSelectDevicesModalDevices({ devices: [], count: 0 });
        setsSelectDevicesModalPage(1);

        setFilterList([]);

        setUploadPayloadLoading(true);
        setActionTypes([]);
        setActionDropdownList([]);
        setSelectedAction("");
        setSelectedConfigVersion({
          key: "",
          text: "",
          value: "",
        });
        setSelectedGeofenceVersion({
          key: "",
          text: "",
          value: "",
        });
        setSelectedFirmwareVersion({
          key: "",
          text: "",
          value: "",
        });
        setSelectedCustomConfigVersion("");
        setSelectedCustomGeofenceVersion("");
        setSelectedCustomFirmwareVersion("");
        setCustomInputJSONData("");
        setCustomInputTextData("");
        setCustomFirmwareInputData("");
        setCustomFileInputData("");
        setCustomScriptInputData("");
        setConfigActiveIndex(-1);
        setGeofenceActiveIndex(-1);
        setFirmwareActiveIndex(-1);
        setFirmwareOptions([]);
        setAllFirmwareOptions(new Set());
        setConfigJSONOptions([]);
        setConfigGeofenceOptions([]);
        setAllConfigOptions(new Set());
        setFirmwareOptionsLoaded(false);

        setFirmwareFile(new File([""], "filename"));
        setFirmwareFileName("");
        setShowFirmwareUploadProgress(false);
        setFirmwareFileLoaded(0);
        setFirmwareFileTotal(0);
        setUploadedFirmwareFileResponse({
          status: 0,
          data: { id: "" },
        });

        setFile(new File([""], "filename"));
        setFileName("");
        setShowUploadProgress(false);
        setFileLoaded(0);
        setFileTotal(0);
        setUploadedFileResponse({
          status: 0,
          data: { id: "" },
        });

        setScript(new File([""], "filename"));
        setScriptName("");
        setShowScriptUploadProgress(false);
        setScriptLoaded(0);
        setScriptTotal(0);
        setUploadedScriptResponse({
          status: 0,
          data: { id: "" },
        });

        setIsPolygonComplete(false);
        setPath([]);
        setAlertThreshold(20);

        setLatitude(12.927381);
        setLongitude(77.637729);

        props.onClose();
      }}
    >
      <Modal.Header> Create a new action </Modal.Header>

      <Modal.Content>
        {activeModal === 1 && (
          <SelectDevicesModal
            // setDevices={(devices) => setSelectDevicesModalDevices(devices)}
            devices={selectDevicesModalDevices}
            setDisableNextButton={(value) => setDisableNextButton(value)}
            filters={filters}
            setFilters={(filters) => setFilters(filters)}
            selectedDevices={selectedDevices}
            setSelectedDevices={(devices) => setSelectedDevices(devices)}
            allSelected={allSelected}
            setAllSelected={(value) => setAllSelected(value)}
            initialFetch={() => initialFetch()}
            refreshDevices={(pageNumber, myFilters) =>
              refreshDevices(pageNumber, myFilters)
            }
            refreshFilterOptions={(filter, keys) =>
              refreshFilterOptions(filter, keys)
            }
            abortController={abortController}
            allKeys={allKeys}
            allowedFilterBys={allowedFilterBys}
            setAllowedFilterBys={(values) => setAllowedFilterBys(values)}
            loading={selectDevicesModalLoading}
            setLoading={(value) => setSelectDevicesModalLoading(value)}
            filterLoading={filterLoading}
            setFilterLoading={(value) => setFilterLoading(value)}
            filterOptions={filterOptions}
            pageLimit={selectDevicesPageLimit}
            page={selectDevicesModalPage}
            setPage={(pageNumber) => setsSelectDevicesModalPage(pageNumber)}
            filterList={filterList}
            setFilterList={(value) => setFilterList(value)}
            metadataKeysToShow={metadataKeysToShow}
          />
        )}

        {activeModal === 2 && (
          <UploadPayloadModal
            user={user}
            devicesCount={selectedDevices ? selectedDevices.count : 0}
            setDisableNextButton={(value) => setDisableNextButton(value)}
            uploadTriggered={uploadTriggered}
            setUploadTriggered={(value) => setUploadTriggered(value)}
            uploadTriggeredEach={uploadTriggeredEach}
            setActiveModal={(index) => setActiveModal(index)}
            activeModal={activeModal}
            setOptionType={(option) => setOptionType(option)}
            optionType={optionType}
            action={action}
            setAction={(action) => setAction(action)}
            payloadType={payloadType}
            setPayloadType={(payload) => setPayloadType(payload)}
            getActionTypes={() => getActionTypes()}
            loading={uploadPayloadLoading}
            actionTypes={actionTypes}
            actionDropdownList={actionDropdownList}
            selectedAction={selectedAction}
            setSelectedAction={(action) => setSelectedAction(action)}
            selectedFirmwareVersion={selectedFirmwareVersion}
            setSelectedFirmwareVersion={(data) =>
              setSelectedFirmwareVersion(data)
            }
            firmwareOptions={firmwareOptions}
            allFirmwareOptions={allFirmwareOptions}
            fillFirmwareDropdownOptions={() => fillFirmwareDropdownOptions()}
            selectedConfigVersion={selectedConfigVersion}
            setSelectedConfigVersion={(version) =>
              setSelectedConfigVersion(version)
            }
            selectedGeofenceVersion={selectedGeofenceVersion}
            setSelectedGeofenceVersion={(version) =>
              setSelectedGeofenceVersion(version)
            }
            selectedCustomConfigVersion={selectedCustomConfigVersion}
            setSelectedCustomConfigVersion={(version) =>
              setSelectedCustomConfigVersion(version)
            }
            selectedCustomFirmwareVersion={selectedCustomFirmwareVersion}
            setSelectedCustomFirmwareVersion={(version) =>
              setSelectedCustomFirmwareVersion(version)
            }
            selectedCustomGeofenceVersion={selectedCustomGeofenceVersion}
            setSelectedCustomGeofenceVersion={(version) =>
              setSelectedCustomGeofenceVersion(version)
            }
            customInputJSONData={customInputJSONData}
            setCustomInputJSONData={(data) => setCustomInputJSONData(data)}
            customInputTextData={customInputTextData}
            setCustomInputTextData={(data) => setCustomInputTextData(data)}
            customFirmwareInputData={customFirmwareInputData}
            setCustomFirmwareInputData={(data) =>
              setCustomFirmwareInputData(data)
            }
            customFileInputData={customFileInputData}
            setCustomFileInputData={(data) => setCustomFileInputData(data)}
            customScriptInputData={customScriptInputData}
            setCustomScriptInputData={(data) => setCustomScriptInputData(data)}
            configActiveIndex={configActiveIndex}
            setConfigActiveIndex={(index) => setConfigActiveIndex(index)}
            geofenceActiveIndex={geofenceActiveIndex}
            setGeofenceActiveIndex={(index) => setGeofenceActiveIndex(index)}
            firmwareActiveIndex={firmwareActiveIndex}
            setFirmwareActiveIndex={(index) => setFirmwareActiveIndex(index)}
            configJSONOptions={configJSONOptions}
            configGeofenceOptions={configGeofenceOptions}
            allConfigOptions={allConfigOptions}
            fillDropdownOptions={() => fillDropdownOptions()}
            firmwareFile={firmwareFile}
            setFirmwareFile={(file) => setFirmwareFile(file)}
            firmwareFileName={firmwareFileName}
            setFirmwareFileName={(name) => setFirmwareFileName(name)}
            showFirmwareUploadProgress={showFirmwareUploadProgress}
            setShowFirmwareUploadProgress={(progress) =>
              setShowFirmwareUploadProgress(progress)
            }
            firmwareFileLoaded={firmwareFileLoaded}
            setFirmwareFileLoaded={(value) => setFirmwareFileLoaded(value)}
            firmwareFileTotal={firmwareFileTotal}
            setFirmwareFileTotal={(value) => setFirmwareFileTotal(value)}
            uploadedFirmwareFileResponse={uploadedFirmwareFileResponse}
            setUploadedFirmwareFileResponse={(res) =>
              setUploadedFirmwareFileResponse(res)
            }
            firmwareFileInput={firmwareFileInput}
            file={file}
            setFile={(file) => setFile(file)}
            fileName={fileName}
            setFileName={(name) => setFileName(name)}
            showUploadProgress={showUploadProgress}
            setShowUploadProgress={(progress) =>
              setShowUploadProgress(progress)
            }
            fileLoaded={fileLoaded}
            setFileLoaded={(value) => setFileLoaded(value)}
            fileTotal={fileTotal}
            setFileTotal={(value) => setFileTotal(value)}
            uploadedFileResponse={uploadedFileResponse}
            setUploadedFileResponse={(res) => setUploadedFileResponse(res)}
            script={script}
            setScript={(script) => setScript(script)}
            scriptName={scriptName}
            setScriptName={(name) => setScriptName(name)}
            showScriptUploadProgress={showScriptUploadProgress}
            setShowScriptUploadProgress={(progress) =>
              setShowScriptUploadProgress(progress)
            }
            scriptLoaded={scriptLoaded}
            setScriptLoaded={(value) => setScriptLoaded(value)}
            scriptTotal={scriptTotal}
            setScriptTotal={(value) => setScriptTotal(value)}
            uploadedScriptResponse={uploadedScriptResponse}
            setUploadedScriptResponse={(res) => setUploadedScriptResponse(res)}
            fileInput={fileInput}
            scriptInput={scriptInput}
            firmwareOptionsLoaded={firmwareOptionsLoaded}
            path={path}
            setPath={(path) => setPath(path)}
            isPolygonComplete={isPolygonComplete}
            setIsPolygonComplete={(value) => setIsPolygonComplete(value)}
            alertThreshold={alertThreshold}
            setAlertThreshold={(threshold) => setAlertThreshold(threshold)}
            latitude={latitude}
            setLatitude={(latitude) => setLatitude(latitude)}
            longitude={longitude}
            setLongitude={(longitude) => setLongitude(longitude)}
          />
        )}
        {activeModal === 3 && devices && (
          //  <ActionExecutionModal /> // currently only showing summary of selected options on this modal
          <ActionExecutionSummaryModal
            // fetchAllActions={() => props.fetchAllActions()}
            uploadExecuted={uploadExecuted}
            optionType={optionType}
            action={action}
            payloadType={payloadType}
            filters={filters}
            selectedFirmwareVersion={selectedFirmwareVersion}
            selectedCustomFirmwareVersion={selectedCustomFirmwareVersion}
            customFileInputData={customFileInputData}
            customScriptInputData={customScriptInputData}
            customInputTextData={customInputTextData}
            customInputJSONData={customInputJSONData}
            selectedConfigVersion={selectedConfigVersion}
            selectedCustomConfigVersion={selectedCustomConfigVersion}
            selectedGeofenceVersion={selectedGeofenceVersion}
            selectedCustomGeofenceVersion={selectedCustomGeofenceVersion}
            geofenceConfigs={geofenceConfigs}
            setGeofenceConfigs={(configs) => setGeofenceConfigs(configs)}
            JSONConfigs={JSONConfigs}
            setJSONConfigs={(configs) => setJSONConfigs(configs)}
            path={path}
            alertThreshold={alertThreshold}
            setDisableSubmitButton={(value) => setDisableSubmitButton(value)}
            setDisableBackButton={(value) => setDisableBackButton(value)}
            setLoading={(state) => props.setLoading(state)}
            onClose={() => props.onClose()}
            selectedDevices={selectedDevices}
            setSelectedDevices={(devices) => setSelectedDevices(devices)}
            allSelected={allSelected}
            deviceCount={devices.count}
            metadataKeysToShow={metadataKeysToShow}
          />
        )}
      </Modal.Content>

      <Modal.Actions>
        {activeModal === 1 && (
          <Button
            secondary
            onClick={() => {
              setDevices({ devices: [], count: 0 });
              setDisableNextButton(true);
              setDisableSubmitButton(false);
              setDisableBackButton(false);
              setUploadTriggered("");
              setUploadTriggeredEach(false);
              setUploadExecuted("");
              setActiveModal(1);
              setOptionType(OptionType.NoOptionSelected);

              setSelectedDevices({ devices: [], count: 0 });
              setAllSelected(false);
              setAction("");
              setPayloadType("");
              setFilters({});

              setAllowedFilterBys([]);
              setFilterOptions([]);
              setFilterLoading(true);
              setSelectDevicesModalLoading(true);
              setSelectDevicesPageLimit(5);
              setSelectDevicesModalDevices({ devices: [], count: 0 });
              setsSelectDevicesModalPage(1);

              setFilterList([]);

              setUploadPayloadLoading(true);
              setActionTypes([]);
              setActionDropdownList([]);
              setSelectedAction("");
              setSelectedConfigVersion({
                key: "",
                text: "",
                value: "",
              });
              setSelectedGeofenceVersion({
                key: "",
                text: "",
                value: "",
              });
              setSelectedFirmwareVersion({
                key: "",
                text: "",
                value: "",
              });
              setSelectedCustomConfigVersion("");
              setSelectedCustomGeofenceVersion("");
              setSelectedCustomFirmwareVersion("");
              setCustomInputJSONData("");
              setCustomInputTextData("");
              setCustomFirmwareInputData("");
              setCustomFileInputData("");
              setCustomScriptInputData("");
              setConfigActiveIndex(-1);
              setGeofenceActiveIndex(-1);
              setFirmwareActiveIndex(-1);
              setFirmwareOptions([]);
              setAllFirmwareOptions(new Set());
              setConfigJSONOptions([]);
              setConfigGeofenceOptions([]);
              setAllConfigOptions(new Set());
              setFirmwareOptionsLoaded(false);

              setFirmwareFile(new File([""], "filename"));
              setFirmwareFileName("");
              setShowFirmwareUploadProgress(false);
              setFirmwareFileLoaded(0);
              setFirmwareFileTotal(0);
              setUploadedFirmwareFileResponse({
                status: 0,
                data: { id: "" },
              });

              setFile(new File([""], "filename"));
              setFileName("");
              setShowUploadProgress(false);
              setFileLoaded(0);
              setFileTotal(0);
              setUploadedFileResponse({
                status: 0,
                data: { id: "" },
              });

              setScript(new File([""], "filename"));
              setScriptName("");
              setShowScriptUploadProgress(false);
              setScriptLoaded(0);
              setScriptTotal(0);
              setUploadedScriptResponse({
                status: 0,
                data: { id: "" },
              });

              setIsPolygonComplete(false);
              setPath([]);
              setAlertThreshold(20);

              setLatitude(12.927381);
              setLongitude(77.637729);

              props.onClose();
            }}
          >
            Cancel
          </Button>
        )}
        {activeModal !== 1 && (
          <Button
            secondary
            disabled={disableBackButton}
            onClick={() => {
              setActiveModal((current) => current - 1);
              if (activeModal === 3) {
                // repercussions fixed!
                setOptionType(OptionType.NoOptionSelected);
                setUploadTriggered("");
              }
            }}
          >
            Back
          </Button>
        )}
        {activeModal !== 3 && (
          <Button
            disabled={disableNextButton}
            primary
            onClick={() => {
              if (activeModal === 1) setActiveModal((current) => current + 1);
              else {
                setUploadTriggered("trigger");
                setUploadTriggeredEach((state) => !state);
              }
            }}
          >
            Next
          </Button>
        )}
        {activeModal === 3 && (
          <Button
            primary
            disabled={disableSubmitButton}
            onClick={() => {
              setUploadExecuted("execute");
            }}
          >
            Submit
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
}

export default NewActionModal;
