import React from "react";
import { OptionType } from "../util";
import { DropdownOptionType } from "../../DeviceManagement/Devices/ActionModals/UpdateConfigModal";
import SelectOrUploadGeofenceConfigAccordion from "./SelectOrUploadGeofenceConfigAccordion";
import { LatLng } from "../Inventory/DeviceConfigurations/GeofenceEditor";

type SelectOrUploadGeofenceConfigProps = {
  setDisableNextButton: (arg0: boolean) => void;
  setOptionType: (arg0: OptionType) => void;
  optionType: OptionType;
  uploadTriggered: string;
  setUploadTriggered: (arg0: string) => void;
  uploadTriggeredEach: boolean;
  setActiveModal: (arg0: number) => void;
  activeModal: number;
  geofenceActiveIndex: number;
  setGeofenceActiveIndex: (arg0: number) => void;
  setSelectedGeofenceVersion: (arg0: DropdownOptionType) => void;
  selectedGeofenceVersion: DropdownOptionType;
  selectedCustomGeofenceVersion: string;
  setSelectedCustomGeofenceVersion: (arg0: string) => void;
  configGeofenceOptions: DropdownOptionType[];
  fillDropdownOptions: () => void;
  allConfigOptions: Set<any> | undefined;
  path: LatLng[];
  setPath: (arg0: LatLng[]) => void;
  isPolygonComplete: boolean;
  setIsPolygonComplete: (arg0: boolean) => void;
  alertThreshold: number;
  setAlertThreshold: (arg0: number) => void;
  theme: string;
  latitude: number;
  setLatitude: (arg0: number) => void;
  longitude: number;
  setLongitude: (arg0: number) => void;
};

export default function SelectOrUploadGeofenceConfig(
  props: SelectOrUploadGeofenceConfigProps
) {
  return (
    <SelectOrUploadGeofenceConfigAccordion
      setDisableNextButton={(value) => props.setDisableNextButton(value)}
      setOptionType={(option) => props.setOptionType(option)}
      optionType={props.optionType}
      uploadTriggered={props.uploadTriggered}
      setUploadTriggered={(value) => props.setUploadTriggered(value)}
      setActiveModal={(index) => props.setActiveModal(index)}
      activeModal={props.activeModal}
      uploadTriggeredEach={props.uploadTriggeredEach}
      activeIndex={props.geofenceActiveIndex}
      setActiveIndex={(index) => props.setGeofenceActiveIndex(index)}
      selectedGeofenceVersion={props.selectedGeofenceVersion}
      setSelectedGeofenceVersion={(version) =>
        props.setSelectedGeofenceVersion(version)
      }
      selectedCustomGeofenceVersion={props.selectedCustomGeofenceVersion}
      setSelectedCustomGeofenceVersion={(version) =>
        props.setSelectedCustomGeofenceVersion(version)
      }
      configGeofenceOptions={props.configGeofenceOptions}
      allConfigOptions={props.allConfigOptions}
      fillDropdownOptions={() => props.fillDropdownOptions()}
      path={props.path}
      setPath={(path) => props.setPath(path)}
      isPolygonComplete={props.isPolygonComplete}
      setIsPolygonComplete={(value) => props.setIsPolygonComplete(value)}
      alertThreshold={props.alertThreshold}
      setAlertThreshold={(threshold) => props.setAlertThreshold(threshold)}
      theme={props.theme}
      latitude={props.latitude}
      setLatitude={(latitude) => props.setLatitude(latitude)}
      longitude={props.longitude}
      setLongitude={(longitude) => props.setLongitude(longitude)}
    />
  );
}
