import React, { useState, memo } from "react";
import {
  Grid,
  Checkbox,
  Progress,
  AccordionTitle,
  AccordionContent,
  GridColumn,
  Icon,
  Popup,
  SemanticWIDTHS,
} from "semantic-ui-react";
import { capitalizeFirstLetter } from "../../util";
import { ActionType, Permission, TenantSettings } from "../../../../util";
import moment from "moment";
import {
  Device,
  FetchStreamsAPIResponse,
  getTenantFromURL,
  updateTenantSettings,
} from "../../../../BytebeamClient";
import styled from "styled-components";
import TextWithToolTip from "./TextWithToolTip";
import { ThinDivider } from "../../Dashboards/Panel/util";
import { ActionButton } from "./ActionButtons";
import { ActivateDeviceConfirmationModal } from "./ActionModals/ActivateDeviceConfirmationModal";
import { ShowDeviceStreamsModal } from "./ActionModals/ShowDeviceStreamsModal";
import { ShowDeviceDashboardModal } from "./ActionModals/ShowDeviceDashboardModal";
import _ from "lodash";
import { ShowConfigVersionModal } from "./ActionModals/ShowConfigVersionModal";
import { useUser } from "../../../../context/User.context";
import { styleActionProgress } from "./LastActionInfoTable";
import DeviceOverview from "./DeviceOverview";
import { checkLogs } from "../../Actions/util";
import { beamtoast } from "../../../common/CustomToast";
import SlicedTextPopUp from "./SlicedTextPopUp";

export const StyledCard = styled.div`
  margin-bottom: 6px !important;
  background-color: ${({ theme }) =>
    theme.colors["container-background"]} !important;
  padding: 20px !important;
  width: 100% !important;
  color: ${({ theme }) => theme.colors["foreground-color"]};
  border: ${({ theme }) => theme.colors["container-border"]};
  border-radius: 12px !important;
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.colors["container-box_shadow"]};
  &:hover {
    box-shadow: ${({ theme }) => theme.colors["container-box_shadow-hover"]};
  }
`;

export const LightMutedText = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: capitalize;
  white-space: nowrap;
  text-align: left;
  height: 18px;
  width: 100%;
`;

export const StyledGridColumn = styled(GridColumn)`
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors["foreground-color"]};
`;

type DeviceCardProp = {
  readonly device: Device;
  readonly allowedActions: "all" | string[];
  readonly allSelected: boolean;
  readonly selectedDevices: Map<string | number, string>;
  readonly selectDevice: (id: number, status: string) => any;
  readonly clearDevice: (id: number) => any;
  readonly stateKeys: string[];
  readonly metadataKeys: string[];
  readonly editableMetadataKeys: Set<string>;
  readonly downloadCertificates: (id: number) => any;
  readonly changeDeviceStatus: (id: number, status: string) => any;
  readonly setRemoteShellDeviceId: (id: number) => any;
  readonly permissions: Permission;
  readonly activeIndex: number;
  readonly setActiveIndex: (value: number) => any;
  readonly dashboards: any[];
  readonly streamsList: Record<string, string[]>;
  readonly tenant_settings: TenantSettings;
  readonly getColumnWidth: (arg: string) => SemanticWIDTHS;
  readonly setDevicePageToLoadingState: () => void;
  readonly detailedStreamsList: FetchStreamsAPIResponse;
  readonly actionTypes: ActionType[];
};

function DeviceCard(props: DeviceCardProp) {
  let {
    device,
    allowedActions,
    allSelected,
    selectedDevices,
    selectDevice,
    clearDevice,
    stateKeys,
    metadataKeys,
    editableMetadataKeys,
    downloadCertificates,
    changeDeviceStatus,
    setRemoteShellDeviceId,
    permissions,
    activeIndex,
    setActiveIndex,
    tenant_settings,
    getColumnWidth,
    setDevicePageToLoadingState,
    detailedStreamsList,
    actionTypes,
  } = props;
  const { user, getCurrentUser } = useUser();
  const theme = user?.settings?.theme ?? "dark";

  const [openActivateConfirmationModal, setOpenActivateConfirmationModal] =
    useState<boolean>(false);
  const [openShowStreamsModal, setOpenShowStreamsModal] =
    useState<boolean>(false);
  const [openShowConfigVersionModal, setOpenShowConfigVersionModal] =
    useState<boolean>(false);
  const [openShowDeviceDashboardModal, setOpenShowDeviceDashboardModal] =
    useState<boolean>(false);

  const lastAction = device["action"];
  const deviceStatus = device["status"];
  const deviceId = device["id"];
  const serialMetadataKey = user?.["tenant-settings"]?.["serial-key"];
  const serialMetadataValue = serialMetadataKey
    ? device?.["-serial_metadata"]?.[serialMetadataKey]
    : "--";
  const common_settings = tenant_settings?.common_settings ?? {};
  const pinnedMetadataKeys: string[] = common_settings?.pin_metadata ?? [];
  const metadataKeysPinningLimit = 3;

  const StyledDeviceOperationsButton = {
    background: theme === "light" ? "#ffffff" : "transparent",
    color: theme === "light" ? "#5C5C5C;" : "#C1C1C1",
    border: `1px solid ${theme === "light" ? "#828282" : "#C1C1C1"}`,
    marginRight: "12px",
    padding: "6px 12px",
    borderRadius: "4px",
  };

  let lastActionType: string = capitalizeFirstLetter(lastAction?.type || "--"),
    lastActionStatus: string = lastAction?.status || "--",
    lastActionProgress: number = styleActionProgress(
      lastActionStatus,
      lastAction?.progress === undefined ? 0 : lastAction?.progress
    )["progress"],
    lastActionClassName: string = styleActionProgress(
      lastActionStatus,
      lastAction?.progress === undefined ? 0 : lastAction?.progress
    )["className"];

  stateKeys = _.uniq(stateKeys);

  // if config_version is in state-keys then enable a flag to show config version
  const showConfigVersion = stateKeys.includes("config_version");

  function handleCollapse(index: number, activeIndex: number) {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  }

  enum PinMetadataActions {
    PIN = "pinned",
    UNPIN = "unpinned",
    NO_ACTION = "no_action",
  }

  async function pinMetadata(key: string): Promise<void> {
    const metadataKeys: Set<string> = new Set(pinnedMetadataKeys);
    let actionPerformed = PinMetadataActions.NO_ACTION;

    if (
      !metadataKeys.has(key) &&
      pinnedMetadataKeys.length >= metadataKeysPinningLimit
    ) {
      // Metadata cannot be pinned more than metadataKeysPinningLimit
      actionPerformed = PinMetadataActions.NO_ACTION;
    } else if (
      !metadataKeys.has(key) &&
      metadataKeys.size <= metadataKeysPinningLimit
    ) {
      // if key is not already pinned then pin it
      actionPerformed = PinMetadataActions.PIN;
      metadataKeys.add(key);
    } else if (metadataKeys.has(key)) {
      // if key is already pinned then unpin it
      actionPerformed = PinMetadataActions.UNPIN;
      metadataKeys.delete(key);
    }

    try {
      if (actionPerformed !== PinMetadataActions.NO_ACTION) {
        const res = await updateTenantSettings({
          settings: {
            ...tenant_settings,
            common_settings: {
              ...common_settings,
              pin_metadata: [...metadataKeys],
            },
          },
        });

        if (res !== undefined) {
          // Updating User State to reflect changes
          await getCurrentUser();
          beamtoast.success(`Successfully ${actionPerformed} ${key} Metadata`);
        }
      } else {
        beamtoast.info("Cannot pin more than 3 Metadata");
      }
    } catch (err) {
      console.error(`Error while pinning metadata: ${err}`);
      beamtoast.error(`Error while pinning/unpinning metadata: ${err}`);
    } finally {
      // Loading device page to reflect changes if any action is performed
      if (actionPerformed !== PinMetadataActions.NO_ACTION)
        setDevicePageToLoadingState();
    }
  }

  return (
    <StyledCard>
      <ActivateDeviceConfirmationModal
        deviceId={deviceId}
        deviceStatus={deviceStatus}
        changeDeviceStatus={changeDeviceStatus}
        isOpen={openActivateConfirmationModal}
        close={() => setOpenActivateConfirmationModal(false)}
      />
      <ShowDeviceStreamsModal
        deviceId={deviceId}
        isOpen={openShowStreamsModal}
        close={() => setOpenShowStreamsModal(false)}
        theme={theme}
        streams={props.streamsList}
        detailedStreamsList={props.detailedStreamsList}
      />
      <ShowConfigVersionModal
        configVersion={device["state"]["config_version"]}
        isOpen={openShowConfigVersionModal}
        close={() => setOpenShowConfigVersionModal(false)}
        theme={theme}
      />
      <ShowDeviceDashboardModal
        deviceId={deviceId}
        isOpen={openShowDeviceDashboardModal}
        dashboards={props.dashboards}
        close={() => setOpenShowDeviceDashboardModal(false)}
      />

      <AccordionTitle
        active={deviceId === activeIndex}
        index={deviceId}
        onClick={() => handleCollapse(deviceId, activeIndex)}
      >
        <Grid>
          <Grid.Row>
            {allowedActions.length > 0 ? (
              <StyledGridColumn style={{ justifyContent: "flex-start" }}>
                <Checkbox
                  width={getColumnWidth("action_checkbox")}
                  disabled={allSelected}
                  checked={allSelected || selectedDevices.has(deviceId)}
                  onChange={(e, { checked }) => {
                    e.stopPropagation();
                    if (checked) {
                      selectDevice(deviceId, deviceStatus);
                    } else {
                      clearDevice(deviceId);
                    }
                  }}
                />
              </StyledGridColumn>
            ) : null}

            <StyledGridColumn
              width={
                serialMetadataKey
                  ? getColumnWidth("-serial_metadata")
                  : getColumnWidth("id")
              }
            >
              {serialMetadataKey && serialMetadataValue ? (
                // SlicedTextPopUp component is used to display the serial metadata value, with showAlways prop set to true.
                <SlicedTextPopUp
                  noWrap
                  showAlways
                  length={22}
                  text={serialMetadataValue}
                  textList={[`Device Id: ${deviceId.toString()}`]}
                />
              ) : (
                deviceId
              )}
            </StyledGridColumn>

            {Object.keys(permissions?.tables).length > 0 && (
              <StyledGridColumn width={getColumnWidth("last_heartbeat")}>
                <Popup
                  content={`${new Date(
                    new Date(device?.state["timestamp"]).getTime() -
                      new Date().getTimezoneOffset() * 60000
                  ).toDateString()}
                    ${new Date(
                      new Date(device?.state["timestamp"]).getTime() -
                        new Date().getTimezoneOffset() * 60000
                    ).toLocaleTimeString()}`}
                  position="top center"
                  inverted
                  trigger={
                    <div>
                      {device?.state["timestamp"]
                        ? capitalizeFirstLetter(
                            moment
                              .duration(
                                -1 *
                                  moment().diff(
                                    moment.utc(device?.state["timestamp"])
                                  )
                              )
                              .humanize(true)
                          )
                        : "--"}
                    </div>
                  }
                />
              </StyledGridColumn>
            )}

            <StyledGridColumn width={getColumnWidth("status")}>
              {capitalizeFirstLetter(
                device?.state["Status"] || device?.state["status"]
              ) || "--"}
            </StyledGridColumn>

            {pinnedMetadataKeys.length !== 0 &&
              pinnedMetadataKeys.map((key) => (
                <StyledGridColumn
                  width={getColumnWidth("pinned_metadata")}
                  key={key}
                >
                  <TextWithToolTip text={device?.metadata[key]} />
                </StyledGridColumn>
              ))}

            <StyledGridColumn width={getColumnWidth("action_type")}>
              <TextWithToolTip text={capitalizeFirstLetter(lastActionType)} />
            </StyledGridColumn>

            <StyledGridColumn width={getColumnWidth("last_action")}>
              {lastActionType !== "--" ? (
                <Popup
                  content={lastActionStatus}
                  position="top center"
                  inverted
                  trigger={
                    <Progress
                      progress
                      indicating={
                        !(
                          lastActionStatus === "Completed" ||
                          lastActionStatus === "Failed"
                        )
                      }
                      percent={lastActionProgress}
                      className={lastActionClassName}
                      style={{ width: "150px", marginBottom: "0px" }}
                    />
                  }
                />
              ) : (
                "No Last Action"
              )}
            </StyledGridColumn>

            <StyledGridColumn width={1} style={{ justifyContent: "flex-end" }}>
              <Icon
                style={{ fontSize: "24px" }}
                name={deviceId === activeIndex ? "angle down" : "angle right"}
              />
            </StyledGridColumn>
          </Grid.Row>
        </Grid>
      </AccordionTitle>
      <AccordionContent active={deviceId === activeIndex}>
        <Grid columns={3} divided>
          <ThinDivider style={{ margin: "20px 0px 8px 0px" }} />

          <Grid.Row
            style={{
              paddingBottom: "0px",
            }}
          >
            <StyledGridColumn
              width={16}
              style={{ justifyContent: "flex-start", fontWeight: "700" }}
            >
              Device Overview
            </StyledGridColumn>
          </Grid.Row>

          <DeviceOverview
            stateKeys={stateKeys}
            device={device}
            deviceId={deviceId}
            lastAction={lastAction}
            metadataKeys={metadataKeys}
            enablePinMetadata={true}
            pinMetadata={pinMetadata}
            pinnedMetadataKeys={pinnedMetadataKeys}
            editableMetadataKeys={editableMetadataKeys}
            detailedStreamsList={detailedStreamsList}
            activeAccordionIndex={activeIndex}
          />

          <ThinDivider style={{ margin: "16px 0px 8px 0px" }} />

          <Grid.Row
            style={{
              paddingBottom: "0px",
            }}
          >
            <StyledGridColumn
              width={16}
              style={{ fontWeight: "700", justifyContent: "flex-start" }}
            >
              Operations
            </StyledGridColumn>
          </Grid.Row>

          <Grid.Row>
            <StyledGridColumn
              style={{ justifyContent: "flex-start" }}
              width={16}
            >
              {permissions.allowCreatingDevices && (
                <ActionButton
                  onClick={() => {
                    if (deviceStatus === "active")
                      downloadCertificates(deviceId);
                    else {
                      beamtoast.error(
                        "Cannot download the configuration for a deactivated device."
                      );
                    }
                  }}
                  style={StyledDeviceOperationsButton}
                >
                  <Icon name="download" /> <span>Download Config</span>
                </ActionButton>
              )}

              {allowedActions?.includes("launch_shell") && (
                <ActionButton
                  onClick={() => setRemoteShellDeviceId(deviceId)}
                  style={StyledDeviceOperationsButton}
                >
                  <Icon name="terminal" /> <span>Remote Shell</span>
                </ActionButton>
              )}
              {/* Show only if config_version is in device_shadow */}
              {showConfigVersion && (
                <ActionButton
                  onClick={() => setOpenShowConfigVersionModal(true)}
                  style={StyledDeviceOperationsButton}
                >
                  <Icon name="configure" /> <span>Config Version</span>
                </ActionButton>
              )}
              {Object.keys(permissions?.tables).length > 0 && (
                <ActionButton
                  onClick={() => setOpenShowStreamsModal(true)}
                  style={StyledDeviceOperationsButton}
                >
                  <Icon name="table" /> <span>Streams</span>
                </ActionButton>
              )}
              {permissions.showDashboardsTab && (
                <ActionButton
                  onClick={() => setOpenShowDeviceDashboardModal(true)}
                  style={StyledDeviceOperationsButton}
                >
                  <Icon name="dashboard" /> <span>Device Dashboards</span>
                </ActionButton>
              )}
              {Object.entries(props.streamsList).filter(([stream, fields]) =>
                checkLogs(stream)
              ).length > 0 && (
                <ActionButton
                  onClick={() =>
                    window.open(
                      `/projects/${getTenantFromURL()}/actions/logs/device-log?device_id=${deviceId}`,
                      "_blank"
                    )
                  }
                  style={StyledDeviceOperationsButton}
                >
                  <Icon name="file alternate outline" /> <span>Logs</span>
                </ActionButton>
              )}
              {permissions.showActionsTab &&
                lastAction?.action_id &&
                // Check if any of the allowed actions permission is given, checking from actionTypes because allowedActions contains deactivated actions as well
                actionTypes.filter((actionType) =>
                  allowedActions === "all"
                    ? true
                    : allowedActions.includes(actionType.type)
                ).length > 0 && (
                  <ActionButton
                    onClick={() =>
                      window.open(
                        `/projects/${getTenantFromURL()}/actions/live-actions?action_id=${
                          lastAction?.action_id
                        }&device_id=${deviceId}`,
                        "_blank"
                      )
                    }
                    style={StyledDeviceOperationsButton}
                  >
                    <Icon name="history" /> <span>History</span>
                  </ActionButton>
                )}
              <ActionButton
                onClick={() => setOpenActivateConfirmationModal(true)}
                style={StyledDeviceOperationsButton}
              >
                <Icon
                  name={
                    deviceStatus === "active" ? "ban" : "check circle outline"
                  }
                />{" "}
                <span>
                  {deviceStatus === "active" ? "Deactivate" : "Activate"}
                </span>
              </ActionButton>
            </StyledGridColumn>
          </Grid.Row>
        </Grid>
      </AccordionContent>
    </StyledCard>
  );
}

export default memo(DeviceCard);
