import React, { Component } from "react";
import { Accordion, Dropdown, Icon } from "semantic-ui-react";
import { OptionType } from "../util";
import { DropdownOptionType } from "../../DeviceManagement/Devices/ActionModals/UpdateConfigModal";
import { UploadConfigComponent } from "./UploadConfigComponent";
import { beamtoast } from "../../../common/CustomToast";

interface SelectOrUploadConfigAccordionProps {
  setDisableNextButton: (arg0: boolean) => void;
  setOptionType: (arg0: OptionType) => void;
  optionType: OptionType;
  uploadTriggered: string;
  setUploadTriggered: (arg0: string) => void;
  uploadTriggeredEach: boolean;
  setActiveModal: (arg0: number) => void;
  activeModal: number;
  customInputJSONData: string;
  setCustomInputJSONData: (arg0: string) => void;
  activeIndex: number;
  setActiveIndex: (arg0: number) => void;
  selectedConfigVersion: DropdownOptionType;
  setSelectedConfigVersion: (arg0: DropdownOptionType) => void;
  selectedCustomConfigVersion: string;
  setSelectedCustomConfigVersion: (arg0: string) => void;
  configJSONOptions: DropdownOptionType[];
  allConfigOptions: Set<any> | undefined;
  fillDropdownOptions: () => void;
  theme: string;
}

export default class SelectOrUploadConfigAccordion extends Component<SelectOrUploadConfigAccordionProps> {
  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = this.props.activeIndex === index ? -1 : index;

    this.props.setActiveIndex(newIndex);

    this.props.fillDropdownOptions();

    if (newIndex === 0) this.props.setOptionType(OptionType.ChooseConfig);

    if (newIndex === 1) this.props.setOptionType(OptionType.UploadConfig);

    if (newIndex === -1) this.props.setDisableNextButton(true);
    else this.props.setDisableNextButton(false);
  };

  onSelect = async (e, data) => {
    this.props.setSelectedConfigVersion(data);
    this.props.setOptionType(OptionType.ChooseConfig);
  };

  returnConfigValue = () => {
    let selectedDropdownOption: string = "";
    this.props.configJSONOptions.forEach((config: DropdownOptionType) => {
      if (config.value === this.props.selectedConfigVersion.value) {
        selectedDropdownOption = config.text;
      }
    });
    return selectedDropdownOption;
  };

  componentDidUpdate(
    prevProps: Readonly<SelectOrUploadConfigAccordionProps>
  ): void {
    this.props.setUploadTriggered("");
    if (this.props.activeIndex === 0)
      this.props.setOptionType(OptionType.ChooseConfig);

    if (this.props.activeIndex === 1)
      this.props.setOptionType(OptionType.UploadConfig);

    if (this.props.activeIndex === -1) this.props.setDisableNextButton(true);
    else this.props.setDisableNextButton(false);
    if (prevProps.uploadTriggeredEach !== this.props.uploadTriggeredEach) {
      if (this.props.uploadTriggered === "trigger") {
        switch (this.props.optionType) {
          case OptionType.ChooseConfig:
            if (
              this.props.selectedConfigVersion &&
              this.props.selectedConfigVersion.value !== ""
            ) {
              this.props.setSelectedConfigVersion(
                this.props.selectedConfigVersion
              );
              this.props.setActiveModal(this.props.activeModal + 1);
            } else beamtoast.error("Please select a config version to trigger");
        }
      }
    }
  }

  render() {
    return (
      <Accordion fluid>
        <Accordion.Title
          active={this.props.activeIndex === 0}
          index={0}
          onClick={this.handleClick}
        >
          <p
            style={{
              fontSize: "1.2rem",
              margin: 0,
            }}
          >
            <Icon name="dropdown" />
            Select Device Config Version
          </p>
        </Accordion.Title>
        <Accordion.Content active={this.props.activeIndex === 0}>
          <div style={{ padding: "1rem 8rem" }}>
            <Dropdown
              placeholder="Select Version"
              fluid
              selection
              defaultValue={this.returnConfigValue()}
              options={this.props.configJSONOptions}
              onChange={this.onSelect}
            />
          </div>
        </Accordion.Content>
        <Accordion.Title
          active={this.props.activeIndex === 1}
          index={1}
          onClick={this.handleClick}
        >
          <p
            style={{
              fontSize: "1.2rem",
              margin: 0,
            }}
          >
            <Icon name="dropdown" />
            Upload new Config Version
          </p>
        </Accordion.Title>
        <Accordion.Content active={this.props.activeIndex === 1}>
          <UploadConfigComponent
            setOptionType={(option) => this.props.setOptionType(option)}
            optionType={this.props.optionType}
            uploadTriggered={this.props.uploadTriggered}
            uploadTriggeredEach={this.props.uploadTriggeredEach}
            setActiveModal={(index) => this.props.setActiveModal(index)}
            activeModal={this.props.activeModal}
            customInputJSONData={this.props.customInputJSONData}
            setCustomInputJSONData={(data) =>
              this.props.setCustomInputJSONData(data)
            }
            configOptions={this.props.configJSONOptions}
            allConfigOptions={this.props.allConfigOptions}
            selectedCustomConfigVersion={this.props.selectedCustomConfigVersion}
            setSelectedCustomConfigVersion={(version) =>
              this.props.setSelectedCustomConfigVersion(version)
            }
            theme={this.props.theme}
          />
        </Accordion.Content>
      </Accordion>
    );
  }
}
