import React, { Component } from "react";
import { Button, Modal } from "semantic-ui-react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-chaos";
import ThemeSchema from "../../../../theme/schema";

type ViewActionPayloadModalProps = {
  isOpen: boolean;
  close: Function;
  action: any;
  theme: string;
};

interface ViewActionPayloadModalState {
  payload: any;
}

class ViewActionPayloadModal extends Component<
  ViewActionPayloadModalProps,
  ViewActionPayloadModalState
> {
  constructor(props) {
    super(props);
    this.state = {
      payload: "",
    };
  }

  componentDidMount(): void {
    this.setState({
      payload: this.props.action.params
        ? JSON.parse(this.props.action.params)
        : "",
    });
  }

  componentDidUpdate(prevProps: Readonly<ViewActionPayloadModalProps>): void {
    if (prevProps.action !== this.props.action) {
      this.setState({
        payload: this.props.action.params
          ? JSON.parse(this.props.action.params)
          : "",
      });
    }
  }

  render() {
    const { theme } = this.props;

    return (
      <Modal
        centered={true}
        open={this.props.isOpen}
        onClose={() => this.props.close}
        size="small"
        className="dark"
      >
        <Modal.Header>Action Payload</Modal.Header>
        <Modal.Content>
          <AceEditor
            height="200px"
            width="100%"
            mode="json"
            theme={
              ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-theme"]
            }
            name="custom-json"
            fontSize={14}
            value={JSON.stringify(this.state.payload, null, 2)}
            showPrintMargin={false}
            showGutter={true}
            highlightActiveLine={true}
            setOptions={{
              readOnly: true,
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: false,
              tabSize: 2,
            }}
            style={{
              marginBottom: "16px",
              borderRadius: "4px",
              border: `${ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-border"]}`,
              boxShadow: `${ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-box-shadow"]}`,
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => this.props.close()}>
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ViewActionPayloadModal;
