import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import styled from "styled-components";
import GetStartedCard from "./GetStartedCard";
import GetStartedConfig from "./GetStartedConfig";
import { useUser } from "../../../context/User.context";
import { UserSettings } from "../../../util";
import { updateUserSettings } from "../../../BytebeamClient";
import { breakpoints } from "../../common/breakpoints";

const deviceOptions: Array<{ key: number; value: string; text: string }> = [
  { key: 1, text: "ESP32 (ESP-IDF)", value: "ESP32 (ESP-IDF)" },
  { key: 2, text: "ESP32 (Rust ESP-IDF)", value: "ESP32 (Rust ESP-IDF)" },
  { key: 3, text: "Arduino", value: "Arduino" },
  { key: 4, text: "Linux", value: "Linux" },
];

const GetStartedPageContainer = styled.div`
  @media (max-width: ${breakpoints.sm}px) {
    padding: 0px 4px 0px 4px;
  }
`;

const DeviceDropdown = styled(Dropdown)`
  min-width: 10em !important;
  min-height: fit-content !important;
  padding: 12px !important;

  input {
    min-height: fit-content !important;
    padding: 12px !important;
  }

  .text {
    margin-right: 52px;
  }

  i {
    padding: 12px !important;
  }

  @media (max-width: ${breakpoints.xs}px) {
    font-size: 22px !important;
    width: 100%;
  }
`;

const GetStartedSectionOne = styled.div`
  display: flex;
  padding: 20px 0px 24px 0px;
  justify-content: space-between;
  gap: 30px;

  h1 {
    font-size: 28px;
  }

  .pageDescription {
    font-size: 20px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column-reverse;
    gap: 24px;

    h1 {
      font-size: 24px;
    }

    .pageDescription {
      font-size: 16px;
    }
  }
`;

const DropDownSection = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  font-size: 24px;
  gap: 12px;

  .DropDownMenuTitle {
    margin: 0px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    justify-content: flex-start;
  }
`;

const GetStartedCardsSection = styled.div`
  display: grid;
  margin-top: 20px;
  margin-bottom: 40px;
  grid-template-columns: repeat(3, 1fr);
  gap: 36px;

  @media (max-width: ${breakpoints.sm}px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 22px;
  }

  @media (max-width: 580px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 18px;
  }
`;

const StyledSkipTextButton = styled.b`
  color: #407bff;
  border-bottom: 1px dashed #1158e1;
  padding-bottom: 2px;
  cursor: pointer;
  margin: 20px 120px 40px 0px;
`;

const GetStartedScreen = ({ currentTenant }) => {
  // For updating the user object
  const { user, getCurrentUser } = useUser();
  const userGetStartedData = user?.settings?.get_started;

  const [selectedDevice, setSelectedDevice] = useState(deviceOptions[0].value);
  const [clickedCards, setClickedCards] = useState({
    selectedDevice: selectedDevice,
    deviceType: {},
    skip: false,
  });

  async function updateGetStartedCardsSettings(clickedCardsData) {
    const userSettings = user?.settings ?? ({} as UserSettings);
    try {
      const res = await updateUserSettings({
        settings: {
          ...userSettings,
          get_started: clickedCardsData,
        },
      });

      if (res !== undefined && res !== null) {
        await getCurrentUser();
      }
    } catch (error) {
      console.log("Error while updating user settings", error);
    }
  }

  const updateSelectedDevice = async (currentlySelectedDevice: string) => {
    const selectedDeviceClickedCardsData = {
      ...clickedCards,
      selectedDevice: currentlySelectedDevice,
    };

    setClickedCards(selectedDeviceClickedCardsData);
    await updateGetStartedCardsSettings(selectedDeviceClickedCardsData);
  };

  const updateSkipCards = async () => {
    const skipClickedCardsData = {
      ...clickedCards,
      skip: true,
    };

    setClickedCards(skipClickedCardsData);
    await updateGetStartedCardsSettings(skipClickedCardsData);
    window.location.href = `/projects/${currentTenant}/`; // For now reloading the whole page.
  };

  useEffect(() => {
    const deviceValueFromUser =
      userGetStartedData?.selectedDevice?.toString() || "";
    const validDevice = deviceOptions.some(
      (option) => option.value === deviceValueFromUser
    );

    setSelectedDevice(
      validDevice ? deviceValueFromUser : deviceOptions[0].value
    );
    setClickedCards((prevClickedCards) => ({
      ...prevClickedCards,
      selectedDevice: validDevice
        ? deviceValueFromUser
        : deviceOptions[0].value,
      skip: userGetStartedData?.skip ?? false,
      deviceType: {
        ...prevClickedCards.deviceType,
        ...(userGetStartedData?.deviceType ?? {}),
      },
    }));
  }, [userGetStartedData]);

  useEffect(() => {
    document.title = "Get Started | Bytebeam";
  }, []);

  const getStartedCardsConfig = GetStartedConfig.deviceTypes[selectedDevice];
  getStartedCardsConfig.sort(
    (cardA, cardB) => cardA.cardSequence - cardB.cardSequence
  );

  return (
    <GetStartedPageContainer>
      <GetStartedSectionOne>
        <div>
          <h1>Welcome to {selectedDevice} Starter</h1>
          <p className="pageDescription">
            In this page you can explore multiple modules as per your
            requirements.
          </p>
        </div>
        <DropDownSection>
          <p className="DropDownMenuTitle">Device</p>
          <DeviceDropdown
            selection
            search
            placeholder="Device"
            value={selectedDevice}
            options={deviceOptions}
            onChange={(_e, data) => {
              setSelectedDevice(String(data?.value));
              updateSelectedDevice(String(data?.value));
            }}
          />
        </DropDownSection>
      </GetStartedSectionOne>
      <GetStartedCardsSection>
        {getStartedCardsConfig.map((cardsConfig) => (
          <GetStartedCard
            key={cardsConfig.key}
            clickedCards={clickedCards.deviceType[selectedDevice]}
            cardKey={cardsConfig.key}
            heading={cardsConfig.heading}
            description={cardsConfig.description}
            onClickExplore={() => {
              const clickedCardsData = {
                ...clickedCards,
                deviceType: {
                  ...clickedCards.deviceType,
                  [selectedDevice]: {
                    ...(clickedCards?.deviceType[selectedDevice] ?? {}),
                    [`${cardsConfig.key}`]: true,
                  },
                },
              };

              setClickedCards(clickedCardsData);
              window.open(`${cardsConfig.url}`, "_blank");
              updateGetStartedCardsSettings(clickedCardsData);
            }}
          />
        ))}
      </GetStartedCardsSection>
      {userGetStartedData?.skip ? null : (
        <StyledSkipTextButton onClick={() => updateSkipCards()}>
          Skip
        </StyledSkipTextButton>
      )}
    </GetStartedPageContainer>
  );
};

export default GetStartedScreen;
