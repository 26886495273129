import React, { useEffect, useState } from "react";
import { Message, MessageContent, MessageHeader } from "semantic-ui-react";
import styled from "styled-components";
import { capitalizeFirstLetter } from "../../Screens/util";
import SelectProjectModal from "../../Screens/SelectProjectModal";
import { useUser } from "../../../context/User.context";
import { breakpoints } from "../breakpoints";

export const StyledSelectTenantButton = styled.span`
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start;
  width: fit-content;
  color: ${(props) => props.theme.colors["link-text-color"]} !important;
  border-bottom: 1px solid ${(props) => props.theme.colors["link-text-color"]};
  cursor: pointer;
`;

export const StyledMessage = styled(Message)`
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  padding: 12px !important;
  margin: 0px !important;
  width: 100% !important;
`;

export const StyledMessageHeader = styled(MessageHeader)`
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  font-size: 17px !important;
`;

export const StyledMessageContent = styled(MessageContent)`
  display: flex !important;
  flex-direction: column;
  gap: 8px;
  margin-top: 6px;
`;

type UserSummaryWithProjectPickerProps = {
  currentTenant: string;
  setCurrentTenant: (tenant: string) => void;
  isMultiTenant: boolean;
  setShowNewProjectModal: (show: boolean) => void;
};

function UserSummaryWithProjectPicker(
  props: UserSummaryWithProjectPickerProps
) {
  const {
    currentTenant,
    setCurrentTenant,
    isMultiTenant,
    setShowNewProjectModal,
  } = props;

  const [showSelectProjectModal, setShowSelectProjectModal] =
    useState<boolean>(false);
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  const { user } = useUser();
  const tenants = user?.tenants?.filter((tenant) => tenant !== "demo") || [];

  useEffect(() => {
    const updateDimensions = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <>
      <SelectProjectModal
        tenants={tenants}
        isOpen={showSelectProjectModal}
        onClose={() => setShowSelectProjectModal(false)}
        setCurrentTenant={setCurrentTenant}
        user={user}
        setShowNewProjectModal={setShowNewProjectModal}
        currentTenant={currentTenant}
        disableCancelButton={false}
      />
      <StyledMessage>
        <StyledMessageHeader>
          {screenWidth > breakpoints.sm
            ? user?.email
            : `${capitalizeFirstLetter(user?.name)} (${user?.email})`}
        </StyledMessageHeader>
        {currentTenant && (
          <StyledMessageContent>
            {`${capitalizeFirstLetter(user?.role?.name)} | ${currentTenant}`}
            {isMultiTenant && (
              <StyledSelectTenantButton
                id="select_project"
                onClick={() => setShowSelectProjectModal(true)}
              >
                Select another project
              </StyledSelectTenantButton>
            )}
          </StyledMessageContent>
        )}
      </StyledMessage>
    </>
  );
}

export default UserSummaryWithProjectPicker;
