import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Checkbox, Input } from "semantic-ui-react";
import { Filters } from "../../../../BytebeamClient";
import { useOutsideClickHandler } from "../../../../hooks/useOutsideClickHandler";
import { Virtuoso } from "react-virtuoso";
import { ButtonIcon, capitalizeFirstLetter } from "../../util";
import { ButtonPosition, NestedDropdownOptions } from "../util";
import LoadingAnimation from "../../../common/Loader";

interface ActionFilterNestedDropdownProps {
  setShowDropdown: (arg0: boolean) => void;
  depth: number;
  onClick: (arg0: any, arg1: any) => void;
  nestedDropdownOptions: NestedDropdownOptions[];
  filters: Filters;
  currentDepth: number;
  setCurrentDepth: (arg0: number) => void;
  selectedFilterKey?: React.MutableRefObject<string>;
  parentButtonPosition?: ButtonPosition;
  infiniteLoading?: boolean;
  setInfiniteLoading?: (arg0: boolean) => void;
  loadMore?: () => void;
  search?: boolean;
}

const DropdownHeaderDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 37px;
  position: sticky !important;
  top: 0px !important;
  z-index: 1 !important;
  margin-bottom: 0.3rem;
  border-bottom: 1px solid
    ${(props) => props.theme.colors["action-border-color"]} !important;
`;

const BackButton = styled(ButtonIcon)`
  position: relative;
  bottom: 2px;
  margin-right: 0 !important;
  margin-left: 5px !important;
`;

const DropdownSearchDiv = styled(Input)`
  width: 100%;
  border: none !important;
  background-color: ${(props) =>
    props.theme.colors["action-dropdown-color"]} !important;
  input {
    border: none !important;
    background-color: ${(props) =>
      props.theme.colors["action-dropdown-color"]} !important;
  }
`;

const DropdownWrapper = styled.div<{
  marginTopExist: boolean;
  buttonPosition?: ButtonPosition;
}>`
  z-index: 10;
  position: ${(props) => (props.buttonPosition ? "fixed" : "absolute")};
  background-color: ${(props) => props.theme.colors["action-dropdown-color"]};
  border: 1px solid ${(props) => props.theme.colors["action-border-color"]};
  border-radius: 8px;
  max-height: 300px;
  min-width: 270px;
  overflow: hidden;
  top: ${(props) =>
    props.buttonPosition
      ? `calc(${props.buttonPosition.top}px + ${props.buttonPosition.height}px + 0.5rem)`
      : "0.3rem"};
  left: ${(props) =>
    props.buttonPosition ? `${props.buttonPosition.left}px` : "0"};
  transition: all 0.15s ease-in;
  animation: fade-in 0.15s;
  padding: 0.3rem;
  padding-top: ${(props) => (props.marginTopExist ? "0.3rem" : "0")};

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const DropdownItem = styled.div<{ noHover?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: ${(props) => (props.noHover ? "default" : "pointer")};
  width: 100%;
  white-space: break-spaces;
  font-size: calc(100% - 1px); /* responsive font */
  background-color: transparent;
  padding: 0.5rem 0.5rem;
  margin-right: 0.5rem;
  border-radius: 8px;
  transition: background-color 0.15s ease-in;
  &:hover {
    background-color: ${(props) =>
      props.noHover
        ? "transparent"
        : props.theme.colors["action-border-color-hover"]};
  }
`;

export default function ActionFilterNestedDropdown(
  props: ActionFilterNestedDropdownProps
) {
  const wrapperRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterDropdownOptions, setFilterDropdownOptions] = useState<
    NestedDropdownOptions[]
  >([]);
  const [selectedMetadataKey, setSelectedMetadataKey] = useState<string>("");
  const [secondaryDropdownOptions, setSecondaryDropdownOptions] = useState<
    any[]
  >([]);
  // this is for when search happens, we alter this secondary array instead of the original data and display this
  const [secondaryDropdownDisplayOptions, setSecondaryDropdownDisplayOptions] =
    useState<any[]>([]);

  // used to close the dropdown if clicked outside it
  useOutsideClickHandler(wrapperRef, () => {
    const dropdownWrapper = document.getElementById("dropdown-wrapper");
    if (dropdownWrapper) dropdownWrapper.style.opacity = "0";
    setTimeout(() => {
      props.setShowDropdown(false);
      props.setCurrentDepth(0);
      if (props.setInfiniteLoading) props.setInfiniteLoading(true);
    }, 150);
  });

  // add the metadata value from filter list
  const selectMetadataValue = useCallback(
    (key: number, fromCheckbox: boolean) => {
      let optionKey: number = key;
      if (fromCheckbox) {
        // get the original key from the unfiltered list
        for (let option in secondaryDropdownOptions) {
          if (
            secondaryDropdownOptions[option].value ===
            secondaryDropdownDisplayOptions[key].value
          ) {
            optionKey = Number(option);
            break;
          }
        }
      }

      let newValues: string[] = props.filters[selectedMetadataKey]
        ? [
            ...props.filters[selectedMetadataKey],
            secondaryDropdownOptions[optionKey].value,
          ]
        : [secondaryDropdownOptions[optionKey].value];

      props.onClick(selectedMetadataKey, newValues);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      props.filters,
      selectedMetadataKey,
      secondaryDropdownOptions,
      secondaryDropdownDisplayOptions,
    ]
  );

  // remove the metadata value from filter list
  const removeMetadataValue = useCallback(
    (key: number, fromCheckbox: boolean) => {
      let optionKey: number = key;
      if (fromCheckbox) {
        // get the original key from the unfiltered list
        for (let option in secondaryDropdownOptions) {
          if (
            secondaryDropdownOptions[option].value ===
            secondaryDropdownDisplayOptions[key].value
          ) {
            optionKey = Number(option);
            break;
          }
        }
      }

      let newValues: string[] = [];
      // Only proceed if the value is defined and not undefined
      if (props.filters[selectedMetadataKey]) {
        newValues = props.filters[selectedMetadataKey].filter(
          (item) => item !== secondaryDropdownOptions[optionKey].value
        );
      } else {
        // Handle the case where the value is undefined
        console.warn("Selected filter values or label is undefined.");
      }

      if (newValues.length === 0) {
        props.setCurrentDepth(0);
        props.setShowDropdown(false);
        if (props.selectedFilterKey) props.selectedFilterKey.current = "";
      }

      props.onClick(selectedMetadataKey, newValues);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      props.filters,
      selectedMetadataKey,
      secondaryDropdownOptions,
      secondaryDropdownDisplayOptions,
      props.currentDepth,
    ]
  );

  // checks whether a metadata value already exists in selected filter values or not
  const metadataValueExists = useCallback(
    (key: number) => {
      let valueExists = false;
      for (let filterKey in props.filters) {
        if (filterKey === selectedMetadataKey) {
          valueExists = props.filters[filterKey].includes(
            secondaryDropdownOptions[key].value
          );
          break;
        }
      }
      return valueExists;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedMetadataKey, secondaryDropdownOptions, props.filters]
  );

  // main filter function which is triggered when a metadata value is selected
  const filterValues = useCallback(
    (index: number) => {
      let optionKey: number = 0;

      // get the original key from the unfiltered list
      for (let option in secondaryDropdownOptions) {
        if (
          secondaryDropdownOptions[option].value ===
          secondaryDropdownDisplayOptions[index].value
        ) {
          optionKey = Number(option);
          break;
        }
      }

      // check if the filter is already applied else remove the filter and vice versa
      if (!metadataValueExists(optionKey)) {
        selectMetadataValue(optionKey, false);
        props.setShowDropdown(false);
        props.setCurrentDepth(0);
      } else {
        removeMetadataValue(optionKey, false);
        props.setShowDropdown(false);
        props.setCurrentDepth(0);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      secondaryDropdownOptions,
      secondaryDropdownDisplayOptions,
      props.currentDepth,
      props.depth,
    ]
  );

  // checks whether a metadata value is already selected or not
  const isMetadataValueSelected = useCallback(
    (dropdownKey: number) => {
      const originalValue = secondaryDropdownDisplayOptions[dropdownKey]?.value;

      if (!originalValue) {
        return false; // If original value is not found, return false
      }

      const optionKey = Object.keys(secondaryDropdownOptions).find(
        (key) => secondaryDropdownOptions[key].value === originalValue
      );

      if (optionKey === undefined) {
        return false; // If optionKey is not found, return false
      }

      const selectedFilter = props.filters[selectedMetadataKey] ?? [];

      return selectedFilter.includes(secondaryDropdownOptions[optionKey].value);
    },
    [
      selectedMetadataKey,
      secondaryDropdownOptions,
      secondaryDropdownDisplayOptions,
      props.filters,
    ]
  );

  // triggered when a metadata key is selected
  const handleFilterKeySelect = (key: number) => {
    setSearchTerm("");
    setSelectedMetadataKey(filterDropdownOptions[key].label);
    if (props.selectedFilterKey)
      props.selectedFilterKey.current = filterDropdownOptions[key].label;
    let optionKey: number = 0;

    // get the original key from the unfiltered list
    for (let option in props.nestedDropdownOptions) {
      if (
        props.nestedDropdownOptions[option].label ===
        filterDropdownOptions[key].label
      ) {
        optionKey = Number(option);
        break;
      }
    }

    props.setCurrentDepth(props.currentDepth + 1);

    if (props.nestedDropdownOptions[optionKey]?.children !== undefined) {
      setSecondaryDropdownOptions(
        props.nestedDropdownOptions[optionKey].children!
      );
      setSecondaryDropdownDisplayOptions(
        props.nestedDropdownOptions[optionKey].children!
      );
    }
  };

  // case for filtering metadata values list (part of below function)
  const filterMetadataValueList = useCallback(
    (searchText: string) => {
      let filteredDropdownOptions = secondaryDropdownOptions.filter((option) =>
        option.value.toString().toLowerCase().includes(searchText)
      );
      setSecondaryDropdownDisplayOptions(filteredDropdownOptions);
    },
    [secondaryDropdownOptions]
  );

  // dropdown list filter function triggered when search key is typed in the input field
  const filterList = (value: string) => {
    if (props.setInfiniteLoading) {
      if (value !== "") props.setInfiniteLoading(false);
      else props.setInfiniteLoading(true);
    }
    setSearchTerm(value);
    const searchText = value.toString().toLowerCase();
    if (props.currentDepth === 0) {
      // case for metadata keys
      let dropdownOptions = props.nestedDropdownOptions.filter((option) =>
        option.label.toString().toLowerCase().includes(searchText)
      );
      setFilterDropdownOptions(dropdownOptions.filter((option) => option)); // making sure no empty values are passed
    } else {
      // case for metadata values
      filterMetadataValueList(searchText);
    }
  };

  const goBack = (e: any) => {
    e.stopPropagation();
    // reset infinite loading back to true if the prop exists
    // so that when getting back at this depth loading would happen
    if (props.setInfiniteLoading) props.setInfiniteLoading(true);
    setSecondaryDropdownOptions([]);
    setSecondaryDropdownDisplayOptions([]);
    setSearchTerm("");
    setFilterDropdownOptions(
      props.nestedDropdownOptions.filter((option) => option) // making sure no empty values are passed
    );
    props.setCurrentDepth(props.currentDepth - 1);
  };

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        // Handle Enter key press here
        // auto trigger selection of the only item in the list
        if (props.currentDepth === 0) {
          // depth level initial
          if (filterDropdownOptions.length === 1) handleFilterKeySelect(0);
        } else if (props.currentDepth === props.depth - 1) {
          // depth level final (for now only 2 levels so no loop)
          if (secondaryDropdownDisplayOptions.length === 1) filterValues(0);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filterDropdownOptions,
      props.currentDepth,
      props.depth,
      secondaryDropdownDisplayOptions,
    ]
  );

  const calculateDropdownHeight = (list: Array<any>): string => {
    const baseHeight = 40; // essentially the height of each dropdown item
    const basePadding = 30; // final additive padding
    const optionCount = list.length;
    const maxHeight = 300;
    const adjustedHeight = 260; // taking into account of search bar is not there

    // let max ~7 items display at one time
    if (!props.search && props.currentDepth !== props.depth - 1) {
      if (optionCount > 7) {
        return `${maxHeight}px`;
      } else {
        return `${optionCount * baseHeight + basePadding}px`;
      }
    } else {
      if (optionCount > 7) {
        return `${adjustedHeight}px`;
      } else {
        return `${(optionCount - 1) * baseHeight + basePadding}px`;
      }
    }
  };

  const Footer = () => {
    return (
      <DropdownItem
        noHover
        style={{
          paddingBottom: "10px",
          justifyContent: "center",
        }}
      >
        <LoadingAnimation loaderSize="20px" />
      </DropdownItem>
    );
  };

  const updateSecondaryDropdownOptions = useCallback(() => {
    let optionKey: number = 0;

    // get the original key from the unfiltered list
    for (let option in props.nestedDropdownOptions) {
      if (props.nestedDropdownOptions[option].label === selectedMetadataKey) {
        optionKey = Number(option);
        break;
      }
    }

    if (props.nestedDropdownOptions[optionKey]?.children !== undefined) {
      setSecondaryDropdownOptions(
        props.nestedDropdownOptions[optionKey].children!
      );
      setSecondaryDropdownDisplayOptions(
        props.nestedDropdownOptions[optionKey].children!
      );
    }
  }, [selectedMetadataKey, props.nestedDropdownOptions]);

  useEffect(() => {
    if (props.nestedDropdownOptions) {
      setFilterDropdownOptions(
        props.nestedDropdownOptions.filter((option) => option) // making sure no empty values are passed
      );
    }

    // case where infinite loading updates data
    // secondary dropdown options need to be updated acc to new data
    if (props.currentDepth !== 0) {
      setSearchTerm("");
      updateSecondaryDropdownOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nestedDropdownOptions]);

  return (
    <DropdownWrapper
      id="dropdown-wrapper"
      ref={wrapperRef}
      marginTopExist={!props.search && props.currentDepth !== props.depth - 1}
      buttonPosition={props.parentButtonPosition}
    >
      {/* back button only (not being rendered at level 1) */}
      {!props.search && props.currentDepth === props.depth - 1 && (
        <DropdownHeaderDiv>
          <BackButton
            link
            name="arrow left"
            title="Go back"
            onClick={(e) => goBack(e)}
          />
        </DropdownHeaderDiv>
      )}

      {/* back button and/or search option */}
      {props.search && (
        <DropdownHeaderDiv>
          {/* render at non primary level */}
          {props.currentDepth === props.depth - 1 && (
            <BackButton
              link
              name="arrow left"
              title="Go back"
              onClick={(e) => goBack(e)}
            />
          )}
          <DropdownSearchDiv
            id="search-action-filters-input"
            fluid
            autoFocus={true}
            icon="search"
            placeholder="Filter..."
            value={searchTerm}
            onKeyDown={handleKeyPress}
            onChange={(e) => filterList(e.currentTarget.value)}
          />
        </DropdownHeaderDiv>
      )}

      {/* first level list */}
      {props.currentDepth === 0 ? (
        filterDropdownOptions.length !== 0 ? (
          <Virtuoso
            key={`depth-${props.currentDepth}`}
            style={{
              height: calculateDropdownHeight(filterDropdownOptions),
            }}
            totalCount={filterDropdownOptions.length}
            itemContent={(index) => {
              const option = filterDropdownOptions[index];
              return (
                option && (
                  <DropdownItem
                    id={`metadata-name-${option.label}-${index}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFilterKeySelect(index);
                    }}
                  >
                    {capitalizeFirstLetter(option.label)}
                  </DropdownItem>
                )
              );
            }}
          />
        ) : (
          <DropdownItem>No matching values</DropdownItem>
        )
      ) : // second level list
      props.currentDepth === 1 ? (
        secondaryDropdownDisplayOptions.length !== 0 ? (
          <Virtuoso
            key={`depth-${props.currentDepth}`}
            style={{
              minWidth: "100%",
              height:
                secondaryDropdownDisplayOptions.length > 7
                  ? "260px" // max height
                  : `${secondaryDropdownDisplayOptions.length * 40 + 30}px`, // adjusted height taking into account variable item heights
            }}
            totalCount={secondaryDropdownDisplayOptions.length}
            data={secondaryDropdownDisplayOptions}
            endReached={props.infiniteLoading ? props.loadMore : () => {}}
            increaseViewportBy={260} // inc by max height
            itemContent={(index) => {
              const option = secondaryDropdownDisplayOptions[index].text;
              return (
                <DropdownItem
                  id={`metadata-value-${option}-${index}`}
                  onClick={() => filterValues(index)}
                >
                  {props.currentDepth === props.depth - 1 && (
                    <Checkbox
                      id={`metadata-dropdown-value-${index}`}
                      fitted
                      style={{ marginRight: "1rem" }}
                      checked={isMetadataValueSelected(index)}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e, { checked }) => {
                        if (!checked) {
                          removeMetadataValue(index, true);
                        } else {
                          selectMetadataValue(index, true);
                        }
                      }}
                    />
                  )}
                  {option}
                </DropdownItem>
              );
            }}
            components={props.infiniteLoading ? { Footer } : {}}
          />
        ) : (
          <DropdownItem>No matching values</DropdownItem>
        )
      ) : (
        <DropdownItem>No matching values</DropdownItem>
      )}
    </DropdownWrapper>
  );
}
