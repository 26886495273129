import React, { Component } from "react";
import { Accordion, Dropdown, Icon } from "semantic-ui-react";
import { OptionType } from "../util";
import { DropdownOptionType } from "../../DeviceManagement/Devices/ActionModals/UpdateConfigModal";
import { UploadGeofenceConfigComponent } from "./UploadGeofenceConfigComponent";
import { LatLng } from "../Inventory/DeviceConfigurations/GeofenceEditor";
import { beamtoast } from "../../../common/CustomToast";

interface SelectOrUploadGeofenceConfigAccordionProps {
  setDisableNextButton: (arg0: boolean) => void;
  setOptionType: (arg0: OptionType) => void;
  optionType: OptionType;
  uploadTriggered: string;
  setUploadTriggered: (arg0: string) => void;
  uploadTriggeredEach: boolean;
  setActiveModal: (arg0: number) => void;
  activeModal: number;
  activeIndex: number;
  setActiveIndex: (arg0: number) => void;
  selectedGeofenceVersion: DropdownOptionType;
  setSelectedGeofenceVersion: (arg0: DropdownOptionType) => void;
  selectedCustomGeofenceVersion: string;
  setSelectedCustomGeofenceVersion: (arg0: string) => void;
  configGeofenceOptions: DropdownOptionType[];
  allConfigOptions: Set<any> | undefined;
  fillDropdownOptions: () => void;
  path: LatLng[];
  setPath: (arg0: LatLng[]) => void;
  isPolygonComplete: boolean;
  setIsPolygonComplete: (arg0: boolean) => void;
  alertThreshold: number;
  setAlertThreshold: (arg0: number) => void;
  theme: string;
  latitude: number;
  setLatitude: (arg0: number) => void;
  longitude: number;
  setLongitude: (arg0: number) => void;
}

export default class SelectOrUploadGeofenceConfigAccordion extends Component<SelectOrUploadGeofenceConfigAccordionProps> {
  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = this.props.activeIndex === index ? -1 : index;

    this.props.setActiveIndex(newIndex);

    this.props.fillDropdownOptions();

    if (newIndex === 0) this.props.setOptionType(OptionType.ChooseGeofence);

    if (newIndex === 1) this.props.setOptionType(OptionType.UploadGeofence);

    if (newIndex === -1) this.props.setDisableNextButton(true);
    else this.props.setDisableNextButton(false);
  };

  onSelect = async (e, data) => {
    this.props.setSelectedGeofenceVersion(data);
    this.props.setOptionType(OptionType.ChooseGeofence);
  };

  returnConfigValue = () => {
    let selectedDropdownOption: string = "";
    this.props.configGeofenceOptions.forEach((config: DropdownOptionType) => {
      if (config.value === this.props.selectedGeofenceVersion.value) {
        selectedDropdownOption = config.text;
      }
    });
    return selectedDropdownOption;
  };

  componentDidUpdate(
    prevProps: Readonly<SelectOrUploadGeofenceConfigAccordionProps>
  ): void {
    this.props.setUploadTriggered("");
    if (this.props.activeIndex === 0)
      this.props.setOptionType(OptionType.ChooseGeofence);

    if (this.props.activeIndex === 1)
      this.props.setOptionType(OptionType.UploadGeofence);

    if (this.props.activeIndex === -1) this.props.setDisableNextButton(true);
    else this.props.setDisableNextButton(false);
    if (prevProps.uploadTriggeredEach !== this.props.uploadTriggeredEach) {
      if (this.props.uploadTriggered === "trigger") {
        switch (this.props.optionType) {
          case OptionType.ChooseGeofence:
            if (
              this.props.selectedGeofenceVersion &&
              this.props.selectedGeofenceVersion.value !== ""
            ) {
              this.props.setSelectedGeofenceVersion(
                this.props.selectedGeofenceVersion
              );
              this.props.setActiveModal(this.props.activeModal + 1);
            } else
              beamtoast.error("Please select a geofence version to trigger");
        }
      }
    }
  }

  render() {
    return (
      <Accordion fluid>
        <Accordion.Title
          active={this.props.activeIndex === 0}
          index={0}
          onClick={this.handleClick}
        >
          <p
            style={{
              fontSize: "1.2rem",
              margin: 0,
            }}
          >
            <Icon name="dropdown" />
            Select Geofence Config Version
          </p>
        </Accordion.Title>
        <Accordion.Content active={this.props.activeIndex === 0}>
          <div style={{ padding: "1rem 8rem" }}>
            <Dropdown
              placeholder="Select Version"
              fluid
              selection
              defaultValue={this.returnConfigValue()}
              options={this.props.configGeofenceOptions}
              onChange={this.onSelect}
            />
          </div>
        </Accordion.Content>
        <Accordion.Title
          active={this.props.activeIndex === 1}
          index={1}
          onClick={this.handleClick}
        >
          <p
            style={{
              fontSize: "1.2rem",
              margin: 0,
            }}
          >
            <Icon name="dropdown" />
            Upload new Geofence Config Version
          </p>
        </Accordion.Title>
        <Accordion.Content active={this.props.activeIndex === 1}>
          <UploadGeofenceConfigComponent
            setOptionType={(option) => this.props.setOptionType(option)}
            optionType={this.props.optionType}
            uploadTriggered={this.props.uploadTriggered}
            uploadTriggeredEach={this.props.uploadTriggeredEach}
            setActiveModal={(index) => this.props.setActiveModal(index)}
            activeModal={this.props.activeModal}
            geofenceOptions={this.props.configGeofenceOptions}
            allConfigOptions={this.props.allConfigOptions}
            selectedCustomGeofenceVersion={
              this.props.selectedCustomGeofenceVersion
            }
            setSelectedCustomGeofenceVersion={(version) =>
              this.props.setSelectedCustomGeofenceVersion(version)
            }
            path={this.props.path}
            setPath={(path) => this.props.setPath(path)}
            isPolygonComplete={this.props.isPolygonComplete}
            setIsPolygonComplete={(value) =>
              this.props.setIsPolygonComplete(value)
            }
            alertThreshold={this.props.alertThreshold}
            setAlertThreshold={(threshold) =>
              this.props.setAlertThreshold(threshold)
            }
            theme={this.props.theme}
            setDisableNextButton={(value) =>
              this.props.setDisableNextButton(value)
            }
            latitude={this.props.latitude}
            setLatitude={(latitude) => this.props.setLatitude(latitude)}
            longitude={this.props.longitude}
            setLongitude={(longitude) => this.props.setLongitude(longitude)}
          />
        </Accordion.Content>
      </Accordion>
    );
  }
}
