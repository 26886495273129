import React from "react";
import Plot from "react-plotly.js";
import { useUser } from "../../../context/User.context";
import LoadingAnimation from "../../common/Loader";
import ThemeSchema from "../../../theme/schema";

interface DonutChartProps {
  setTotalCount: number;
  setInitiatedListCount: number;
  setQueuedListCount: number;
  setProgressListCount: number;
  setFailedListCount: number;
  setCompletedListCount: number;
  height: number;
  width: number;
  textInfo: boolean;
}

function BarChart(props: DonutChartProps) {
  const { user } = useUser();
  const theme = user?.settings?.theme;

  return props.setTotalCount === 0 || props.setTotalCount === undefined ? (
    <div>
      <LoadingAnimation loadingText={`Loading bar chart`} fontSize="20px" />
    </div>
  ) : (
    <div>
      <Plot
        data={[
          {
            name: "Actions Summary",
            x: [
              props.setQueuedListCount,
              props.setInitiatedListCount,
              props.setProgressListCount,
              props.setCompletedListCount,
              props.setFailedListCount,
            ],
            y: ["Queued", "Initiated", "In Progress", "Completed", "Failed"],
            marker: {
              showscale: false,
              color:
                ThemeSchema.data[theme ?? "dark"]?.colors[
                  "chart_state-color-scheme-array"
                ],
            },
            type: "bar",
            orientation: "h",
            showlegend: false,
            hoverinfo: "x+text",
          },
        ]}
        layout={{
          height: props.height,
          width: props.width,
          bargap: 0.7,
          margin: {
            l: 0,
            r: 0,
            b: 20,
            t: 20,
          },
          xaxis: {
            color:
              ThemeSchema.data[theme ?? "dark"]?.colors["chart-text-color"],
            showline: true,
            showgrid: false,
            fixedrange: true,
          },
          yaxis: {
            color:
              ThemeSchema.data[theme ?? "dark"]?.colors["chart-text-color"],
            showgrid: false,
            fixedrange: true,
            ticklen: 2,
          },
          paper_bgcolor: "transparent",
          plot_bgcolor: "transparent",
        }}
        config={{
          displayModeBar: false,
        }}
      />
    </div>
  );
}

export default BarChart;
