import React from "react";
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "semantic-ui-react";

export default function SimpleConfirmationModal({
  open,
  header = "Are you sure?",
  noHeader = false,
  content,
  onClose,
  onConfirm,
}: {
  open: boolean;
  header?: string;
  noHeader?: boolean;
  content: string;
  onClose: () => void;
  onConfirm: () => void;
}) {
  return (
    <Modal onClose={onClose} open={open} size="small" className="dark">
      {!noHeader && <ModalHeader>{header}</ModalHeader>}
      <ModalContent>
        <p>{content}</p>
      </ModalContent>
      <ModalActions>
        <Button icon="close" content="Go back" onClick={onClose} />
        <Button primary icon="check" content="Proceed" onClick={onConfirm} />
      </ModalActions>
    </Modal>
  );
}
