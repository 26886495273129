import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import {
  Device,
  Filters,
  PendingDeviceActionsResponse,
  SearchDeviceResponse,
  getPendingActionsOnDevices,
  getTenantFromURL,
} from "../../../../BytebeamClient";
import { Checkbox, Icon, Popup, Table } from "semantic-ui-react";
import { capitalizeFirstLetter } from "../../util";
import { ErrorMessage } from "../../../common/ErrorMessage";
import SlicedTextPopUp from "../../DeviceManagement/Devices/SlicedTextPopUp";
import {
  StyledActionNavItem,
  StyledContent,
} from "../NewAction/ActionWarningModal";

type ActionModalSearchResultsProps = {
  readonly metadataKeysToShow: string[];
  readonly devices: Readonly<SearchDeviceResponse>;
  readonly checkEnabled: boolean;
  readonly allSelected?: boolean;
  readonly setAllSelected?: (arg: boolean) => void;
  readonly selectedDevices?: Readonly<SearchDeviceResponse>;
  readonly setSelectedDevices?: (arg: Readonly<SearchDeviceResponse>) => void;
  readonly selectDevice?: (arg: Readonly<Device>) => void;
  readonly clearDevice?: (arg: Readonly<Device>) => void;
  readonly phase?: string;
};

export function ActionModalSearchResults(props: ActionModalSearchResultsProps) {
  const checkboxRefs: any = useRef([]); // Refs for checkboxes
  const serialMetadataObj = props.devices.devices?.[0]?.["-serial_metadata"];

  const [pendingActionsOnDevices, setPendingActionsOnDevices] =
    useState<PendingDeviceActionsResponse>({});

  // Initialize refs for checkboxes and div elements
  const numCheckboxes = props.devices.count; // The number of checkboxes
  if (checkboxRefs.current.length !== numCheckboxes) {
    checkboxRefs.current = Array(numCheckboxes)
      .fill(null)
      .map((_, index) => checkboxRefs.current[index] || React.createRef());
  }

  const isDeviceSelected = (deviceID) => {
    return props.selectedDevices?.devices.some(
      (device) => device.id === deviceID
    );
  };

  const imitateCheckboxClick = (device: Device, index: number) => {
    if (!props.allSelected) {
      const checkboxRef = checkboxRefs.current[index];

      if (checkboxRef.current) {
        if (!checkboxRef.current.state.checked && props.selectDevice) {
          props.selectDevice(device);
        } else if (props.clearDevice) {
          props.clearDevice(device);
        }
      }
    }
  };

  // Set selected devices when all devices are selected on component mount.
  useEffect(() => {
    if (props.allSelected && props.setSelectedDevices) {
      props.setSelectedDevices(props.devices);
    }
  }, [props.devices]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function getPendingActionInfo() {
      let filter: Filters = {
        id: [],
      };
      props.devices.devices.forEach((device) => {
        filter.id.push(String(device.id));
      });
      const response = await getPendingActionsOnDevices(filter);

      setPendingActionsOnDevices({
        ...pendingActionsOnDevices,
        ...response,
      });
    }

    getPendingActionInfo();
  }, [JSON.stringify(props.devices)]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ maxWidth: "100%", overflow: "scroll", marginBottom: "10px" }}>
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            {props.checkEnabled && (
              <Table.HeaderCell>
                <Checkbox
                  check_id={`${props.phase}-select_all`}
                  checked={props.allSelected}
                  disabled={props.devices.devices.length === 0}
                  onChange={(e, { checked }) => {
                    if (
                      checked &&
                      props.setAllSelected &&
                      props.setSelectedDevices
                    ) {
                      props.setAllSelected(true);
                      props.setSelectedDevices(props.devices);
                    } else if (
                      props.setAllSelected &&
                      props.setSelectedDevices
                    ) {
                      props.setAllSelected(false);
                      props.setSelectedDevices({ devices: [], count: 0 });
                    }
                  }}
                />
              </Table.HeaderCell>
            )}
            {serialMetadataObj && (
              <Table.HeaderCell>
                {`#${capitalizeFirstLetter(
                  Object.keys(serialMetadataObj).toString()
                )}`}
              </Table.HeaderCell>
            )}
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Last Heartbeat</Table.HeaderCell>
            {props.metadataKeysToShow.map((key) => {
              return (
                <Table.HeaderCell key={key} style={{ minWidth: "120px" }}>
                  {capitalizeFirstLetter(key)}
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {props.devices.devices.length !== 0 ? (
            props.devices.devices.map((device, index) => (
              <Table.Row
                style={{ cursor: "pointer" }}
                key={device.id}
                onClick={() => {
                  imitateCheckboxClick(device, index);
                }}
              >
                {props.checkEnabled && (
                  <Table.Cell>
                    <Checkbox
                      check_id={`device_${device.id}`}
                      ref={checkboxRefs.current[index]}
                      disabled={props.allSelected}
                      checked={props.allSelected || isDeviceSelected(device.id)}
                      onChange={(e, { checked }) => {
                        if (checked && props.selectDevice) {
                          props.selectDevice(device);
                        } else if (props.clearDevice) {
                          props.clearDevice(device);
                        }
                      }}
                    />
                  </Table.Cell>
                )}
                {device?.["-serial_metadata"] && (
                  <Table.Cell>
                    <SlicedTextPopUp
                      noWrap
                      text={Object.values(device["-serial_metadata"])[0]}
                      length={22}
                    />
                  </Table.Cell>
                )}
                <Table.Cell>{device.id}</Table.Cell>
                <Table.Cell>
                  {pendingActionsOnDevices?.[device.id]?.filter(
                    (action) =>
                      action.type !== "cancel_action" &&
                      action.type !== "launch_shell"
                  )?.length > 0 && (
                    <Popup
                      hoverable
                      inverted
                      position="right center"
                      size="small"
                      trigger={
                        <Icon
                          name="exclamation circle"
                          color="yellow"
                          style={{ marginRight: "16px" }}
                        />
                      }
                      content={
                        <StyledContent
                          style={{
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                          }}
                        >
                          {pendingActionsOnDevices[device.id]
                            ?.filter(
                              (action) =>
                                action.type !== "cancel_action" &&
                                action.type !== "launch_shell"
                            )
                            ?.map((action) => (
                              <StyledActionNavItem
                                key={action.action_id}
                                onClick={() => {
                                  window.open(
                                    `/projects/${getTenantFromURL()}/actions?action_id=${action.action_id}`,
                                    "_blank"
                                  );
                                }}
                              >
                                {`Currently Action ${action.action_id} - ${action.type} is being in "${action.status}" state for this device`}{" "}
                                <Icon name="share square" />
                              </StyledActionNavItem>
                            ))}
                        </StyledContent>
                      }
                    />
                  )}
                  {device.state?.timestamp
                    ? capitalizeFirstLetter(
                        moment
                          .duration(
                            -1 *
                              moment().diff(
                                moment.utc(device.state["timestamp"])
                              )
                          )
                          .humanize(true)
                      )
                    : "-"}
                </Table.Cell>
                {props.metadataKeysToShow.map((key) => {
                  return (
                    <Table.Cell key={key}>
                      <SlicedTextPopUp
                        text={device?.metadata[key]}
                        length={12}
                      />
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colspan={2}>
                <ErrorMessage marginTop="30px" message={"No data Found!"} />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  );
}
