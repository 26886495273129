import React, { useState } from "react";
import { Button, Modal, Icon, Popup } from "semantic-ui-react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import RemoteShell from "./RemoteShell/RemoteShell";
import { markActionAsCompleted } from "../../../../../BytebeamClient";

type RemoteShellModalProps = {
  deviceId: number;
  isOpen: boolean;
  close: () => void;
};

export function RemoteShellModal(props: RemoteShellModalProps) {
  const { isOpen } = props;
  const [actionId, setActionId] = useState();
  const fullScreenHandle = useFullScreenHandle();

  // Close the modal and mark the action as completed.
  const handleRemoteShellClose = () => {
    if (actionId) {
      try {
        markActionAsCompleted(actionId, { all: true });
      } catch (e) {
        console.log("Failed to mark action as completed", e);
      }
    }
    props.close();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleRemoteShellClose}
      size="fullscreen"
      className="dark"
      closeOnDimmerClick={false}
      style={{ marginLeft: "2em" }}
    >
      <Modal.Header>
        Remote Shell
        {!fullScreenHandle.active ? (
          <span className="bytebeam-panel-icons">
            <span className="bytebeam-panel-icon">
              <Popup
                content="View full screen"
                position="top center"
                inverted
                trigger={
                  <Icon
                    name="expand"
                    color="blue"
                    link
                    onClick={fullScreenHandle.enter}
                  />
                }
              />
            </span>
          </span>
        ) : (
          ""
        )}
      </Modal.Header>
      <Modal.Content>
        {/* @ts-ignore */}
        <FullScreen handle={fullScreenHandle}>
          {fullScreenHandle.active ? (
            <span className="bytebeam-panel-icons">
              <span className="bytebeam-panel-icon">
                <Popup
                  content="Exit full screen"
                  position="top center"
                  inverted
                  trigger={
                    <Icon
                      name="compress"
                      color="yellow"
                      link
                      onClick={fullScreenHandle.exit}
                    />
                  }
                />
              </span>
            </span>
          ) : (
            ""
          )}
          <RemoteShell
            setActionId={setActionId}
            deviceId={props.deviceId}
            isFullScreen={fullScreenHandle.active}
          />
        </FullScreen>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => handleRemoteShellClose()}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
