import React, { useEffect, useState } from "react";
import { Table, Input, Icon } from "semantic-ui-react";
import { hasSpecialCharacters, isNumericType } from "../../util";
import TypeSelection from "./TypeSelection";
import UnitSelection from "./UnitSelection";
import { beamtoast } from "../../../common/CustomToast";

const AddColButton = ({ onClick, disabled }) => (
  <Icon
    style={{
      cursor: disabled === true ? "not-allowed" : "pointer",
    }}
    link={!disabled}
    name="add"
    onClick={onClick}
    disabled={disabled}
  />
);

export default function AddNewColumn({ tableName, addNewCol, columnNameSet }) {
  const [columnName, setColumnName] = useState("");
  const [columnType, setColumnType] = useState("");
  const [columnUnit, setColumnUnit] = useState(null);

  const isValid = columnType !== "" && columnName !== "";

  useEffect(() => {
    if (!isNumericType(columnType)) {
      setColumnUnit(null);
    }
  }, [columnType]);

  return (
    <Table.Row key={tableName + ":add-col"}>
      <Table.Cell style={{ paddingRight: "36px" }}>
        <Input
          placeholder="Field Name"
          value={columnName}
          onChange={(_e, d) =>
            setColumnName(d.value?.replace(" ", "_")?.trim())
          }
          style={{ width: "100%" }}
        />
      </Table.Cell>

      <Table.Cell style={{ paddingRight: "36px" }}>
        <TypeSelection
          value={columnType}
          onChange={(_e, d) => setColumnType(d.value)}
          disabled={false}
          id={"add_column-type"}
          editMode={false}
        />
      </Table.Cell>

      <Table.Cell style={{ paddingRight: "36px" }}>
        <UnitSelection
          value={columnUnit}
          onChange={(_e, d) => setColumnUnit(d.value)}
          disabled={!isNumericType(columnType)}
        />
      </Table.Cell>

      <Table.Cell>
        <AddColButton
          onClick={() => {
            if (columnNameSet?.has(columnName)) {
              beamtoast.error(`${columnName} is already present`);
            } else if (hasSpecialCharacters(columnName)) {
              beamtoast.error(
                "Field Name cannot contain special characters except underscore"
              );
            } else addNewCol(tableName, columnName, columnType, columnUnit);
          }}
          disabled={!isValid}
        />
      </Table.Cell>
    </Table.Row>
  );
}
