interface ThemeColors {
  [key: string]: string | string[];
}

interface Theme {
  id: string;
  name: string;
  colors: ThemeColors;
  font: string;
}

interface ThemeData {
  data: {
    light: Theme;
    dark: Theme;
  };
}

const ThemeSchema: ThemeData = {
  data: {
    light: {
      id: "T_001",
      name: "Light",
      colors: {
        "background-color": "#f7f7f7",
        "foreground-color": "#1d1f31",
        "primary-color": "#3573FF",
        "primary-hover-color": "#5e94ff",
        "secondary-color": "#686868",
        "secondary-hover-color": "#757575",
        "secondary-color-2": "#67696a",

        "chart-text-color": "#1d1f31",
        "ace-editor-theme": "chrome",
        "ace-editor-border-color": "#CBCBCB",
        "ace-editor-border": "1px solid #CBCBCB",
        "ace-editor-box-shadow-color": "rgba(0, 0, 0, 0.25)",
        "ace-editor-box-shadow": "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",

        "project-setup-modal-light-color": "#2b2b2b",
        "project-setup-modal-hover-background-color": "#d7d7d7",
        "project-setup-modal-card-hover-background-color": "#f9f9f9",

        "link-text-color": "#1158e1",
        "code-query-text-color": "#202020",
        "code-query-text-background-color": "#ffca85",

        "get-started-cards-background-color": "#F9F9F9",
        "get-started-cards-peel-background-color": "#DDDDDD",
        "get-started-cards-read-ticked-color": "#03c003",
        "get-started-cards-box-shadow": "0px 0px 12px -1px #00000040",
        "get-started-explore-button-text-color": "#FFFFFF",
        "get-started-explore-button-background-color": "#3573FF",
        "get-started-explore-button-background-hover-color": "#6093ff",

        "vertical-menu-hover-color": "#a6a6a6",
        "vertical-menu-active-text-color": "#e3e3e3",
        "vertical-menu-active-hover-text-color": "#ffffffff",
        "dropdown-bold-text": "#f6f6f6",

        "navbar-background": "#ffffff",
        "navbar-item-text-color": "#1b1b1b",
        "navbar-item-text-hover-color": "#000000",
        "navbar-item-background-hover-color": "#fdfdfd",
        "navbar-active-item-text-color": "#000000",
        "navbar-active-item-background-color": "#e3e3e3",
        "navbar-active-item-background-hover-color": "#eaeaea",
        "navbar-vertical-menu-box-shadow-hover":
          "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",

        "navbar-dropdown-background-hover-color": "#a7a7a7",
        "deviceId-drop-down-background-color": "#bebebe5e",
        "dropdown-menu-selected-items-background-color": "#1a1a1a2d",

        "bytebeam_logo-color": "#000000",
        "reset-logo-button-border": "none",

        "login-container-background": "#ededed",
        "login-container-background-secondary": "#f6f6f6",

        "modal-heading-text-color": "#242424",
        "modal-separation-line-color": "#c9c9c9",

        "container-background": "#FFFFFF",
        "container-box_shadow": "0px 0px 6px 0px #d0d0d0",
        "container-box_shadow-hover": "0px 2px 2px 1px #e3e3e3",
        "container-box_shadow-disable": "none",
        "container-border": "1px solid #22242626",
        "container-border-color": "#22242626",
        "container-secondary-border-color": "#afafaf",
        "pulse-download-border": "1px solid #22242626",
        "stream-table-outer-border": "#c3c3c3",
        "stream-table-cell-border": "#9b9b9b",
        "stream-table-hover-color": "#e0e0e0",

        "table-row-warning-background-color": "#D1D1D1",
        "table-row-warning-color": "rgb(40, 40, 40)",

        "table-outer-border": "#b2b2b2",
        "table-header-background-color": "#D1D1D1",
        "table-header-hover-background-color": "#bcbcbc",
        "table-text": "#494949",
        "table-bold-text": "#000000",
        "table-hover-cell-color": "#eaeaea",
        "table-hover-text-color": "#001000",

        "devices_list-action-button-border-color": "#787878",
        "action-selectable-item-selected": "#d0d0d0",
        "action-selectable-item-selected-hover": "#e3e3e3",
        "action-selectable-item-button-border-color": "#888888",
        "action-not-selected-item-button-text-color": "#6e6e6e",

        "display-dropdown-background-color": "#f7f7f7",
        "display-dropdown-border-color": "#111111",

        "action-selected-table-background": "#eeeded",
        "action-selected-table-text-color": "#000000",
        "action-selected-table-border-color": "#4079ff",
        "action_button-background": "#ffffff",
        "action_button-background-disable": "#fdfdfd",
        "action_button-text": "#1d1f31",
        "action_button-text-disable": "#c5c5c5",
        "action_button-box_shadow": "0px 0px 4px 0px #d0d0d0",
        "action_button-box_shadow-disable": "none",

        "live-actions-more-info-popup-border": "none",

        "action-card-container-background": "#ffffff",
        "inventory-navbar-background-active-color": "#575757",
        "inventory-navbar-background-color": "transparent",
        "inventory-navbar-text-color": "#575757",
        "inventory-navbar-text-active-color": "#ffffff",
        "inventory-navbar-border-active-color": "#575757",
        "inventory-navbar-border-color": "#bababa",

        "primary_tab-background": "#f6f6f6",
        "primary_tab-background-active": "#686868",
        "primary_tab-background-active-hover": "#757575",
        "primary_tab-text": "#d0d2e3",
        "primary_tab-text-active": "#d0d2e3",
        "primary_tab-text-active-hover": "#fff",

        "secondary_tab-background": "#FFFFFF",
        "secondary_tab-background-active": "#3573FF",
        "secondary_tab-text": "#000",
        "secondary_tab-text-active": "#fff",

        "secondary_menu_tab-background": "transparent",
        "secondary_menu_tab-text": "#1d1f31",
        "secondary_menu_tab-text-active": "#000000",
        "secondary_menu_tab-text-hover": "#000000",

        "search_input-background": "#ffffff",
        "search_input-box_shadow": "0px 0px 4px 0px #d0d0d0",
        "search_input-placeholder-color": "#70717a",
        "search_input-placeholder-color-focus": "#a8a8a8",
        "search_input-border": "1px solid #8c8c8c",
        "card_search_input-background": "#FFFFFF",

        "pagination-box-shadow": "0px 0px 3px 0px #d0d0d0",
        "pagination-active-item-color": "#a2a2a2",
        "pagination-item-background-hover-color": "#d9d9d9",
        "card_pagination-item-background-color": "#d1d1d1",
        "card_pagination-item-background-hover-color": "#bcbcbc",
        "card_pagination-active-item-background-color": "#a2a2a2",

        "secondary-button-color": "#686868",
        "secondary-button-hover-color": "#363535",
        "cta-button-bold-text": "#ffffff",
        "cta-button-bold-hover-text": "#f6f6f6",

        "date_time_dropdown_button-background": "#f2f2f2",
        "date_time_dropdown_button-color": "#1d1f31",

        "input-label-filter-bg": "#ffffff",
        "input-label-filter-text-color": "#1b1b1b",
        "input-label-bg": "#ececec",
        "input-label-bg-hover": "#e2e2e2",
        "input-text-active-hover": "#111",

        "component-bold-text": "#1d1f31",
        "component-bold-text-hover": "#292929",

        "select_file_button-background": "#f1f1f1",
        "select_file_button-color": "#1d1f31",
        "action_select_file_button-color": "#C7C7C7",
        "select_file_button-border": "1px solid #b0b0b0",
        "action_select_file_button-border": "1px solid #7e7c83",
        "select_file_button-box_shadow": "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",

        "panel-icons-color": "#6A6A6A",
        "panel-icons-hover-color": "#ffffff",
        "panelButton-background-color": "#FFFFFF",
        "panelButton-background-hover-color": "#3573FF",
        "panelButton-border": "1px solid #b2b2b2",
        "panelButton-hover-border": "1px solid #b3b3b3",
        "panelButton-box-shadow": "0px 0px 8px 0px #00000040",
        "panelButton-hover-box-shadow": "0px 0px 8px 2px #19191956",
        "panelButton-text-color": "#161616",
        "panelButton-text-hover-color": "#ffffff",

        "panel-background": "#ffffff",
        "panel-border": "1px solid #CBCBCB",
        "panel-box_shadow": "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
        "panel-border-hover": "#0059f5",

        "active-state-led-panel-label-bg": "#eaeaea",
        "active-state-led-panel-label-border": "2px solid #c9c9c9",

        "reorder-icon-panel-color": "#646464",
        "reorder-icon-panel-hover-color": "#464646",

        "delete-icon-in-panel-color": "#e4e4e4",
        "delete-icon-in-panel-hover-color": "#FFFFFF",
        "delete-icon-in-panel-background-color": "#4e535b",
        "delete-icon-in-panel-background-hover-color": "#333741",
        "delete-icon-in-panel-border": "1px solid #4e535b",
        "delete-icon-in-panel-hover-border": "1px solid #333741",

        "toast-success-background": "#dcfcd8",
        "toast-error-background": "#fcd2d2",
        "toast-info-background": "#cce5ff",
        "toast-warning-background": "#fff3cc",

        "toast-success-text-color": "#111111",
        "toast-error-text-color": "#111111",
        "toast-info-text-color": "#111111",
        "toast-warning-text-color": "#111111",

        "progress_bar-background": "rgba(0, 0, 0, 0.1)",
        "progress_bar-initiated": "#4A82FF",
        "progress_bar-completed": "#03B635",
        "progress_bar-failed": "#DC2A2A",
        "progress_bar-queued": "#C7C7C7",
        "progress_bar-scheduled": "#F57C00",
        "progress_bar-pending_approval": "#cea9f3",
        "progress_bar-in_progress": "#FBB13C",

        "chart_state-color-scheme-array": [
          "#cea9f3",
          "#F57C00",
          "#C7C7C7",
          "#4A82FF",
          "#FBB13C",
          "#03B635",
          "#DC2A2A",
        ],
        "error-stats-charts-color-scheme-array": [
          "#DC2A2A",
          "#D84315",
          "#FF5722",
        ],

        "progress-bar-inner-text-color": "#FFFFFF",
        "progress_circle-actions-inner-text-color": "#FFFFFF",
        "bars-inner-text-color": "#FFFFFF",

        "scrollbar_thumb-background": "#8f959c4c",

        "dropdown-menu": "#f7f7f7",
        "dropdown-border": "#c2c1bd",
        "dropdown-divider-color": "#afafaf",

        "tooltip-background": "#000000",
        "tooltip_primary-text": "#FFFFFF",
        "tooltip_list-text-color": "#DADADA",

        "loading-circle-foreground-color": "#53535354",

        "marker_icon-border-color": "#1d1f31",

        "time-range_container-background": "#f6f6f6",
        "time-range_container-color": "#1d1f31",
        "time-range_container-border": "1px solid rgba(34, 36, 38, 0.15)",
        "time-range_container-box_shadow":
          "0px 1px 2px 0 rgba(34, 36, 38, 0.15)",

        "toggle-enabled-color": "#2185d0",
        "inputs-border": "#96c8da",
        "input-unselected-border": "#7e7c83",
        "ui-label-bg": "#1fba8b",
        "panel-title": "#242424",
        "grid-stroke": "#b4b2af",
        "datatable-bottom-border": "#dbdad6",
        "textarea-bottom-border": "#b9b8b4",
        "breadcrumb-divider": "#4183c4",
        "ui-msg-color": "#1f1f1e",
        "refreshtime-stale": "#801818",
        "filter-icon": "#272727",
        "refreshtime-bg": "#ebeae7",
        "save-button-bg": "#125cec",
        "save-button-color": "#eeeeee",
        "save-button-color-hover": "#ffffff",
        "light-text": "#121212",
        "list-light-text": "#0f0f0f95",
        "note-text-color": "#333333",
        body: "#FFFFFF",
        text: "#000000",

        "carousel-indicator": "#A6A6A6",
        "welcome-divider": "#B9B9B9",
        "welcome-header-text": "#525252",
        "login-button-border": "1px solid #000000",

        "copyright-footer-color": "#525252",

        "add-button-border-color": "#000", // tbd

        "action-dropdown-color": "#F7F7F7",
        "action-border-color": "#b2b2b2",
        "action-border-color-hover": "#d1d1d1",

        "action-section-color": "#1c1c1e", // tbd

        "card_actions-navbar-background-color": "#292C31", //tbd
      },
      font: "Tinos",
    },
    dark: {
      id: "T_002",
      name: "Dark",
      colors: {
        "background-color": "#121416",
        "foreground-color": "#d0d2e3",
        "primary-color": "#125cec",
        "primary-hover-color": "#5e94ff",
        "secondary-color": "#363b45",
        "secondary-hover-color": "#4f5664",
        "secondary-color-2": "#738596",

        "chart-text-color": "#C0C0C0",
        "ace-editor-theme": "chaos",
        "ace-editor-border-color": "#34373b",
        "ace-editor-border": "1px solid #34373b",
        "ace-editor-box-shadow-color": "rgba(255, 255, 255, 0.75)",
        "ace-editor-box-shadow": "0px 0px 4px 0px rgba(255, 255, 255, 0.75)",

        "project-setup-modal-light-color": "#b5b5b5",
        "project-setup-modal-hover-background-color": "#19191e",
        "project-setup-modal-card-hover-background-color": "#19191e",

        "link-text-color": "#87afff",
        "code-query-text-color": "#ffa538",
        "code-query-text-background-color": "#2e2e2e",

        "get-started-cards-background-color": "#363b45",
        "get-started-cards-peel-background-color": "#8b8c8e",
        "get-started-cards-read-ticked-color": "#03c003",
        "get-started-cards-box-shadow": "none",
        "get-started-explore-button-text-color": "#0a0a0a",
        "get-started-explore-button-background-color": "#d9d9d9",
        "get-started-explore-button-background-hover-color": "#FFFFFF",

        "vertical-menu-hover-color": "#4f5664",
        "vertical-menu-active-text-color": "#cfd2e2",
        "vertical-menu-active-hover-text-color": "#ffffffff",
        "dropdown-bold-text": "#f6f6f6",

        "navbar-background": "#18191d",
        "navbar-item-text-color": "#d0d2e3",
        "navbar-item-text-hover-color": "#ffffff",
        "navbar-item-background-hover-color": "#4f5664",
        "navbar-active-item-text-color": "#e0e1e8",
        "navbar-active-item-background-color": "#363b45",
        "navbar-active-item-background-hover-color": "#4f5664",
        "navbar-vertical-menu-box-shadow-hover": "none",

        "navbar-dropdown-background-hover-color": "rgba(255, 255, 255, 0.1)",
        "deviceId-drop-down-background-color": "rgba(255, 255, 255, 0.1)",
        "dropdown-menu-selected-items-background-color": "#5c5c5c",

        "bytebeam_logo-color": "#ffffff",
        "reset-logo-button-border": "1px solid #b2b2b2",

        "login-container-background": "#0e0e0e",
        "login-container-background-secondary": "#111111",

        "modal-heading-text-color": "#ffffffd9",
        "modal-separation-line-color": "#dddbd926",

        "container-background": "#1f2127",
        "container-box_shadow": "none",
        "container-box_shadow-hover": "none",
        "container-box_shadow-disable": "none",
        "container-border": "none",
        "container-border-color": "none",
        "container-secondary-border-color": "#34373b",
        "pulse-download-border": "1px solid #4E5664",
        "stream-table-outer-border": "#212121",
        "stream-table-cell-border": "#373737",
        "stream-table-hover-color": "#252525",

        "table-row-warning-background-color": "#363B45",
        "table-row-warning-color": "rgb(255 255 255)",

        "table-outer-border": "#3b3f43",
        "table-header-background-color": "#363b45",
        "table-header-hover-background-color": "#4f5664",
        "table-text": "#aaadbb",
        "table-bold-text": "#d6d6d6",
        "table-hover-cell-color": "rgba(255, 255, 255, 0.05)",
        "table-hover-text-color": "rgba(255, 255, 255, 0.95)",

        "devices_list-action-button-border-color": "#525252",
        "action-selectable-item-selected": "#4c4c4c",
        "action-selectable-item-selected-hover": "#302f2f",
        "action-selectable-item-button-border-color": "#484848",
        "action-not-selected-item-button-text-color": "#c0c0c0",

        "display-dropdown-background-color": "#141519",
        "display-dropdown-border-color": "#a9a9a9",

        "action-selected-table-background": "#2e323d",
        "action-selected-table-text-color": "#FFFFFF",
        "action-selected-table-border-color": "#5286ff",
        "action_button-background": "#1F2127",
        "action_button-background-disable": "#181a1e",
        "action_button-text": "#d0d2e3",
        "action_button-text-disable": "#6A6B6D",
        "action_button-box_shadow": "none",
        "action_button-box_shadow-disable": "none",

        "live-actions-more-info-popup-border": "1px solid white",

        "action-card-container-background": "#1F2127",
        "inventory-navbar-background-active-color": "#575757",
        "inventory-navbar-background-color": "transparent",
        "inventory-navbar-text-color": "#e4e4e4",
        "inventory-navbar-text-active-color": "#ffffff",
        "inventory-navbar-border-active-color": "#575757",
        "inventory-navbar-border-color": "#bababa",

        "primary_tab-background": "#1f2127",
        "primary_tab-background-active": "#363b45",
        "primary_tab-background-active-hover": "#4f5664",
        "primary_tab-text": "#d0d2e3",
        "primary_tab-text-active": "#d0d2e3",
        "primary_tab-text-active-hover": "#fff",

        "secondary_tab-background": "#1f2127",
        "secondary_tab-background-active": "#125cec",
        "secondary_tab-text": "#fff",
        "secondary_tab-text-active": "#fff",

        "secondary_menu_tab-background": "transparent",
        "secondary_menu_tab-text": "#ffffffb2",
        "secondary_menu_tab-text-active": "#ffffff",
        "secondary_menu_tab-text-hover": "#ffffff",

        "search_input-background": "#111111",
        "search_input-box_shadow": "none",
        "search_input-placeholder-color": "#a8a8a8",
        "search_input-placeholder-color-focus": "#70717a",
        "search_input-border": "1px solid #8a8a8a",
        "card_search_input-background": "#292C31",

        "pagination-box-shadow": "none",
        "pagination-active-item-color": "#363b45",
        "pagination-item-background-hover-color": "#4f5664",
        "card_pagination-item-background-color": "#292C31",
        "card_pagination-item-background-hover-color": "#333740",
        "card_pagination-active-item-background-color": "#3C4047",

        "secondary-button-color": "#363b45",
        "secondary-button-hover-color": "#4f5664",
        "cta-button-bold-text": "#d6d6d6",
        "cta-button-bold-hover-text": "#d6d6d6",

        "date_time_dropdown_button-background": "#2a2b33",
        "date_time_dropdown_button-color": "#d0d2e3",

        "input-label-filter-bg": "#27272d",
        "input-label-filter-text-color": "#cfd2e2",
        "input-label-bg": "#2a2b33",
        "input-label-bg-hover": "#1f2127",
        "input-text-active-hover": "#fff",

        "component-bold-text": "#d0d2e3",
        "component-bold-text-hover": "#f2f2f2",

        "select_file_button-background": "#141414",
        "select_file_button-color": "#d0d2e3",
        "action_select_file_button-color": "#a7a9b7",
        "select_file_button-border": "1px solid #d0d2e3",
        "action_select_file_button-border": "1px solid #a7a9b7",
        "select_file_button-box_shadow":
          "0px 0px 4px 0px rgba(255, 255, 255, 0.75)",

        "panel-icons-color": "#FFFFFF",
        "panel-icons-hover-color": "#FFFFFF",
        "panelButton-background-color": "#363b45",
        "panelButton-border": "none",
        "panelButton-hover-border": "none",
        "panelButton-background-hover-color": "#125cec",
        "panelButton-box-shadow": "none",
        "panelButton-hover-box-shadow": "none",
        "panelButton-text-color": "#d0d2e3",
        "panelButton-text-hover-color": "#ffffff",

        "panel-background": "#1c2024",
        "panel-border": "none",
        "panel-box_shadow": "none",
        "panel-border-hover": "#0059f5",

        "active-state-led-panel-label-bg": "#313138",
        "active-state-led-panel-label-border": "none",

        "reorder-icon-panel-color": "#d6d6d6",
        "reorder-icon-panel-hover-color": "#FFFFFF",

        "delete-icon-in-panel-color": "#d6d6d6",
        "delete-icon-in-panel-hover-color": "#FFFFFF",
        "delete-icon-in-panel-background-color": "#363b45",
        "delete-icon-in-panel-background-hover-color": "#4f5664",
        "delete-icon-in-panel-border": "1px solid #363b45",
        "delete-icon-in-panel-hover-border": "1px solid #4f5664",

        "toast-success-background": "#094e2f",
        "toast-error-background": "#450000",
        "toast-info-background": "#151b1e",
        "toast-warning-background": "#64510d",

        "toast-success-text-color": "#dcfcd8",
        "toast-error-text-color": "#fcd2d2",
        "toast-info-text-color": "#7fc1ff",
        "toast-warning-text-color": "#fcf7e7",

        "progress_bar-background": "rgba(255, 255, 255, 0.08)",
        "progress_bar-initiated": "#6d95d2",
        "progress_bar-completed": "#609d5a",
        "progress_bar-failed": "#c53f4f",
        "progress_bar-queued": "#808080",
        "progress_bar-scheduled": "#FFA726",
        "progress_bar-pending_approval": "#7E57C2",
        "progress_bar-in_progress": "#9e9335",

        "chart_state-color-scheme-array": [
          "#7E57C2",
          "#FFA726",
          "#808080",
          "#6D95D2",
          "#9E9335",
          "#609D5A",
          "#C53F4F",
        ],
        "error-stats-charts-color-scheme-array": [
          "#DC2A2A",
          "#D84315",
          "#FF5722",
        ],

        "progress-bar-inner-text-color": "#FFFFFF",
        "progress_circle-actions-inner-text-color": "#FFFFFF",
        "bars-inner-text-color": "#FFFFFF",

        "scrollbar_thumb-background": "#4c4e6a66",

        "dropdown-menu": "#2f2f34",
        "dropdown-border": "#3d3e42",
        "dropdown-divider-color": "rgba(221,219,217,.5)",

        "tooltip-background": "#000000",
        "tooltip_primary-text": "#FFFFFF",
        "tooltip_list-text-color": "#DADADA",

        "loading-circle-foreground-color": "#96969654",

        "marker_icon-border-color": "#d0d2e3",

        "time-range_container-background": "#363b45",
        "time-range_container-color": "#FFFFFF",
        "time-range_container-border": "none",
        "time-range_container-box_shadow": "none",

        "toggle-enabled-color": "#2185d0",
        "inputs-border": "#96c8da",
        "input-unselected-border": "#a7a9b7",
        "ui-label-bg": "#1fba8b",
        "panel-title": "#738596",
        "grid-stroke": "#4b4d50",
        "datatable-bottom-border": "#242529",
        "textarea-bottom-border": "#46474b",
        "breadcrumb-divider": "#4183c4",
        "ui-msg-color": "#e0e0e1",
        "refreshtime-stale": "#801818",
        "filter-icon": "#d8d8d8",
        "refreshtime-bg": "#1C2024",
        "save-button-bg": "#125cec",
        "save-button-color": "#eeeeee",
        "save-button-color-hover": "#ffffff",
        "light-text": "#ffffff50",
        "list-light-text": "#ffffff70",
        "note-text-color": "lightgrey",
        body: "#000000",
        text: "#FFFFFF",

        "carousel-indicator": "#D9D9D9",
        "welcome-divider": "#808080",
        "welcome-header-text": "#ffffff",
        "login-button-border": "none",

        "copyright-footer-color": "#808080",

        "add-button-border-color": "#BABABA",

        "action-dropdown-color": "#151515",
        "action-border-color": "#3b3f43",
        "action-border-color-hover": "#676767",

        "action-section-color": "#1c1c1e",

        "card_actions-navbar-background-color": "#292C31",
      },
      font: "Tinos",
    },
  },
};

export default ThemeSchema;
