import React, { useState, useEffect } from "react";
import { Button, Dropdown, DropdownItemProps, Modal } from "semantic-ui-react";
import {
  fetchAllStreamsWithDetails,
  FetchStreamsAPIResponse,
  StreamFieldDetails,
} from "../../../../BytebeamClient";
import {
  CompositeCondition,
  Condition,
  dropDownOptionsFromArray,
  SessionType,
  SimpleCondition,
  specialCharacterValidation,
} from "../../../../util";
import SqlWhereClauseBuilder from "../../common/SqlWhereClauseBuilder";
import { StyledInput, StyledLabel } from "../actiontypes/ActionTypesModal";
import { ErrorMessage } from "../roles/CreateOrEditRoleModal";
import styled from "styled-components";

const StyledDropdown = styled(Dropdown)`
  & .message {
    color: ${({ theme }) => theme.colors["text"]} !important;
  }
`;

interface CreateSessionTypeModalProps {
  sessionTypesArray: SessionType[];
  sessionType?: SessionType;
  title: string;
  onSubmit: (sessionType: SessionType) => void;
  trigger: React.ReactNode;
}

type ErrorMessageType = {
  showValidationMessage: boolean;
  nameErrorMessage: string;
};

export default function CreateSessionTypeModal(
  props: CreateSessionTypeModalProps
) {
  const [open, setOpen] = React.useState(false);
  const [streamOptions, setStreamOptions] = useState<DropdownItemProps[]>([]);
  const [fields, setFields] = useState<{ [key: string]: StreamFieldDetails }>(
    {}
  );
  const [streams, setStreams] = useState<FetchStreamsAPIResponse>({});
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>({
    showValidationMessage: false,
    nameErrorMessage: "",
  });

  const defaultSessionType = {
    name: "",
    stream: "",
    condition: { operator: "=", value: "", field: "" } as SimpleCondition,
  };

  const [sessionType, setSessionType] = useState<SessionType>(
    props.sessionType || defaultSessionType
  );
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    const fn = async () => {
      const streams = await fetchAllStreamsWithDetails();

      const streamOptions = Object.keys(streams);
      setStreamOptions(dropDownOptionsFromArray(streamOptions));
      setStreams(streams);
    };

    fn();
  }, []);

  const isSessionNameValidate = (sessionName: string) => {
    const existingSessionType = props.sessionTypesArray.find(
      (session) => session.name.toLowerCase() === sessionName.toLowerCase()
    );
    if (existingSessionType) {
      setErrorMessage({
        showValidationMessage: true,
        nameErrorMessage: "Session with this name already exists",
      });
      return false;
    } else if (
      !specialCharacterValidation(sessionName) &&
      sessionName.length > 0
    ) {
      setErrorMessage({
        showValidationMessage: true,
        nameErrorMessage:
          "Please enter a valid name, special characters not allowed",
      });
      return false;
    } else {
      setErrorMessage({
        showValidationMessage: false,
        nameErrorMessage: "",
      });
      return true;
    }
  };

  useEffect(() => {
    const isValidCondition = (condition: Condition) => {
      const compositeOperators = ["and", "or"];

      if (compositeOperators.includes(condition.operator)) {
        const conditions = (condition as CompositeCondition).conditions;
        return conditions.every(isValidCondition);
      } else {
        const simpleCondition = condition as SimpleCondition;
        return (
          simpleCondition.field.length > 0 &&
          simpleCondition.value !== null &&
          simpleCondition.value !== undefined &&
          simpleCondition.value !== ""
        );
      }
    };

    const isSessionTypeValid =
      sessionType.name.length > 0 &&
      sessionType.stream.length > 0 &&
      isSessionNameValidate(sessionType.name) &&
      isValidCondition(sessionType.condition);

    setButtonDisabled(!isSessionTypeValid);
  }, [sessionType]); // eslint-disable-line react-hooks/exhaustive-deps

  const setInputStream = (inputStream: string) => {
    setSessionType({
      ...sessionType,
      stream: inputStream as string,
      condition: {
        operator: "=",
        value: "",
        field: "",
      } as SimpleCondition,
    });

    const fields = streams[inputStream].fields;

    setFields(fields);
  };

  const handleStateModalClose = () => {
    setErrorMessage({
      showValidationMessage: false,
      nameErrorMessage: "",
    });
    setSessionType(defaultSessionType);
    setOpen(false);
  };

  return (
    <Modal
      className="dark"
      onClose={() => {
        handleStateModalClose();
      }}
      onOpen={() => setOpen(true)}
      open={open}
      size="large"
      trigger={props.trigger}
    >
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Description></Modal.Description>
      <Modal.Content>
        <StyledInput labelPosition="left">
          <StyledLabel>Session Name</StyledLabel>
          <input
            placeholder="Enter session name"
            value={sessionType.name}
            onChange={(e) => {
              setSessionType({ ...sessionType, name: e.target.value });
              isSessionNameValidate(e.target.value);
            }}
          />
        </StyledInput>
        {errorMessage?.showValidationMessage && (
          <ErrorMessage>{errorMessage?.nameErrorMessage}</ErrorMessage>
        )}

        <StyledInput labelPosition="left">
          <StyledLabel>Stream</StyledLabel>

          <StyledDropdown
            fluid
            search
            placeholder="Stream"
            options={streamOptions}
            value={sessionType.stream}
            onChange={(_e, d) => {
              setInputStream(d.value as string);
            }}
            style={{
              padding: "8px 12px",
              border: "none",
            }}
          />
        </StyledInput>

        <SqlWhereClauseBuilder
          fields={fields}
          condition={sessionType.condition}
          onChange={(condition) => {
            setSessionType({
              ...sessionType,
              condition: condition,
            });
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            handleStateModalClose();
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          primary
          disabled={buttonDisabled}
          onClick={() => {
            setOpen(false);
            props.onSubmit(sessionType);
          }}
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
