import React, { useState } from "react";
import { Grid, Icon } from "semantic-ui-react";
import TypeSelection from "./TypeSelection";
import ConfirmationModal from "../../common/ConfirmationModal";

export const TwoItems = ({ left, right }) => (
  <Grid>
    <Grid.Row>
      <Grid.Column width={12}>{left}</Grid.Column>
      <Grid.Column
        width={4}
        verticalAlign="middle"
        floated="right"
        textAlign="right"
        style={{ paddingLeft: "0px", paddingRight: "36px" }}
      >
        {right}
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default function ColumnType({
  required,
  value,
  pendingType,
  status,
  columnName,
  onChange,
  permission,
}) {
  const [newType, setNewType] = useState(value);
  const [editMode, setEditMode] = useState(false);

  if (required) {
    return value;
  }

  if (status === "editing" && pendingType !== value) {
    return value + " -> " + pendingType;
  }
  if (!editMode) {
    let right = <></>;
    if (status === "ready" && permission) {
      right = <Icon link name="edit" onClick={() => setEditMode(true)} />;
    }

    return <TwoItems left={value} right={right} />;
  } else {
    return (
      <TwoItems
        left={
          <TypeSelection
            value={newType}
            onChange={(_e, d) => setNewType(d.value)}
            disabled={false}
            id={"edit_column-type"}
            editMode={true}
          />
        }
        right={
          <>
            <ConfirmationModal
              prefixContent="Edit column type"
              expectedText={columnName}
              onConfirm={() => {
                setEditMode(false);
                onChange(newType);
              }}
              trigger={
                <Icon
                  link
                  name="save"
                  disabled={newType === value}
                  style={{ marginRight: "8px" }}
                />
              }
              message={
                <>
                  <p>
                    <span>Are you absolutely sure?</span>
                    <br />
                    You are going to edit <b>{columnName}</b>, this action will
                    edit column type for {columnName} column.
                  </p>
                  <div
                    style={{
                      padding: "8px",
                      color: "#c50000",
                      textAlign: "center",
                      background: "#f7d4d6",
                      border: "1px solid #c50000",
                      borderRadius: "4px",
                    }}
                  >
                    <b>Warning:</b> This action is not reversible. Please be
                    certain.
                  </div>
                  <p>
                    <br /> Please type <b>{columnName}</b> to proceed or press
                    Cancel to close.
                  </p>
                </>
              }
            />
            <Icon
              link
              name="cancel"
              onClick={() => {
                setEditMode(false);
                setNewType(value);
              }}
            />
          </>
        }
      />
    );
  }
}
