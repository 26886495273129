import React, { useState } from "react";
import styled from "styled-components";
import ThemeSchema from "../../../theme/schema";
import { useUser } from "../../../context/User.context";

const ActionLegendParagraph = styled.p`
  color: ${({ theme }) => theme.colors["foreground-color"]};
  cursor: pointer;
  min-width: 120px;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
`;

interface ActionLegendProps {
  onClick?: Function;
  filterStatus?: boolean;
  liStyles?: Object;
}

function ActionLegend(props: ActionLegendProps) {
  const { onClick, filterStatus, liStyles } = props;
  const [selectedAction, setSelectedAction] = useState<string>("");

  const { user } = useUser();
  const theme = user?.settings?.theme;

  const colorScheme = [
    {
      color: ThemeSchema.data[theme ?? "dark"]?.colors["progress_bar-queued"],
      label: "Queued Actions",
    },
    {
      color:
        ThemeSchema.data[theme ?? "dark"]?.colors["progress_bar-initiated"],
      label: "Initiated Actions",
    },
    {
      color:
        ThemeSchema.data[theme ?? "dark"]?.colors["progress_bar-in_progress"],
      label: "In Progress Actions",
    },

    {
      color:
        ThemeSchema.data[theme ?? "dark"]?.colors["progress_bar-completed"],
      label: "Completed Actions",
    },
    {
      color: ThemeSchema.data[theme ?? "dark"]?.colors["progress_bar-failed"],
      label: "Failed Actions",
    },
  ];

  return (
    <ul className="action-legend">
      {colorScheme.map((item) => (
        <li
          key={item.label}
          style={{
            display: "flex",
            alignItems: "center",
            ...liStyles,
          }}
        >
          <div
            style={{
              width: "10px",
              height: "10px",
              background: item.color,
              marginRight: "0.5rem",
            }}
          />

          <ActionLegendParagraph
            className={`${
              selectedAction === item.label && filterStatus && "underline bold"
            }`}
            onClick={() => {
              if (onClick) {
                onClick(item.label);
                setSelectedAction(item.label);
              }
            }}
          >
            {item.label}
          </ActionLegendParagraph>
        </li>
      ))}
    </ul>
  );
}

export default ActionLegend;
