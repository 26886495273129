import React, { Fragment, useEffect, useState, useRef } from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polyline,
  TrafficLayer,
  InfoWindow,
} from "react-google-maps";

import { GOOGLE_MAPS_API_KEY } from "../../../../../constants/index";
import { TrackDevicesMetaData, TrackDevicesData } from "./PanelDef";
import { PanelViewComponent } from "../PanelDef";
import { MarkerWithInfoWindow } from "../LocateDevices/ViewLocateDevices";
import { ReplayState } from "../../DashboardHeader";
import { darkTheme, lightTheme } from "./MapStyle";
import { DashboardsInfo } from "../../ViewDashboard";
import { Permission, Settings } from "../../../../../util";
import {
  DeviceConfigurationType,
  fetchAllDeviceConfiguration,
} from "../../../../../BytebeamClient";
import { useUser } from "../../../../../context/User.context";
import styled, { createGlobalStyle } from "styled-components";

const HideInfoWindowHeader = createGlobalStyle`
  .gm-style-iw-chr {
    display: none !important;
  }
`;

const StyledPolylineInfoWindowContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  color: black;
  font-weight: 500;
  padding: 12px 8px 4px 0px;
`;

type MapComponentProps = {
  devicesLocationData: TrackDevicesData;
  deviceDashboardId: string;
  replayTimestamp: number;
  replayStep: number;
  replayState: ReplayState;
  trafficLayerFlag: boolean;
  satelliteLayerFlag: boolean;
  allDashboards: DashboardsInfo[];
  settings: Settings;
  markerIcon: string;
};

const MapComponent = compose<MapComponentProps, MapComponentProps>(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&key=" +
      GOOGLE_MAPS_API_KEY +
      "&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: "100%" }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props: MapComponentProps) => {
  const mapsRef = useRef<GoogleMap>(null);
  const [geofenceConfigs, setGeofenceConfigs] = useState<
    { [key: string]: DeviceConfigurationType } | undefined
  >(undefined);
  const [hoveredPosition, setHoveredPosition] = useState<{
    lat: number;
    lng: number;
    timestamp: number;
  } | null>(null);

  const { user } = useUser();
  const permissions: Permission = user?.role?.permissions ?? [];
  const theme = user?.settings?.theme ?? "dark";
  const mapTheme = theme === "light" ? lightTheme : darkTheme;

  async function getGeoFenceConfigs() {
    const configs = await fetchAllDeviceConfiguration();
    const geofenceConfigs = configs.filter(
      (config) => config.action_type === "update_geofence"
    );
    const groupedConfigs = {};

    geofenceConfigs.forEach((config) => {
      groupedConfigs[config.version_name] = config;
    });
    setGeofenceConfigs(groupedConfigs);
  }

  useEffect(() => {
    // Function will only invoke if the user has permission to view device configs
    if (permissions.viewDeviceConfigs && geofenceConfigs === undefined) {
      getGeoFenceConfigs();
    }
  }, [geofenceConfigs, permissions.viewDeviceConfigs]);

  useEffect(() => {
    const bounds = new google.maps.LatLngBounds();

    props.devicesLocationData.forEach((device) => {
      device.coordinates.forEach((c) => {
        bounds.extend(new google.maps.LatLng(c.latitude, c.longitude));
      });

      const geofenceConfig =
        device.state["geofence_version"] && geofenceConfigs
          ? geofenceConfigs[device.state["geofence_version"]]
          : undefined;

      if (geofenceConfig) {
        const path = geofenceConfig["config_json"]["path"];

        path.forEach((point) => {
          bounds.extend(new google.maps.LatLng(point["lat"], point["lng"]));
        });
      }
    });

    mapsRef.current?.fitBounds(bounds);
  }, [props.devicesLocationData, mapsRef, geofenceConfigs]);

  let defaultOptions = {
    streetViewControl: false,
    scaleControl: false,
    mapTypeControl: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    mapTypeControlOptions: {},
    panControl: true,
    zoomControl: false,
    rotateControl: true,
    fullscreenControl: false,
    styles: mapTheme,
  };

  if (props.satelliteLayerFlag) {
    // showing satellite layer
    defaultOptions = {
      streetViewControl: false,
      scaleControl: false,
      mapTypeControl: true,
      mapTypeId: google.maps.MapTypeId.SATELLITE,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [
          google.maps.MapTypeId.ROADMAP,
          google.maps.MapTypeId.SATELLITE,
          google.maps.MapTypeId.HYBRID,
        ],
      },
      panControl: true,
      zoomControl: false,
      rotateControl: true,
      fullscreenControl: false,
      styles: mapTheme,
    };
  }

  const handleMouseOver = (
    event,
    coordinates: {
      latitude: number;
      longitude: number;
      timestamp: number;
    }[]
  ) => {
    const latLng = event.latLng;
    const lat = latLng.lat();
    const lng = latLng.lng();

    // Convert the event's LatLng to a Google Maps LatLng object
    const mousePosition = new google.maps.LatLng(lat, lng);

    let closestPoint: {
      latitude: number;
      longitude: number;
      timestamp: number;
    } | null = null;
    let minDistance = Infinity;

    coordinates.forEach((point) => {
      const pointPosition = new google.maps.LatLng(
        point.latitude,
        point.longitude
      );
      const distance = google.maps.geometry.spherical.computeDistanceBetween(
        mousePosition,
        pointPosition
      );

      if (distance < minDistance) {
        minDistance = distance;
        closestPoint = point;
      }
    });

    if (closestPoint) {
      let point = closestPoint as {
        latitude: number;
        longitude: number;
        timestamp: number;
      };
      setHoveredPosition({
        lat: point.latitude,
        lng: point.longitude,
        timestamp: point.timestamp,
      });
    }
  };

  const handleMouseOut = () => {
    setHoveredPosition(null);
  };

  return (
    // @ts-ignore
    <GoogleMap
      ref={mapsRef}
      defaultZoom={17}
      defaultCenter={{ lat: 12.927381, lng: 77.637729 }}
      options={defaultOptions}
    >
      {props.trafficLayerFlag && <TrafficLayer />}

      {props.devicesLocationData.map((device) => {
        const coordinates = device.coordinates;
        const path = coordinates.map(
          (c) => new google.maps.LatLng(c.latitude, c.longitude)
        );

        // starting location marker color
        const startColor = "black";
        // current location marker + polyline color
        const color = "#ff0000";

        let coordIndex = coordinates.length - 1;

        if (
          props.replayState === ReplayState.ReplayRunning ||
          props.replayState === ReplayState.ReplayPaused
        ) {
          coordIndex = props.replayStep;

          if (coordIndex > coordinates.length - 1) {
            coordIndex = coordinates.length - 1;
          }
        }

        const startLocation = coordinates[0];
        const currentLocation = coordinates[coordIndex];

        const geofenceConfig =
          device?.state["geofence_version"] && geofenceConfigs
            ? geofenceConfigs[device.state["geofence_version"]]
            : undefined;

        return (
          <Fragment key={device.id}>
            <Polyline
              path={path}
              options={{
                strokeColor: color,
                geodesic: true,
                strokeWeight: 4,
              }}
              onMouseOver={(event) => handleMouseOver(event, coordinates)}
              onMouseOut={handleMouseOut}
            />

            {hoveredPosition && (
              <>
                <HideInfoWindowHeader />
                {/* @ts-ignore */}
                <InfoWindow
                  position={{
                    lat: hoveredPosition.lat,
                    lng: hoveredPosition.lng,
                  }}
                  onCloseClick={handleMouseOut}
                >
                  <StyledPolylineInfoWindowContent>
                    <div>Latitude: {hoveredPosition.lat}</div>
                    <div>Longitude: {hoveredPosition.lng}</div>
                    {Boolean(hoveredPosition.timestamp) && (
                      <div>
                        Timestamp:{" "}
                        {new Date(hoveredPosition.timestamp).toLocaleString()}
                      </div>
                    )}
                  </StyledPolylineInfoWindowContent>
                </InfoWindow>
              </>
            )}

            {/* Start Location Marker */}
            <MarkerWithInfoWindow
              deviceId={device.id}
              coordinates={startLocation}
              deviceDashboardIds={[props.deviceDashboardId]}
              color={startColor}
              metadata={device.metadata}
              state={device.state}
              settings={props.settings}
              allDashboards={props.allDashboards}
              markerIcon={props.markerIcon}
              geofenceConfig={geofenceConfig}
              alerts={device.alerts || []}
              headerContent="Start Location"
            />

            {/* Current Location Marker */}
            <MarkerWithInfoWindow
              deviceId={device.id}
              coordinates={currentLocation}
              deviceDashboardIds={[props.deviceDashboardId]}
              color={color}
              metadata={device.metadata}
              state={device.state}
              settings={props.settings}
              allDashboards={props.allDashboards}
              markerIcon={props.markerIcon}
              geofenceConfig={geofenceConfig}
              alerts={device.alerts || []}
              headerContent="Current Location"
            />
          </Fragment>
        );
      })}
    </GoogleMap>
  );
});

export class ViewTrackDevices extends PanelViewComponent<
  TrackDevicesMetaData,
  TrackDevicesData
> {
  render() {
    return (
      <MapComponent
        devicesLocationData={this.props.data}
        deviceDashboardId={this.props.panelMeta.device_dashboard_id}
        trafficLayerFlag={this.props.panelMeta.trafficLayerFlag}
        satelliteLayerFlag={this.props.panelMeta.satelliteLayerFlag}
        replayTimestamp={this.props.replayStep}
        replayState={this.props.replayState}
        replayStep={this.props.replayStep}
        allDashboards={this.props.dashboards}
        settings={this.props.settings}
        markerIcon={this.props.panelMeta.markerIcon ?? "default"}
      />
    );
  }
}
