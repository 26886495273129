import React, { Component } from "react";
import { Accordion, Dropdown, Icon } from "semantic-ui-react";
import { UploadFirmwareComponent } from "./UploadFirmwareComponent";
import { DropdownOptionType } from "../../DeviceManagement/Devices/ActionModals/UpdateConfigModal";
import { OptionType } from "../util";
import { beamtoast } from "../../../common/CustomToast";

interface SelectOrUploadFirmwareAccordionProps {
  setDisableNextButton: (arg0: boolean) => void;
  setOptionType: (arg0: OptionType) => void;
  optionType: OptionType;
  uploadTriggered: string;
  setUploadTriggered: (arg0: string) => void;
  uploadTriggeredEach: boolean;
  setActiveModal: (arg0: number) => void;
  activeModal: number;
  customFirmwareInputData: string;
  setCustomFirmwareInputData: (arg0: string) => void;
  activeIndex: number;
  setActiveIndex: (arg0: number) => void;
  selectedFirmwareVersion: DropdownOptionType;
  setSelectedFirmwareVersion: (arg0: DropdownOptionType) => void;
  firmwareOptions: DropdownOptionType[];
  fillFirmwareDropdownOptions: () => void;
  selectedCustomFirmwareVersion: string;
  setSelectedCustomFirmwareVersion: (arg0: string) => void;
  allFirmwareOptions: Set<any> | undefined;
  firmwareFile: File;
  setFirmwareFile: (arg0: File) => void;
  firmwareFileName: string;
  setFirmwareFileName: (arg0: string) => void;
  showFirmwareUploadProgress: boolean;
  setShowFirmwareUploadProgress: (arg0: boolean) => void;
  firmwareFileLoaded: number;
  setFirmwareFileLoaded: (arg0: number) => void;
  firmwareFileTotal: number;
  setFirmwareFileTotal: (arg0: number) => void;
  uploadedFirmwareFileResponse: {
    status: number;
    data: { id: string };
  };
  setUploadedFirmwareFileResponse: (arg0: {
    status: number;
    data: { id: string };
  }) => void;
  firmwareOptionsLoaded: boolean;
  firmwareFileInput: any;
}

export default class SelectOrUploadFirmwareAccordion extends Component<SelectOrUploadFirmwareAccordionProps> {
  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = this.props.activeIndex === index ? -1 : index;

    this.props.setActiveIndex(newIndex);

    this.props.fillFirmwareDropdownOptions();

    if (newIndex === 0) this.props.setOptionType(OptionType.ChooseFirmware);

    if (newIndex === 1) this.props.setOptionType(OptionType.UploadFirmware);

    if (newIndex === -1) this.props.setDisableNextButton(true);
    else this.props.setDisableNextButton(false);
  };

  onSelect = (e, data) => {
    this.props.setSelectedFirmwareVersion(data);
    this.props.setOptionType(OptionType.ChooseFirmware);
  };

  returnFirmwareValue = () => {
    let selectedDropdownOption: string = "";
    this.props.firmwareOptions.forEach((config: DropdownOptionType) => {
      if (config?.value === this.props.selectedFirmwareVersion.value) {
        selectedDropdownOption = config.text;
      }
    });
    return selectedDropdownOption;
  };

  componentDidUpdate(
    prevProps: Readonly<SelectOrUploadFirmwareAccordionProps>
  ): void {
    this.props.setUploadTriggered("");
    if (this.props.activeIndex === 0)
      this.props.setOptionType(OptionType.ChooseFirmware);

    if (this.props.activeIndex === 1)
      this.props.setOptionType(OptionType.UploadFirmware);

    if (this.props.activeIndex === -1) this.props.setDisableNextButton(true);
    else this.props.setDisableNextButton(false);
    if (prevProps.uploadTriggeredEach !== this.props.uploadTriggeredEach) {
      if (this.props.uploadTriggered === "trigger") {
        switch (this.props.optionType) {
          case OptionType.ChooseFirmware:
            if (
              this.props.selectedFirmwareVersion &&
              this.props.selectedFirmwareVersion.value !== ""
            ) {
              this.props.setSelectedFirmwareVersion(
                this.props.selectedFirmwareVersion
              );
              this.props.setActiveModal(this.props.activeModal + 1);
            } else
              beamtoast.error("Please select a firmware version to trigger");
        }
      }
    }
  }

  render() {
    // const { activeIndex } = this.state;

    return (
      <Accordion fluid>
        <Accordion.Title
          active={this.props.activeIndex === 0}
          index={0}
          onClick={this.handleClick}
        >
          <p
            style={{
              fontSize: "1.2rem",
              margin: 0,
            }}
          >
            <Icon name="dropdown" />
            Select Firmware Version
          </p>
        </Accordion.Title>
        <Accordion.Content active={this.props.activeIndex === 0}>
          <div style={{ padding: "1rem 8rem" }}>
            <Dropdown
              placeholder="Select Version"
              fluid
              selection
              defaultValue={this.returnFirmwareValue()}
              options={this.props.firmwareOptions}
              onChange={this.onSelect}
              disabled={this.props.activeIndex !== 0}
            />
          </div>
        </Accordion.Content>
        <Accordion.Title
          active={this.props.activeIndex === 1}
          index={1}
          onClick={this.handleClick}
        >
          <p
            style={{
              fontSize: "1.2rem",
              margin: 0,
            }}
          >
            <Icon name="dropdown" />
            Upload new Firmware Version
          </p>
        </Accordion.Title>

        <Accordion.Content active={this.props.activeIndex === 1}>
          <UploadFirmwareComponent
            setOptionType={(option) => this.props.setOptionType(option)}
            optionType={this.props.optionType}
            uploadTriggered={this.props.uploadTriggered}
            uploadTriggeredEach={this.props.uploadTriggeredEach}
            setActiveModal={(index) => this.props.setActiveModal(index)}
            activeModal={this.props.activeModal}
            customFirmwareInputData={this.props.customFirmwareInputData}
            setCustomFirmwareInputData={(version) =>
              this.props.setCustomFirmwareInputData(version)
            }
            selectedCustomFirmwareVersion={
              this.props.selectedCustomFirmwareVersion
            }
            setSelectedCustomFirmwareVersion={(version) =>
              this.props.setSelectedCustomFirmwareVersion(version)
            }
            allFirmwareOptions={this.props.allFirmwareOptions}
            file={this.props.firmwareFile}
            setFile={(file) => this.props.setFirmwareFile(file)}
            fileName={this.props.firmwareFileName}
            setFileName={(name) => this.props.setFirmwareFileName(name)}
            showUploadProgress={this.props.showFirmwareUploadProgress}
            setShowUploadProgress={(progress) =>
              this.props.setShowFirmwareUploadProgress(progress)
            }
            fileLoaded={this.props.firmwareFileLoaded}
            setFileLoaded={(value) => this.props.setFirmwareFileLoaded(value)}
            fileTotal={this.props.firmwareFileTotal}
            setFileTotal={(value) => this.props.setFirmwareFileTotal(value)}
            uploadedFileResponse={this.props.uploadedFirmwareFileResponse}
            setUploadedFileResponse={(res) =>
              this.props.setUploadedFirmwareFileResponse(res)
            }
            fillFirmwareDropdownOptions={() =>
              this.props.fillFirmwareDropdownOptions()
            }
            firmwareOptionsLoaded={this.props.firmwareOptionsLoaded}
            fileInput={this.props.firmwareFileInput}
          />
        </Accordion.Content>
      </Accordion>
    );
  }
}
