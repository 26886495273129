import React, { useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import ThemeSchema from "../../../../theme/schema";
import { useUser } from "../../../../context/User.context";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-chaos";
import { fetchActionParams } from "../../../../BytebeamClient";
import LoadingAnimation from "../../../common/Loader";

type ViewPayloadModalProps = {
  isOpen: boolean;
  onClose: () => void;
  actionId: number;
};

const ViewPayloadModal: React.FC<ViewPayloadModalProps> = ({
  isOpen,
  onClose,
  actionId,
}) => {
  const { user } = useUser();
  const theme = user?.settings?.theme ?? "dark";

  const [payload, setPayload] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getActionPayload() {
      setLoading(true);
      try {
        const res = await fetchActionParams(String(actionId));
        setPayload(res.params ? JSON.parse(res.params) : "");
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (isOpen) {
      getActionPayload();
    }
  }, [isOpen, actionId]);

  return (
    <Modal
      centered
      open={isOpen}
      onClose={onClose}
      size="small"
      className="dark"
    >
      <Modal.Header>Action Payload</Modal.Header>
      <Modal.Content>
        {loading ? (
          <LoadingAnimation
            loaderSize="42px"
            loaderContainerHeight="200px"
            loadingText={`Loading Payload`}
            fontSize="20px"
          />
        ) : (
          <AceEditor
            height="200px"
            width="100%"
            mode="markdown"
            theme={ThemeSchema.data[theme]?.colors["ace-editor-theme"]}
            name="payload"
            fontSize={14}
            value={JSON.stringify(payload, null, 2)}
            showPrintMargin={false}
            showGutter={true}
            highlightActiveLine={true}
            setOptions={{
              readOnly: true,
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: false,
              tabSize: 2,
            }}
            style={{
              marginBottom: "16px",
              borderRadius: "4px",
              border: `${ThemeSchema.data[theme]?.colors["ace-editor-border"]}`,
              boxShadow: `${ThemeSchema.data[theme]?.colors["ace-editor-box-shadow"]}`,
            }}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={onClose}>
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ViewPayloadModal;
