import React, { useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import { OptionType } from "../util";
import { DropdownOptionType } from "../../DeviceManagement/Devices/ActionModals/UpdateConfigModal";
import LoadingAnimation from "../../../common/Loader";
import GeofenceEditor, {
  LatLng,
} from "../../Actions/Inventory/DeviceConfigurations/GeofenceEditor";
import { beamtoast } from "../../../common/CustomToast";

type UploadGeofenceConfigComponentProps = {
  setOptionType: (arg0: OptionType) => void;
  optionType: OptionType;
  uploadTriggered: string;
  uploadTriggeredEach: boolean;
  setActiveModal: (arg0: number) => void;
  activeModal: number;
  geofenceOptions: DropdownOptionType[];
  allConfigOptions: Set<any> | undefined;
  selectedCustomGeofenceVersion: string;
  setSelectedCustomGeofenceVersion: (arg0: string) => void;
  path: LatLng[];
  setPath: (arg0: LatLng[]) => void;
  isPolygonComplete: boolean;
  setIsPolygonComplete: (arg0: boolean) => void;
  alertThreshold: number;
  setAlertThreshold: (arg0: number) => void;
  theme: string;
  setDisableNextButton: (arg0: boolean) => void;
  latitude: number;
  setLatitude: (arg0: number) => void;
  longitude: number;
  setLongitude: (arg0: number) => void;
};

export function UploadGeofenceConfigComponent(
  props: UploadGeofenceConfigComponentProps
) {
  const { theme } = props;

  const [errors, setErrors] = useState<{
    versionName?: string | null;
    thresholdValue?: string | null;
  }>({
    versionName: "",
    thresholdValue: "",
  });

  const handleSubmit = () => {
    let validity = 1;
    if (props.selectedCustomGeofenceVersion.length === 0) {
      setErrors({
        versionName: "Please enter a geofence config name",
      });
      validity = 0;
    } else if (props.alertThreshold < 1) {
      setErrors({
        thresholdValue: "Geofence alert threshold should be greater than 0",
      });
      validity = 0;
    } else if (!props.alertThreshold) {
      setErrors({
        thresholdValue: "Please enter valid geofence threshold",
      });
      validity = 0;
    } else if (!props.isPolygonComplete) {
      beamtoast.error("Please select a geofence on the map");
      validity = 0;
    } else if (props.allConfigOptions) {
      if (props.allConfigOptions.has(props.selectedCustomGeofenceVersion)) {
        setErrors({
          versionName: "This geofence config version already exists",
        });
        validity = 0;
      }
    }
    if (validity === 1) {
      // valid data, submit
      props.setActiveModal(props.activeModal + 1);
    }
  };

  useEffect(() => {
    if (props.uploadTriggered === "trigger") {
      switch (props.optionType) {
        case OptionType.UploadGeofence:
          handleSubmit();
          break;
      }
    }
  }, [props.uploadTriggered, props.uploadTriggeredEach]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !props.isPolygonComplete ||
      props.selectedCustomGeofenceVersion.length !== 0 ||
      props.path.length !== 0
    )
      props.setDisableNextButton(true);
    else props.setDisableNextButton(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.isPolygonComplete,
    props.selectedCustomGeofenceVersion,
    props.path,
  ]);

  useEffect(() => {
    if (props.path.length > 0) {
      props.setLatitude(props.path[0].lat);
      props.setLongitude(props.path[0].lng);
    }
  }, [props.path]); // eslint-disable-line react-hooks/exhaustive-deps

  return props.allConfigOptions && props.geofenceOptions ? (
    <Form>
      <Form.Field>
        <label>Version Name</label>
        <Form.Input
          error={errors.versionName !== "" ? errors.versionName : null}
          placeholder={"production v1"}
          value={props.selectedCustomGeofenceVersion}
          onChange={(e) => {
            props.setOptionType(OptionType.UploadGeofence);
            props.setSelectedCustomGeofenceVersion(e.target.value);
            setErrors({
              versionName: "",
            });
          }}
        />
      </Form.Field>
      <Form.Field>
        <label>Click to create geofence polygon</label>
        <GeofenceEditor
          editable
          latitude={props.latitude} // static value because only creation of new geofence is happening from here; bangalore
          longitude={props.longitude} // static value because only creation of new geofence is happening from here; bangalore
          path={props.path}
          onPolygonChanged={(path, isComplete) => {
            props.setPath(path);
            props.setIsPolygonComplete(isComplete);
            props.setOptionType(OptionType.UploadGeofence);
          }}
          theme={theme}
        />
      </Form.Field>
      <Form.Field>
        <label htmlFor="alert_threshold">Alert threshold (seconds)</label>
        <Form.Input
          id={"alert_threshold"}
          min="1"
          error={errors.thresholdValue !== "" ? errors.thresholdValue : null}
          placeholder={20}
          value={props.alertThreshold ? props.alertThreshold : null}
          onChange={(e) => {
            props.setOptionType(OptionType.UploadGeofence);
            props.setAlertThreshold(parseInt(e.target.value));
            setErrors({
              thresholdValue: "",
            });
          }}
        />
      </Form.Field>
    </Form>
  ) : (
    <LoadingAnimation
      loaderContainerHeight="350px"
      loadingText="Loading"
      fontSize="20px"
    />
  );
}
