import React from "react";
import {
  Button,
  Icon,
  ButtonProps,
  SemanticICONS,
  SemanticCOLORS,
} from "semantic-ui-react";
import styled, { css } from "styled-components";

interface ButtonWithIconProps extends ButtonProps {
  color?:
    | SemanticCOLORS
    | "facebook"
    | "google plus"
    | "vk"
    | "twitter"
    | "linkedin"
    | "instagram"
    | "youtube";
  backgroundcolor?:
    | SemanticCOLORS
    | "facebook"
    | "google plus"
    | "vk"
    | "twitter"
    | "linkedin"
    | "instagram"
    | "youtube";
  rounded?: boolean;
  icon?: SemanticICONS;
  styleType?: "filled" | "outlined";
  width?: string;
  border?: string;
}

const StyledCustomButton = styled(Button)<ButtonWithIconProps>`
  &&& {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: ${(props) => props.width ?? "240px"};
    height: 30px;
    border-radius: ${(props) => (props.rounded ? "25px" : "40px")} !important;
    padding: 12px 0 !important;
    margin-bottom: 0px !important;
    border: ${(props) => props.border ?? ""};
    box-shadow: ${({ theme }) =>
      theme.colors["container-box_shadow"]} !important;
    background: ${(props) =>
      props.styleType === "filled" && props.backgroundcolor
        ? props.backgroundcolor
        : props.theme.colors["background-color"]} !important;
    color: ${(props) =>
      props.color
        ? props.color
        : props.theme.colors["welcome-header-text"]} !important;
    font-size: 12px !important;
    font-weight: 400;
    ${(props) =>
      props.rounded &&
      css`
        border-radius: 25px !important;
      `}
  }
`;

const ButtonWithIcon: React.FC<ButtonWithIconProps> = ({
  color,
  rounded,
  icon,
  styleType,
  disabled,
  children,
  ...rest
}) => {
  return (
    <StyledCustomButton
      color={color}
      rounded={rounded}
      styleType={styleType}
      disabled={disabled}
      {...rest}
    >
      {icon && <Icon name={icon} />}
      {children}
    </StyledCustomButton>
  );
};

export default ButtonWithIcon;
